import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Alert,
  ArrowRightIcon,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SquareIcon,
  Stack,
  Typography,
  WarningIcon,
} from '@dizzbo/ui';

import { getOrderTasks, reviewOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import { OrderSubtodoType, OrderType } from '@types';

const SubtitleStyles = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const ContentCardStyles = styled(Card)(() => ({
  width: '10  0%',
  height: 200,
}));

type Props = {
  orderData: OrderType;
  popupState: PopupState;
};

export const OrderReviewDialog: React.FC<Props> = ({
  orderData,
  popupState,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { uuid: orderUUID, reference, status } = orderData;

  const { data: orderTasks, isPending: isOrderTasksPending } = useQuery({
    queryKey: [QueryKeys.TASKS, orderUUID],
    queryFn: () => getOrderTasks(orderUUID),
    enabled: popupState.isOpen,
  });

  const orderReviewTasks: Array<OrderSubtodoType> = isOrderTasksPending
    ? []
    : orderTasks.order_review_todo;

  const { mutate: mutateReviewOrder, isPending } = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: () => reviewOrder(orderUUID),
    onSuccess() {
      toast.success(t('orderMarkedAsReviewed'));
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS],
      });
      popupState.close();
    },
    onError() {
      toast.error(t('orderCouldNotBeMarkedAsReviewed'));
      popupState.close();
    },
  });

  const handleClick = () => {
    mutateReviewOrder();
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        <Typography variant="h3" color="primary">
          {reference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {status}
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <SubtitleStyles>
          <Typography variant="h5">{t('orderReview')}</Typography>
        </SubtitleStyles>
        <ContentCardStyles variant="filled-green" elevation={0} square>
          <CardContent>
            <Typography variant="bodyRegular">
              {t('loader')}:&nbsp;
              {orderData?.loader
                ? `${orderData.loader.name || ''} ${orderData.loader.name || ''}`
                : 'Loader info missing!'}
            </Typography>
            <br />
            <Typography variant="bodyRegular">
              {t('customerNumber')}:&nbsp;
              {orderData?.loader
                ? orderData.loader.customerNumber
                : 'Loader info missing!'}
            </Typography>
          </CardContent>
        </ContentCardStyles>
        {!isOrderTasksPending && orderReviewTasks.length > 0 ? (
          <ContentCardStyles variant="filled-disabled" elevation={0} square>
            <CardContent>
              <Alert
                title={t('orderCannotMarkedAsReviewed')}
                message={orderReviewTasks.map((subTodo: OrderSubtodoType) => (
                  <Box key={subTodo.uuid + subTodo.field}>
                    <Typography component={'span'}>
                      {subTodo.fieldLabel && subTodo.fieldLabel.length > 0
                        ? subTodo.fieldLabel
                        : 'Todo'}
                      :&nbsp;
                      {subTodo.message}
                    </Typography>
                    <br />
                  </Box>
                ))}
                variant="standard"
                severity="warning"
                icon={<WarningIcon />}
              />
            </CardContent>
          </ContentCardStyles>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button
            variant="tertiary"
            onClick={() => popupState.close()}
            disabled={isPending}
          >
            {t('cancel')}
          </Button>
          {orderReviewTasks.length > 0 ? (
            <Button
              startIcon={<ArrowRightIcon />}
              size="medium"
              component={RouterLink}
              to={`/orders/${orderUUID}`}
            >
              {t('goToOrderDetails')}
            </Button>
          ) : (
            <LoadingButton
              type="submit"
              variant="primary"
              onClick={handleClick}
              loading={isPending}
              disabled={isPending}
              startIcon={<SquareIcon />}
            >
              {t('markOrderAsReviewed')}
            </LoadingButton>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
