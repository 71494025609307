import { removeOrderFromCurrentTour } from '@core/api';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@dizzbo/ui';
import { useGetOrders } from '@orders/queries';
import { useMutation } from '@tanstack/react-query';
import { UUIDType } from '@types';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type Props = {
  popupState: PopupState;
  orderReference: string;
  tourReference: string;
  orderUUID: string;
};

export const RemoveOrderDialog: FC<Props> = ({
  popupState,
  orderReference,
  tourReference,
  orderUUID,
}) => {
  const { t } = useTranslation();
  const { refetch: refreshOrderListData } = useGetOrders();

  const { mutateAsync: mutateRemoveOrder, isPending: isMutating } = useMutation(
    {
      mutationFn: (config: { orderUUID: UUIDType }) => {
        const { orderUUID } = config;
        return removeOrderFromCurrentTour(orderUUID);
      },
      onSuccess: async () => {
        await refreshOrderListData();
        toast.success(
          t('orderWasRemovedFromTour', {
            orderReference,
            tourReference,
          })
        );
        popupState.close();
      },
      onError: (error) => {
        toast.error(`Something went wrong! Error : ${error.message || ''}`);
      },
    }
  );

  function handleDialogCancel(): void {
    popupState.close();
  }

  async function handleDialogConfirm(): Promise<void> {
    mutateRemoveOrder({ orderUUID });
  }

  return (
    <Dialog
      aria-labelledby="draggable-dialog-title"
      scroll="paper"
      fullWidth
      maxWidth="xs"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close} id="draggable-dialog-title">
        <Typography variant="h5" color="primary">
          {t('confirm')}
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers={true}
        sx={{ textAlign: 'center', color: '#000' }}
      >
        {
          <Typography variant="buttonRegular">
            {t('removeOrder', { orderReference })}
          </Typography>
        }
      </DialogContent>
      <DialogContent dividers={false}>
        {
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '6px',
            }}
          >
            <Button
              variant="tertiary"
              size="medium"
              onClick={() => handleDialogCancel()}
            >
              <Typography variant="buttonRegular">{t('cancel')}</Typography>
            </Button>
            <Button
              variant="primary"
              color="warning"
              size="medium"
              onClick={() => handleDialogConfirm()}
              disabled={isMutating}
            >
              <Typography variant="buttonRegular">
                {isMutating ? t('loading') : t('confirm')}
              </Typography>
            </Button>
          </Box>
        }
      </DialogContent>
    </Dialog>
  );
};
