import { useTheme } from '@mui/material/styles';
import React, { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DocumentIcon, MapIcon, Tab, Tabs } from '@dizzbo/ui';
import {
  useOrderDetailNavigation,
  useTourOrdersData,
} from '@order-detail/contexts';
import { OrderType, UUIDType } from '@types';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type Props = {
  tourReference: string;
  ordersData: Record<UUIDType, OrderType>;
  rearrangedOrderUUIDS: Array<UUIDType>;
  stuck?: boolean;
};

export const OrderDetailNavigation: FC<Props> = ({
  tourReference,
  ordersData,
  rearrangedOrderUUIDS,
  stuck,
}) => {
  const { t } = useTranslation();
  const { activeTabIndex, setActiveTabIndex } = useOrderDetailNavigation();
  const theme = useTheme();

  const handleChange = (_: SyntheticEvent, newValue: number): void => {
    setActiveTabIndex(newValue);
  };

  const { updateSelectedOrderUUID } = useTourOrdersData();

  return (
    <Box
      sx={{
        transition: theme.transitions.create('border '),
        backgroundColor: theme.styles.common.pageBackground,
        paddingLeft: 3,
        position: 'relative',
        borderTop: stuck
          ? `1px solid ${theme.styles.divider.primary.default.borderColor}`
          : `0px solid ${theme.styles.divider.primary.default.borderColor}`,
        '&::after': {
          pointerEvents: 'none',
          content: "' '",
          display: 'block',
          position: 'absolute',
          zIndex: 1,
          height: '48px',
          width: '100%',
          bottom: -48,
          left: 0,
          backgroundRepeat: 'repeat-x',
          backgroundImage: `url(${theme.styles.images.shadowHorizontal})`,
        },
      }}
    >
      <Tabs
        value={
          rearrangedOrderUUIDS && rearrangedOrderUUIDS.length > 0
            ? activeTabIndex
            : 0
        }
        onChange={handleChange}
        aria-label="Order detail tabs"
        variant="scrollable"
        visibleScrollbar={true}
      >
        <Tab
          icon={<MapIcon sx={{ width: 20, height: 20 }} />}
          iconPosition="start"
          label={t('tourWithReference', { reference: tourReference })}
          {...a11yProps(0)}
        />
        {rearrangedOrderUUIDS && rearrangedOrderUUIDS.length > 0
          ? rearrangedOrderUUIDS.map((orderUUID: UUIDType, index: number) => {
              const { reference } = ordersData[orderUUID];
              return (
                <Tab
                  key={`order_tab_${reference}`}
                  icon={<DocumentIcon sx={{ width: 20, height: 20 }} />}
                  iconPosition="start"
                  label={t('orderWithReference', { reference })}
                  {...a11yProps(index + 1)}
                  onClick={() => updateSelectedOrderUUID(orderUUID)}
                />
              );
            })
          : null}
      </Tabs>
    </Box>
  );
};
