import {
  useGetMultipleOrdersTasks,
  useGetMultipleOrdersTodos,
  useGetOrder,
  useGetTourTasks,
  useGetTourTodos,
} from '@core/hooks';
import { useGetTour } from '@order-detail/queries';
import { useSearchForOrder } from '@orders/queries';
import {
  OrderTodosTasksType,
  OrderTodoType,
  OrderType,
  TourTodosTasksType,
  TourTodoType,
  TourType,
  UUIDType,
} from '@types';
import { rearrangeOrderUUIDS } from '@utils';
import React, {
  Context,
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type TourOrdersContextType = 'newOrder' | 'existingOrder';

type TourOrdersDataContextType = {
  contextType: TourOrdersContextType;
  selectedOrderUUID: UUIDType;
  selectedOrderData: OrderType;
  tourOrdersUUIDS: Array<UUIDType>;
  tourOrders: Record<UUIDType, OrderType>;
  tourData: TourType;
  updateSelectedOrderUUID: (newOrderUUID: UUIDType) => void;
  refetchTourDataAndOrders: () => void;
  refetchOrderAndTourTodos: () => void;
  isTasksAndTodosFetching: boolean;
  ordersTasks: Record<UUIDType, OrderTodosTasksType>;
  ordersTodos: Record<UUIDType, OrderTodoType[]>;
  tourTasks: TourTodosTasksType;
  tourTodos: TourTodoType[];
  isOrderAndTourDataLoadedOnce: boolean;
  isOrderDataFetching: boolean;
  isTourDataFetching: boolean;
  isTourOrdersDataFetching: boolean;
  isErrorFetchingOrderData: boolean;
  switchToCopiedOrder: (newOrderUUID: UUIDType) => void;
};

const TourOrdersDataContext: Context<TourOrdersDataContextType> = createContext<
  TourOrdersDataContextType | undefined
>(undefined);

interface Props extends PropsWithChildren {
  contextType: TourOrdersContextType;
  initialDisplayedOrderUUID: UUIDType;
}

export const TourOrdersDataProvider: FC<Props> = ({
  contextType,
  initialDisplayedOrderUUID,
  children,
}) => {
  const [selectedOrderUUID, setSelectedOrderUUID] = useState<UUIDType>(
    initialDisplayedOrderUUID
  );
  const [selectedOrderData, setSelectedOrderData] = useState<
    OrderType | undefined
  >(undefined);
  const [tourOrdersUUIDS, setTourOrderUUIDS] = useState<Array<UUIDType>>([]);
  const [tourOrders, setTourOrders] = useState<Record<UUIDType, OrderType>>({});
  const [isOrderAndTourDataLoadedOnce, setIsOrderAndTourDataLoadedOnce] =
    useState<boolean>(false);

  const {
    data: orderData,
    refetch: refetchOrderData,
    isPending: isOrderDataFetching,
    isError: isErrorFetchingOrderData,
  } = useGetOrder(initialDisplayedOrderUUID);

  const { tourReference, tourUUID } = useMemo(() => {
    let tourReference: string = '';
    let tourUUID: UUIDType = '';

    if (!isOrderDataFetching && orderData) {
      tourReference = orderData?.tour?.reference || '';
      tourUUID = orderData?.tour?.uuid || '';
    }
    return { tourReference, tourUUID };
  }, [isOrderDataFetching]);

  const {
    data: tourData,
    refetch: refetchTourData,
    isPending: isTourDataFetching,
  } = useGetTour(tourUUID);

  const {
    data: tourOrdersData,
    refetch: refetchTourOrdersData,
    isFetching: isTourOrdersDataFetching,
  } = useSearchForOrder({
    search: tourReference,
  });

  const {
    data: ordersTasks,
    isFetching: isOrdersTasksFetching,
    refetch: refetchOrderTasks,
  } = useGetMultipleOrdersTasks(tourOrdersUUIDS);

  const {
    data: ordersTodos,
    isFetching: isOrdersTodosFetching,
    refetch: refetchOrderTodos,
  } = useGetMultipleOrdersTodos(tourOrdersUUIDS);

  const {
    data: tourTasks,
    isFetching: isTourTasksFetching,
    refetch: refetchTourTasks,
  } = useGetTourTasks(contextType === 'existingOrder' ? tourData?.uuid : null);

  const {
    data: tourTodos,
    isFetching: isTourTodosFetching,
    refetch: refetchTourTodos,
  } = useGetTourTodos(contextType === 'existingOrder' ? tourData?.uuid : null);

  const { isTasksAndTodosFetching } = useMemo(() => {
    const isTasksAndTodosFetching: boolean =
      isOrdersTasksFetching ||
      isOrdersTodosFetching ||
      isTourTasksFetching ||
      isTourTodosFetching;
    return { isTasksAndTodosFetching };
  }, [
    isOrdersTasksFetching,
    isOrdersTodosFetching,
    isTourTasksFetching,
    isTourTodosFetching,
  ]);

  function updateSelectedOrderUUID(newUUID: UUIDType): void {
    if (!tourOrders[newUUID]) {
      console.error(
        `Error in updateSelectedOrderUUID()! Order with uuid of ${newUUID} was not found!`
      );
      return;
    }
    setSelectedOrderUUID(newUUID);
    setSelectedOrderData(tourOrders[newUUID]);
  }

  async function refetchTourDataAndOrders(): Promise<void> {
    if (contextType === 'existingOrder') {
      await Promise.all([refetchTourData(), refetchTourOrdersData()]);
    } else {
      await refetchOrderData();
    }
  }

  async function refetchOrderAndTourTodos(): Promise<void> {
    if (contextType === 'newOrder') {
      return Promise.resolve();
    }
    await Promise.all([
      refetchOrderTasks(),
      refetchOrderTodos(),
      refetchTourTasks(),
      refetchTourTodos(),
    ]);
  }

  function switchToCopiedOrder(newUUID: UUIDType): void {
    setIsOrderAndTourDataLoadedOnce(false);
    setSelectedOrderUUID(newUUID);
    refetchOrderData();
  }

  useEffect(() => {
    if (contextType !== 'existingOrder') {
      return;
    }
    if (
      !isTourOrdersDataFetching &&
      tourOrdersData &&
      tourOrdersData.results.length !== 0 &&
      tourOrders[selectedOrderUUID]
    ) {
      setSelectedOrderData(tourOrders[selectedOrderUUID]);
    }
  }, [isTourOrdersDataFetching, tourOrders]);

  useEffect(() => {
    if (contextType !== 'newOrder') {
      return;
    }
    if (!isOrderDataFetching && orderData) {
      setSelectedOrderData(orderData);
    }
  }, [isOrderDataFetching, orderData]);

  useEffect(() => {
    if (!isTourOrdersDataFetching && tourOrdersData) {
      const tourOrdersArr: Array<OrderType> = tourOrdersData.results;
      const updatedTourOrders = tourOrdersArr.reduce(
        (acc: Record<UUIDType, OrderType>, order: OrderType) => {
          acc[order.uuid] = order;
          return acc;
        },
        {}
      );
      const tourOrderUUIDs: Array<UUIDType> = Object.keys(updatedTourOrders);
      const rearrangedTourOrderUUIDS = rearrangeOrderUUIDS(
        tourOrderUUIDs,
        initialDisplayedOrderUUID
      );
      setTourOrderUUIDS(rearrangedTourOrderUUIDS);
      setTourOrders(updatedTourOrders);
    }
  }, [isTourOrdersDataFetching, tourOrdersData]);

  useEffect(() => {
    if (
      selectedOrderData &&
      tourData &&
      tourOrdersUUIDS.length !== 0 &&
      !isOrdersTasksFetching &&
      !isOrdersTodosFetching &&
      !isTourTasksFetching &&
      !isTourTodosFetching &&
      !isTourDataFetching
    ) {
      setIsOrderAndTourDataLoadedOnce(true);
    }
  }, [
    selectedOrderData,
    tourData,
    tourOrdersUUIDS,
    isOrdersTasksFetching,
    isOrdersTodosFetching,
    isTourTasksFetching,
    isTourTodosFetching,
    isTourDataFetching,
  ]);

  return (
    <TourOrdersDataContext.Provider
      value={{
        contextType,
        selectedOrderUUID,
        selectedOrderData,
        tourOrdersUUIDS,
        tourOrders,
        tourData,
        updateSelectedOrderUUID,
        refetchTourDataAndOrders,
        refetchOrderAndTourTodos,
        isTasksAndTodosFetching,
        ordersTasks,
        ordersTodos,
        tourTasks,
        tourTodos,
        isOrderAndTourDataLoadedOnce,
        isOrderDataFetching,
        isTourDataFetching,
        isTourOrdersDataFetching,
        isErrorFetchingOrderData,
        switchToCopiedOrder,
      }}
    >
      {children}
    </TourOrdersDataContext.Provider>
  );
};

export const useTourOrdersData = () => {
  const context: TourOrdersDataContextType = useContext(TourOrdersDataContext);

  if (!context) {
    throw new Error(
      'useTourOrdersData hook must be used within a TourOrdersDataProvider'
    );
  }

  return context;
};
