import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardSubHeader,
  ErrorIcon,
  GoodIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Stack,
} from '@dizzbo/ui';

import { deleteDeliveryGood } from '@core/api';
import { QueryKeys } from '@core/config';

import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { UUIDType } from '@types';
import { AxiosError } from 'axios';
import { PopupState as PopupStateType } from 'material-ui-popup-state/hooks';
import { toast } from 'react-toastify';

const ActionsStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'status',
})(() => {
  return { marginLeft: 'auto !important' };
});

type Props = {
  title: string;
  deliveryUUID: UUIDType;
  deliveryGoodUUID: UUIDType;
  setActiveTab: (value: number) => void;
};

export const DeliveryGoodDetailHeader: React.FC<Props> = ({
  title,
  deliveryUUID,
  deliveryGoodUUID,
  setActiveTab,
}): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    selectedOrderUUID,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();

  const { mutate: mutateDelivery } = useMutation({
    mutationKey: [
      QueryKeys.ORDERS,
      selectedOrderUUID,
      QueryKeys.DELIVERY_GOODS,
    ],
    mutationFn: () => deleteDeliveryGood(deliveryUUID, deliveryGoodUUID),
  });

  const handleDelete = (popupState: PopupStateType) => {
    const getToastId = addSelfClearingLoadingToast(t('updatingDelivery'), 6000);

    mutateDelivery(null, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('deliveryUpdated'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
        queryClient.invalidateQueries({
          queryKey: [
            QueryKeys.ORDERS,
            selectedOrderUUID,
            QueryKeys.DELIVERY_GOODS,
          ],
        });
        setActiveTab(0);
        popupState.close();
      },
    });
  };

  return (
    <CardSubHeader title={title} icon={<GoodIcon />}>
      <ActionsStyles>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <>
              <IconButton
                size="medium"
                variant="tertiary"
                {...bindTrigger(popupState)}
              >
                <MoreVerticalIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => handleDelete(popupState)}>
                  <ListItemIcon>
                    <ErrorIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText>{t('deleteGood')}</ListItemText>
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      </ActionsStyles>
    </CardSubHeader>
  );
};
