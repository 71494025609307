import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { updateOrder } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useFieldErrors } from '@dizzbo/core/hooks';
import { MultiLineTextfield, Stack, TextField } from '@dizzbo/ui';
import { useTourOrdersData } from '@order-detail/contexts';
import { OrderType } from '@types';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

type FormData = {
  invoiceReference: string;
  invoiceNotes: string;
};

type Props = {};

const schema = yup.object().shape({
  invoiceReference: yup.string().max(50, 'Must be max 50 digits'),
  invoiceNotes: yup.string(),
});

export const ReferenceNumbersForm: FC<Props> = () => {
  const { t } = useTranslation();
  const {
    selectedOrderUUID,
    selectedOrderData,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();

  const { invoiceReference, invoiceNotes, hasCustomer } = useMemo(() => {
    const invoiceReference: string = selectedOrderData?.invoiceReference || '';
    const invoiceNotes: string = selectedOrderData?.invoiceNotes || '';
    const hasCustomer: boolean = !!selectedOrderData?.loader;

    return { invoiceReference, invoiceNotes, hasCustomer };
  }, [selectedOrderData]);

  const {
    mutate: mutateOrder,
    isError,
    error,
  } = useMutation<OrderType, AxiosError, FormData>({
    mutationKey: [QueryKeys.ORDERS, selectedOrderUUID],
    mutationFn: (values: FormData) => {
      const { invoiceNotes, invoiceReference } = values;
      const parsedFormData: Partial<OrderType> = {
        invoiceNotes,
        invoiceReference,
      };
      return updateOrder({
        orderUUID: selectedOrderUUID,
        orderData: parsedFormData,
      });
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    values: {
      invoiceReference,
      invoiceNotes,
    },
    resolver: yupResolver(schema),
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData: FormData): void => {
    const getToastId = addSelfClearingLoadingToast(t('updatingOrder'), 6000);

    mutateOrder(formData, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('orderUpdated'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
      },
    });
  };

  return (
    <>
      <Stack spacing={6}>
        <Controller
          name="invoiceReference"
          control={control}
          defaultValue=""
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              label={t('invoiceReferenceNumber')}
              error={hasFieldError(field)}
              helperText={fieldError(field)}
              {...field}
              disabled={!hasCustomer}
            />
          )}
        />
        <Controller
          name="invoiceNotes"
          control={control}
          defaultValue=""
          rules={{ required: false }}
          render={({ field }) => (
            <MultiLineTextfield
              label={t('additionalInvoiceInformation')}
              characterLimit={1000}
              {...field}
              disabled={!hasCustomer}
            />
          )}
        />
      </Stack>
      <AutoSave
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        isValid={isValid}
        control={control}
      />
    </>
  );
};
