import { Box, colors, LoadingButton, LocationIcon, PlusIcon } from '@dizzbo/ui';
import React, { FC } from 'react';
import { styled } from '@mui/system';

const BoxActivationButtonContainer = styled(Box)(({}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'absolute',
  right: 0,
  top: 0,
  background: `linear-gradient(90deg, transparent , ${colors.turquoise['40']} 15%)`,
  borderRadius: 0,
  height: '100% ',
  width: '200px',
}));

type Props = {
  btnOnClick: () => void;
};

export const WaypointActivationButton: FC<Props> = ({ btnOnClick }) => {
  return (
    <BoxActivationButtonContainer>
      <Box sx={{ marginRight: '35px' }}>
        <LoadingButton
          size="large"
          type="button"
          variant="primary"
          sx={{
            padding: '6 12px',
            minWidth: '68px',
            minHeight: '32px',
            display: 'flex',
            gap: '8px',
          }}
          onClick={btnOnClick}
        >
          <LocationIcon />
          <PlusIcon />
        </LoadingButton>
      </Box>
    </BoxActivationButtonContainer>
  );
};
