import { WaypointWithSectionType } from '@types';
import { MutableRefObject, useEffect } from 'react';
import { getWaypointMarker } from '../utils';

export const useRenderWaypoints = (
  hereMapRef: MutableRefObject<H.Map> | undefined,
  waypoints: WaypointWithSectionType[]
) => {
  // render waypoints
  useEffect(() => {
    if (!hereMapRef.current) return;

    const map = hereMapRef.current as H.map;

    function handleWaypointPointerEnter(event) {
      const waypoint = event?.target?.data;

      map.getViewPort().element.style.cursor =
        waypoint && waypoint?.name ? 'auto' : 'grab';
    }
    function handleWaypointPointerLeave() {
      map.getViewPort().element.style.cursor = 'auto';
    }

    const waypointMarkers = Object.values(waypoints).map((waypoint) => {
      const waypointMarker = getWaypointMarker(waypoint);

      waypointMarker.addEventListener(
        'pointerenter',
        handleWaypointPointerEnter
      );
      waypointMarker.addEventListener(
        'pointerleave',
        handleWaypointPointerLeave
      );
      return waypointMarker;
    });

    map.addObjects(waypointMarkers);

    return () => {
      if (!map) return;

      const renderedWaypoints = map
        .getObjects()
        .filter((object) => object instanceof H.map.DomMarker);
      map.removeObjects(renderedWaypoints);
    };
  }, [hereMapRef?.current, waypoints]);
};
