import { SxProps, useTheme } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC } from 'react';

import { Box, Skeleton, Stack } from '@dizzbo/ui';

import { LoadingPointAutocomplete } from '@core/components';
import { QueryKeys } from '@core/config';
import { useGetOrderStop, useGetRoute, useUpdateOrderStop } from '@core/hooks';

import { useTourOrdersData } from '@order-detail/contexts';
import { LoadingPointType, UUIDType } from '@types';
import { LoadingPointBullet } from './LoadingPointBullet';
import { StopActions } from './StopActions';
import { StopContent } from './StopContent';
import { StopSectionSummary } from './StopSectionSummary';

type Props = {
  stopUUID: UUIDType;
  index: number;
  isLastItem: boolean;
};

export const StopListItem: FC<Props> = ({ stopUUID, index, isLastItem }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { selectedOrderUUID, selectedOrderData } = useTourOrdersData();
  const { route: routeUUID } = selectedOrderData;

  const { data } = useGetRoute(routeUUID);
  const { mutateAsync } = useUpdateOrderStop(selectedOrderUUID, stopUUID);
  const { data: stopData, isPending } = useGetOrderStop(
    selectedOrderUUID,
    stopUUID
  );

  const displaySectionSummary = data?.sections.length >= index + 1;

  // TODO REFACTOR THIS
  const handleAutocompleteOnChange = async (loadingPoint: LoadingPointType) => {
    if (loadingPoint) {
      try {
        await mutateAsync({ loadingPoint: loadingPoint.uuid });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ROUTES, routeUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TODOS, selectedOrderUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TASKS, selectedOrderUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.DELIVERIES, selectedOrderUUID],
        });
      } catch (error) {
        console.error('error', error);
      }
    }
  };

  const stopContainerStyles: SxProps = {
    height: '100%',
    backgroundColor: theme.styles.paper.filledSecondary.default.backgroundColor,
    '&:hover': {
      backgroundColor: theme.styles.planner.stopListItem.hover.backgroundColor,
    },
  };

  const bulletContainerStyles: SxProps = isLastItem
    ? {
        height: '100%',
        position: 'relative',
      }
    : {
        height: '100%',
        position: 'relative',
        '&::after': {
          content: "' '",
          position: 'absolute',
          top: 60,
          bottom: 0,
          left: '50%',
          border: '1px dashed #404E64',
        },
      };

  return (
    <Box sx={{ backgroundColor: '#FFFFFF' }}>
      <Stack sx={{ ...stopContainerStyles }} direction="row" py={3} px={6}>
        <Stack sx={{ ...bulletContainerStyles }}>
          <LoadingPointBullet index={index} actions={stopData?.actions} />
        </Stack>
        <Stack
          spacing={3}
          direction="column"
          ml={6}
          sx={{
            display: 'inline',
            width: '100%',
          }}
        >
          <Stack
            spacing={0}
            direction="row"
            alignItems="center"
            sx={{
              width: '100%',
            }}
          >
            {!isPending ? (
              <LoadingPointAutocomplete
                label="Stop"
                onChange={handleAutocompleteOnChange}
                value={stopData.loadingPoint}
                disableClearable={false}
                sx={{ width: '100%' }}
              />
            ) : (
              <>
                <Skeleton width="100%" height={40} />
              </>
            )}
            <Box ml={2}>
              <StopActions stopUUID={stopUUID} />
            </Box>
          </Stack>

          <Box>
            <StopContent
              stopData={stopData}
              stopUUID={stopUUID}
              isLoading={isPending}
            />
          </Box>
          {displaySectionSummary && stopData?.originSection && (
            <StopSectionSummary sectionUUID={stopData.originSection} />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

StopListItem.displayName = 'StopListItem';
