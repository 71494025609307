import Popper from '@mui/material/Popper';
import { useQuery } from '@tanstack/react-query';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useState } from 'react';

import { QueryKeys } from '@core/config';
import {
  Autocomplete,
  CheckIcon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  LocationIcon,
  Paper,
  PlusIcon,
  TextField,
  Typography,
} from '@dizzbo/ui';

import { getLoadingPoints } from '@core/api/loadingPoints';
import { SxProps } from '@mui/material';
import { LoadingPointType } from '@types';
import { useTranslation } from 'react-i18next';
import { CreateLoadingPointDialog } from '../CreateLoadingPointDialog';

type Props = {
  label: string;
  value?: LoadingPointType;
  onChange?: (loadingPoint: LoadingPointType) => void;
  sx?: SxProps;
  disablePopupIcon?: boolean;
  disableClearable?: boolean;
  display?: boolean;
  disabled?: boolean;
};

const styles = {
  popper: {
    width: 'fit-content',
  },
};

const PopperMy = function (props) {
  // need to override style here because else "fit-content" would not be
  // recognized by MUI and how it calculates the width of the popper
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

export const LoadingPointAutocomplete: React.FC<Props> = ({
  label,
  value,
  onChange,
  sx,
  disablePopupIcon,
  disableClearable = true,
  display,
  disabled = false,
}) => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'loadingPointCreateDialog',
  });

  const [searchValue, setSearchValue] = useState(value?.name);
  const [open, setOpen] = React.useState(false);

  async function findLocation(query) {
    const term = query.queryKey[1];
    const resData = await getLoadingPoints({ q: term });
    return resData;
  }

  const { isFetching, data = [] } = useQuery({
    queryKey: [QueryKeys.LOADING_POINTS, searchValue],
    queryFn: findLocation,
    enabled: open && !!searchValue,
  });

  const formatFullAddress = (loadingPoint: LoadingPointType) => {
    const shortNameString = loadingPoint.shortName
      ? loadingPoint.shortName + ', '
      : '';
    const nameString = loadingPoint.name ? loadingPoint.name + ', ' : '';
    return `${shortNameString}${nameString}${loadingPoint.address.formatted}`;
  };

  const handleClick = (e) => {
    popupState.open();
    e.stopPropagation();
  };

  const { t } = useTranslation();

  const PaperComponent = (props: any) => {
    const { children, ...other } = props;
    return (
      <Paper {...other}>
        {children}

        <List>
          <ListItem onClick={(e) => e.preventDefault()}>
            <ListItemButton onMouseDown={handleClick}>
              <ListItemIcon>
                <PlusIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
              {t('createNewLoadingPoint')}
            </ListItemButton>
          </ListItem>
        </List>
      </Paper>
    );
  };

  return (
    <>
      <Autocomplete
        sx={{
          ...sx,
          ...(disablePopupIcon && {
            '.MuiInputBase-root': {
              paddingRight: '10px !important',
            },
          }),
          pointerEvents: disabled ? 'none' : 'auto',
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableClearable={disableClearable}
        disableCloseOnSelect
        loading={isFetching}
        autoComplete // seems not to work
        autoHighlight
        blurOnSelect // input should be blurred when an option is selected
        clearOnEscape // clear input via pressing Esc
        popupIcon={<LocationIcon />}
        PaperComponent={PaperComponent}
        PopperComponent={PopperMy}
        componentsProps={{
          popper: {
            sx: {
              width: 'auto',
            },
          },
          popupIndicator: {
            sx: {
              ...(disablePopupIcon && {
                display: 'none',
              }),
            },
          },
        }}
        noOptionsText={t('noLoadingPointsFound')}
        options={data}
        value={value}
        filterOptions={(x) => x}
        disabled={display}
        renderInput={(params) => (
          <TextField {...params} display={display} label={label} />
        )}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : formatFullAddress(option)
        }
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        onChange={(
          event: any,
          newValue: LoadingPointType | null,
          reason: string
        ) => {
          onChange(newValue);
        }}
        onInputChange={(event, newValue: string, reason: string) => {
          // dont set a new search value when the suggestion list closes/an item get selected
          // TODO this seems not tow work reliable. As soon as you selected an item
          // and reopen the search the full string gets set and also is highlighted via autosuggest-highlight
          if (reason !== 'reset') {
            setSearchValue(newValue);
          }
        }}
        renderOption={(
          props,
          loadingPoint: LoadingPointType,
          { inputValue }
        ) => {
          const addressString = formatFullAddress(loadingPoint);
          const matches = match(addressString, inputValue, {
            insideWords: true,
          });
          const parts = parse(addressString, matches);

          // removing className property as we dont need the special styling applied via autocomplete
          const { className, ...rest } = props;

          let listItemProps = {};

          const text = parts.map((part, index) => (
            <Typography
              key={`t_${addressString}_${index}`}
              variant={part.highlight ? 'bodyBold' : 'bodyRegular'}
            >
              {part.text}
            </Typography>
          ));

          if (props['aria-selected']) {
            listItemProps = {
              secondaryAction: <CheckIcon sx={{ width: 16, height: 16 }} />,
            };
          }

          return (
            <ListItem
              key={`list_item_loading_point_${loadingPoint.uuid}`}
              {...rest}
              {...listItemProps}
            >
              <ListItemIcon>
                <LocationIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
              <ListItemText>{text}</ListItemText>
            </ListItem>
          );
        }}
      />
      <CreateLoadingPointDialog
        popupState={popupState}
        setLoadingPoint={onChange}
      />
    </>
  );
};
