import { useMutation } from '@tanstack/react-query';
import React, { FC, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { updateTour } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  WarningIcon,
} from '@dizzbo/ui';

import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { CarrierType, TourType } from '@types';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CarrierPriceTotal } from './CarrierPriceTotal';

type FormData = {
  price: string;
};

type Props = {
  orientation?: string;
};

export const CarrierPrice: FC<Props> = ({ orientation = 'vertical' }) => {
  const { refetchOrderAndTourTodos, refetchTourDataAndOrders, tourData } =
    useTourOrdersData();
  const { uuid: tourUUID } = tourData;

  const { price, carrier } = useMemo(() => {
    const price: number | null = tourData?.price ?? null;
    const carrier: CarrierType | null = tourData?.carrier || null;

    return { price, carrier };
  }, [tourData]);

  const { t } = useTranslation();

  const {
    mutate: mutateTour,
    isError,
    error,
  } = useMutation<TourType, AxiosError, FormData>({
    mutationKey: [QueryKeys.TOURS, tourUUID],
    mutationFn: (values: FormData) => {
      const { price } = values;
      const parsedFormData: Partial<TourType> = {
        price:
          typeof price === 'string' && price.trim() !== ''
            ? Number(price)
            : null,
      };
      return updateTour({ tourUUID, tourData: parsedFormData });
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    values: {
      price: typeof price === 'number' ? price.toFixed(2) : '',
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData: FormData): void => {
    const getToastId = addSelfClearingLoadingToast(t('updatingTour'), 6000);

    mutateTour(formData, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('tourUpdated'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
      },
    });
  };

  // RESET THE FORM WHEN REMOVING THE CARRIER
  useEffect(() => {
    if (!carrier) {
      reset({
        price: typeof price === 'number' ? price.toFixed(2) : '',
      });
    }
  }, [carrier, reset]);

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: orientation === 'horizontal' ? 0 : 374,
      }}
    >
      <CardHeader title="Carrier Price" variant="small" />
      <CardContent>
        {!price && price !== 0 ? (
          <Alert
            title="Carrier price missing"
            variant="standard"
            severity="warning"
            icon={<WarningIcon />}
            sx={{ marginBottom: 6 }}
          />
        ) : null}

        <Stack
          spacing={6}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <Controller
            name="price"
            control={control}
            defaultValue=""
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                label="Carrier Price"
                required
                type="number"
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>

        <AutoSave
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isValid={isValid}
          control={control}
        />
      </CardContent>
      <CardContent>
        {/* TODO Price Positions */}
        {orientation === 'vertical' && <Box sx={{ height: 80 }}></Box>}
      </CardContent>
      <CardContent>
        <CarrierPriceTotal price={getValues('price')} />
      </CardContent>
    </Card>
  );
};
