import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { forwardRef } from 'react';

import { Box, Stack } from '@dizzbo/ui';

import { LoadingPointAutocomplete } from '@core/components';
import { QueryKeys } from '@core/config';

import { LoadingPointBullet } from './LoadingPointBullet';

import { createOrderStop } from '@core/api';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { LoadingPointType, UUIDType } from '@types';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const StopContainerStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isHovering' && prop !== 'isDragging',
})(({ theme }) => {
  return {
    height: '100%',
    backgroundColor: theme.styles.paper.filledSecondary.default.backgroundColor,
    '&:hover': {
      backgroundColor: theme.styles.planner.stopListItem.hover.backgroundColor,
    },
  };
});

const BulletContainerStyles = styled(Box)(() => ({
  height: '100%',
  position: 'relative',
}));

type Props = {
  index: number;
};

export const StopAddItem = forwardRef<HTMLDivElement, Props>(
  ({ index }, ref) => {
    const {
      selectedOrderUUID,
      selectedOrderData,
      refetchOrderAndTourTodos,
      refetchTourDataAndOrders,
    } = useTourOrdersData();
    const { route: routeUUID } = selectedOrderData;
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const { mutate: mutateAddOrderStop, isPending: isMutatingOrderStop } =
      useMutation({
        mutationKey: [QueryKeys.ORDERS, selectedOrderUUID],
        mutationFn: (values: { loadingPoint: UUIDType }) =>
          createOrderStop(selectedOrderUUID, values),
      });

    const handleAddStop = (loadingPoint: LoadingPointType): void => {
      const getToastId = addSelfClearingLoadingToast(t('updatingOrder'), 6000);

      mutateAddOrderStop(
        { loadingPoint: loadingPoint.uuid },
        {
          onSuccess: () => {
            toast.update(getToastId(), {
              autoClose: 2000,
              closeButton: true,
              render: t('orderUpdated'),
              type: 'success',
              isLoading: false,
            });
          },
          onError: (error: AxiosError) => {
            toast.update(getToastId(), {
              autoClose: 6000,
              closeButton: true,
              render: generateAxiosErrorMessage(error),
              type: 'error',
              isLoading: false,
            });
          },
          onSettled: () => {
            refetchOrderAndTourTodos();
            refetchTourDataAndOrders();
            queryClient.invalidateQueries({
              queryKey: [QueryKeys.ORDERS, selectedOrderUUID, QueryKeys.STOPS],
            });
            queryClient.invalidateQueries({
              queryKey: [QueryKeys.ROUTES, routeUUID],
            });
          },
        }
      );
    };

    return (
      <Box sx={{ backgroundColor: '#FFFFFF' }} ref={ref}>
        <StopContainerStyles direction="row" py={3} px={6}>
          <BulletContainerStyles>
            <LoadingPointBullet index={index} />
          </BulletContainerStyles>
          <Stack
            spacing={3}
            direction="column"
            ml={6}
            pr={10}
            sx={{
              display: 'inline',
              width: '100%',
            }}
          >
            <LoadingPointAutocomplete
              label={t('stop')}
              onChange={handleAddStop}
              value={null}
              disableClearable={false}
              disabled={isMutatingOrderStop}
            />
          </Stack>
        </StopContainerStyles>
      </Box>
    );
  }
);

StopAddItem.displayName = 'StopAddItem';

export default StopAddItem;
