import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardSubHeader,
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  LoadIcon,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Stack,
} from '@dizzbo/ui';

import { deleteOrderDelivery } from '@core/api';
import { QueryKeys } from '@core/config';

import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { UUIDType } from '@types';
import { AxiosError } from 'axios';
import { PopupState as PopupStateType } from 'material-ui-popup-state/hooks';
import { toast } from 'react-toastify';

const ActionsStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'status',
})(() => {
  return { marginLeft: 'auto !important' };
});

type Props = {
  deliveryUUID: UUIDType;
  deliveryIndex: number;
  setActiveTab: (value: number) => void;
};

export const DeliveryDetailHeader: React.FC<Props> = ({
  deliveryUUID,
  deliveryIndex,
  setActiveTab,
}): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    selectedOrderData,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();
  const { uuid: orderUUID } = selectedOrderData;

  const orderReference: string = useMemo(
    () => selectedOrderData.reference || '',
    [selectedOrderData]
  );

  const { mutate: mutateDelivery } = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DELIVERIES],
    mutationFn: () => deleteOrderDelivery(orderUUID, deliveryUUID),
  });

  const handleDelete = (popupState: PopupStateType) => {
    const getToastId = addSelfClearingLoadingToast(t('deletingDelivery'), 6000);

    mutateDelivery(null, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('deliveryDeleted'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DELIVERIES],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.STOPS],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.DELIVERIES, orderUUID],
        });
        setActiveTab(0);
      },
    });
    popupState.close();
  };

  return (
    <CardSubHeader
      title={`${t('delivery')} ${orderReference} - ${deliveryIndex}`}
      icon={<LoadIcon />}
    >
      <ActionsStyles>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <>
              <IconButton
                size="medium"
                variant="tertiary"
                {...bindTrigger(popupState)}
              >
                <MoreVerticalIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => handleDelete(popupState)}>
                  <ListItemIcon>
                    <ErrorIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText>{t('deleteDelivery')}</ListItemText>
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      </ActionsStyles>
    </CardSubHeader>
  );
};
