import { SxProps } from '@mui/material';
import React, { ChangeEvent, FC, ReactNode } from 'react';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { RemoveIcon, SearchIcon } from '../icons';
import { InputAdornment } from '../InputAdornment';
import { MinimalIconButton } from '../MinimalIconButton';
import { Skeleton } from '../Skeleton';
import { TextField } from '../TextField';

type Props = {
  label: string;
  searchValue: string;
  onSearchChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onSearchClear: () => void;
  sx?: SxProps;
  icon?: ReactNode;
  fullWidth?: boolean;
};

export const SearchInput: FC<Props> = ({
  label,
  searchValue,
  onSearchChange,
  onSearchClear,
  sx,
  icon,
  fullWidth = false,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <TextField
        label={label}
        value={searchValue}
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => onSearchChange(event)}
        fullWidth={fullWidth}
        InputProps={{
          endAdornment: (
            <>
              {searchValue ? (
                <InputAdornment
                  position="end"
                  sx={{ margin: '0 4px 0 0', width: '46px' }}
                >
                  <MinimalIconButton
                    sx={{ opacity: 0.5 }}
                    onClick={() => onSearchClear()}
                  >
                    <RemoveIcon />
                  </MinimalIconButton>
                </InputAdornment>
              ) : (
                <Skeleton
                  variant={'text'}
                  sx={{
                    visibility: 'hidden',
                    width: '50px',
                    minHeight: '30px',
                  }}
                />
              )}
              <InputAdornment position="end">
                <IconButton>{icon || <SearchIcon />}</IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
    </Box>
  );
};
