import { AxiosError } from 'axios';
import i18n from '../i18n/i18n';
import { GoogleAutocompleteAddress } from '../types';

type ResponseType = Record<string, Array<string>>;

export function generateAxiosErrorMessage(
  error: AxiosError,
  displayKeys: boolean = false
): string {
  const { t } = i18n;

  let responseString: string = '';
  if (error?.response?.data && typeof error?.response?.data === 'object') {
    const data = error.response.data as ResponseType;
    Object.keys(data).forEach((key: string) => {
      const errorType: string = key;
      const errors: Array<string> = data[errorType];

      const descriptionHeader: string = `${errorType}:`;
      let descriptionBody: string = '';
      if (Array.isArray(errors)) {
        errors.forEach((error) => {
          descriptionBody = `${error}.${descriptionBody}`;
        });
      } else {
        descriptionBody = `${errors}`;
      }
      responseString = `${displayKeys ? descriptionHeader : ''} ${descriptionBody} `;
    });
  }

  const messageString: string | undefined = error.message
    ? `${error.message}.`
    : '';
  console.log(messageString);

  return `${t('somethingWentWrong')}! ${messageString} ${responseString}`;
}
export function formatAddressString(
  address: GoogleAutocompleteAddress
): string {
  const streetNumber = address.streetNumber ? ` ${address.streetNumber}` : '';
  const street = address.street ? `${address.street}${streetNumber}, ` : '';
  const city = address.city ? `${address.city}, ` : '';
  const postalCode = address.postalCode ? `${address.postalCode}, ` : '';
  const countryName = address.countryName ? `${address.countryName}` : '';
  const country = address.country ? ` (${address.country})` : '';
  return `${street}${city}${postalCode}${countryName}${country}`;
}
