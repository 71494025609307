import { ContactType } from './contacts';
import { DepartmentType } from './departments';
import { AddressType } from './orders';
import { UUIDType } from './utility';

export type CarrierType = {
  uuid: UUIDType;
  name: string;
  secondName: string;
  customerNumber: number;
  active: boolean;
  address: AddressType;
  isLocked: boolean;
};

export type RequestedVehicleType =
  | 'STANDARD_PLANE'
  | 'FRIGO'
  | 'KOFFERSATTEL'
  | 'MEGATRAILER'
  | 'STANDARD_TAUTLINER'
  | 'SPRINTER12'
  | 'PLANE136'
  | 'SMALL_TRUCK'
  | 'OPEN_TRUCK'
  | 'TRAILER_TRAIN'
  | 'JUMBO_TRAILER_TRAIN'
  | 'WALKING_FLOOR_TRAILER'
  | 'TIPPER_TRUCK'
  | 'ALU_TIPPER_TRUCK'
  | 'JOLODA_TRAILER'
  | 'COIL_TROUGH_TRAILER';

export type VehicleType = {
  uuid: UUIDType;
  licensePlate?: string;
  vehicleType: RequestedVehicleType;
  contract: string;
  carrier?: UUIDType;
  department: DepartmentType;
  carrierName: string;
  lat?: number;
  lng?: number;
};

export type VehiclePositionType = {
  uuid: UUIDType;
  licensePlate?: string;
  lat: number;
  lng: number;
};

export type CarrierContactType = {
  uuid: UUIDType;
  order: UUIDType;
  contact: ContactType;
  contactType: string;
};

export enum RequestedVehicleTypeEnum {
  STANDARD_PLANE = 'STANDARD_PLANE',
  FRIGO = 'FRIGO',
  KOFFERSATTEL = 'KOFFERSATTEL',
  MEGATRAILER = 'MEGATRAILER',
  STANDARD_TAUTLINER = 'STANDARD_TAUTLINER',
  SPRINTER12 = 'SPRINTER12',
  PLANE136 = 'PLANE136',
  SMALL_TRUCK = 'SMALL_TRUCK',
  OPEN_TRUCK = 'OPEN_TRUCK',
  TRAILER_TRAIN = 'TRAILER_TRAIN',
  JUMBO_TRAILER_TRAIN = 'JUMBO_TRAILER_TRAIN',
  WALKING_FLOOR_TRAILER = 'WALKING_FLOOR_TRAILER',
  TIPPER_TRUCK = 'TIPPER_TRUCK',
  ALU_TIPPER_TRUCK = 'ALU_TIPPER_TRUCK',
  JOLODA_TRAILER = 'JOLODA_TRAILER',
  COIL_TROUGH_TRAILER = 'COIL_TROUGH_TRAILER',
  TRUCK_WITH_TAILLIFT = 'TRUCK_WITH_TAILLIFT',
}
