import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { toast } from 'react-toastify';

import { deleteOrderProofOfDelivery } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Button,
  CheckIcon,
  Chip,
  DocumentAvatar,
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  PinDownIcon,
  Stack,
  Typography,
  ViewIcon,
} from '@dizzbo/ui';

import { ProofOfDeliveryType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { ProofOfDeliveryReviewDialog } from './ProofOfDeliveryReviewDialog';

const ActionsStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'status',
})<any>(({ theme, status }) => {
  return { marginLeft: 'auto !important' };
});

type Props = {
  orderUUID: UUIDType;
  podData: ProofOfDeliveryType;
};

export const ProofOfDeliveryItem: React.FC<Props> = ({
  orderUUID,
  podData,
}: Props) => {
  const podReviewDialogPopupState = usePopupState({
    variant: 'dialog',
    popupId: 'podReviewDialog',
  });
  const queryClient = useQueryClient();
  const { uuid: podUUID, file, uploadedBy, status, createdAt, title } = podData;
  const { mutate: mutateOrderDocument }: any = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.ORDER_PODS],
    mutationFn: () => deleteOrderProofOfDelivery(orderUUID, podUUID),
  });

  // TODO REFACTOR THIS
  const handleDelete = (popupState) => {
    mutateOrderDocument('', {
      onSuccess: (podData, values) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.ORDER_PODS],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TASKS, orderUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TODOS, orderUUID],
        });
        toast.success(`PoD deleted.`);
      },
      onError: (error, variables, context) => {
        toast.error(`PoD could not bedeleted.`);
      },
    });
    popupState.close();
  };

  const { t } = useTranslation();

  return (
    <Stack spacing={3} direction="row" alignItems="center">
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        onClick={() => window.open(file, '_blank')}
        sx={{ cursor: 'pointer' }}
      >
        <DocumentAvatar file={file} />
        <Stack spacing={0}>
          <Typography variant="buttonRegularSmall" color="primary">
            {title}
          </Typography>
          <Typography variant="labelSmall" color="primary.light">
            {t('uploadedBy')} {uploadedBy?.displayName},{' '}
            {dayjs(createdAt).format('DD.MM.YY h:mm')}
          </Typography>
        </Stack>
      </Stack>
      <ActionsStyles direction="row" spacing={4}>
        {status === 'open' ? (
          <Button
            startIcon={<ViewIcon />}
            size="medium"
            variant="primary"
            onClick={() => podReviewDialogPopupState.open()}
          >
            {t('reviewPod')}
          </Button>
        ) : status === 'accepted' ? (
          <Chip
            label="Approved"
            size="large"
            variant="outlined"
            icon={<CheckIcon />}
          />
        ) : (
          <Chip
            label="Rejected"
            size="large"
            variant="outlined"
            icon={<ErrorIcon />}
          />
        )}
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <IconButton
                size="medium"
                variant="tertiary"
                {...bindTrigger(popupState)}
              >
                <MoreVerticalIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => handleDelete(popupState)}>
                  <ListItemIcon>
                    <ErrorIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText>{t('deletePod')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => window.open(file, '_blank')}>
                  <ListItemIcon>
                    <PinDownIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText>{t('downloadPod')}</ListItemText>
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </ActionsStyles>
      {podData && (
        <ProofOfDeliveryReviewDialog
          orderUUID={orderUUID}
          podUUID={podUUID}
          popupState={podReviewDialogPopupState}
          file={file}
          fileTitle={title}
          fileDate={createdAt}
        />
      )}
    </Stack>
  );
};
