import { useTheme } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useLocalStorage } from 'react-use';

import { Button, ResetIcon, Stack } from '@dizzbo/ui';

import { QueryKeys } from '@core/config';
import { useDeleteOrder } from '@core/hooks';

import { UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { useOrderDetail } from '../hooks';
import { CreateOrderButton } from './CreateOrderButton';

type Props = {
  totalPrice: number | string;
  setTotalPrice(price: number): void;
  isOrderCreationPending: boolean;
};

export const ToolBar: React.FC<Props> = ({
  setTotalPrice,
  totalPrice,
  isOrderCreationPending,
}) => {
  const theme = useTheme();
  const [tempOrderUUID, setTempOrderUUID] = useLocalStorage<UUIDType | null>(
    'plannerTempOrderUUID'
  );
  const queryClient = useQueryClient();

  const { orderData } = useOrderDetail();
  const { mutateAsync: deleteOrder } = useDeleteOrder();

  const handleDeleteOrder = async () => {
    try {
      await deleteOrder(orderData.uuid);
      setTempOrderUUID(null);
      setTotalPrice(0);
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, orderData.uuid],
      });
    } catch (error) {}
  };

  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      spacing={3}
      py={3}
      px={6}
      width="100%"
      sx={{
        backgroundColor: theme.styles.common.pageBackground,
        borderTop: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
      }}
    >
      <CreateOrderButton
        totalPrice={
          typeof totalPrice === 'string' ? totalPrice : totalPrice.toString()
        }
        isOrderCreationPending={isOrderCreationPending}
      />
      <Button
        variant="tertiary"
        startIcon={<ResetIcon />}
        onClick={handleDeleteOrder}
      >
        {t('startOver')}
      </Button>
    </Stack>
  );
};
