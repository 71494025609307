import { styled, useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { createDeliveryGood } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Box,
  Divider,
  IconButton,
  PlusIcon,
  Stack,
  Tab,
  Tabs,
  Tooltip,
} from '@dizzbo/ui';

import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { UUIDType } from '@types';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function a11yProps(index: number) {
  return {
    id: `delivery-good-tab-${index}`,
    'aria-controls': `delivery-good-tabpanel-${index}`,
  };
}

const ActionStyles = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  paddingRight: 24,
}));

type Props = {
  activeTab: number;
  setActiveTab: (number: number) => void;
  deliveryUUID: UUIDType;
  tabs: string[];
};

export const DeliveryGoodsTabs: React.FC<Props> = ({
  activeTab,
  setActiveTab,
  deliveryUUID,
  tabs = [],
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    selectedOrderUUID,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const queryClient = useQueryClient();

  const { mutate: mutateDeliveryGood } = useMutation({
    mutationKey: [
      QueryKeys.ORDERS,
      selectedOrderUUID,
      QueryKeys.DELIVERY_GOODS,
    ],
    mutationFn: () => createDeliveryGood(deliveryUUID),
  });

  const handleAddDeliveryGood = () => {
    const getToastId = addSelfClearingLoadingToast(t('updatingDelivery'), 6000);

    mutateDeliveryGood(null, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('deliveryUpdated'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
        queryClient.invalidateQueries({
          queryKey: [
            QueryKeys.ORDERS,
            selectedOrderUUID,
            QueryKeys.DELIVERY_GOODS,
          ],
        });
      },
    });
  };

  return (
    <Stack
      sx={{
        transition: theme.transitions.create('border '),
        paddingLeft: 3,
        position: 'relative',
      }}
      direction="row"
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Loading plan tabs"
      >
        {tabs.map((tab, index) => (
          <Tab key={tab} label={tab} {...a11yProps(index + 1)} />
        ))}
      </Tabs>
      <ActionStyles>
        <Divider orientation="vertical" sx={{ height: 32, marginRight: 3 }} />
        <Tooltip title="Add good" placement="top">
          <IconButton variant="tertiary" onClick={handleAddDeliveryGood}>
            <PlusIcon />
          </IconButton>
        </Tooltip>
      </ActionStyles>
    </Stack>
  );
};
