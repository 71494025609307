import { useMutation } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { updateOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import {
  Box,
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { useTourOrdersData } from '@order-detail/contexts';
import { CustomerType, OrderType } from '@types';
import { AxiosError } from 'axios';
import { PopupState as PopupStateType } from 'material-ui-popup-state/hooks';
import { FC } from 'react';

type FormData = {
  loader: CustomerType | null;
  invoiceReference: string;
  invoiceNotes: string;
};

type Props = {};

export const CustomerDisplay: FC<Props> = () => {
  const { t } = useTranslation();
  const {
    selectedOrderUUID,
    selectedOrderData,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();

  const { loader } = selectedOrderData;

  const { mutate: mutateOrder } = useMutation<OrderType, AxiosError, FormData>({
    mutationKey: [QueryKeys.ORDERS, selectedOrderUUID],
    mutationFn: (values: FormData) =>
      updateOrder({ orderUUID: selectedOrderUUID, orderData: values }),
  });

  const handleDelete = (popupState: PopupStateType): void => {
    const getToastId = addSelfClearingLoadingToast(t('deletingCustomer'), 6000);

    mutateOrder(
      { loader: null, invoiceReference: '', invoiceNotes: '' },
      {
        onSuccess: () => {
          toast.update(getToastId(), {
            autoClose: 2000,
            closeButton: true,
            render: t('customerRemoved'),
            type: 'success',
            isLoading: false,
          });
        },
        onError: (error: AxiosError) => {
          toast.update(getToastId(), {
            autoClose: 6000,
            closeButton: true,
            render: generateAxiosErrorMessage(error),
            type: 'error',
            isLoading: false,
          });
        },
        onSettled: () => {
          refetchOrderAndTourTodos();
          refetchTourDataAndOrders();
        },
      }
    );
    popupState.close();
  };

  const { customerNumber, name, secondName, isLocked } = loader;

  return (
    <Stack direction="row" spacing={6}>
      <Stack sx={{ minWidth: 80 }}>
        <Typography variant="labelSmall" color="primary.light">
          {t('customerNumber')}
        </Typography>
        <Typography variant="bodyRegular" color="primary.dark">
          {customerNumber}
        </Typography>
      </Stack>
      <Stack width="100%">
        <Typography variant="labelSmall" color="primary.light">
          {t('customer')}
        </Typography>
        <Typography variant="bodyRegular" color="primary.dark">
          {name} {secondName} {isLocked ? '🚫' : ''}
        </Typography>
      </Stack>
      <PopupState variant="popover" popupId="customer-popup-menu">
        {(popupState: PopupStateType) => (
          <Box>
            <IconButton
              size="medium"
              variant="tertiary"
              {...bindTrigger(popupState)}
            >
              <MoreVerticalIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => handleDelete(popupState)}>
                <ListItemIcon>
                  <ErrorIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('removeCustomer')}</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        )}
      </PopupState>
    </Stack>
  );
};
