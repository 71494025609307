import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { updateTour } from '@core/api';
import { QueryKeys } from '@core/config';
import { AssignIcon, Box, LoadingButton, Stack } from '@dizzbo/ui';

import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { OrderType, TourType } from '@types';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { CarrierAutoCompleteTextField } from './CarrierAutoCompleteTextField';

const FormStyles = styled('form')(() => ({
  width: '100%',
}));

const schema = yup.object().shape({
  carrier: yup.object().required('Carrier is required'),
});

type Props = {};

export const CarrierForm: FC<Props> = () => {
  const {
    selectedOrderUUID,
    tourData,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();
  const { uuid: tourUUID } = tourData;

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { mutate: mutateTour, isPending } = useMutation<
    OrderType,
    unknown,
    Partial<TourType>
  >({
    mutationKey: [QueryKeys.ORDERS, selectedOrderUUID],
    mutationFn: (values) =>
      updateTour({ tourUUID: tourUUID, tourData: values }),
  });

  const { t } = useTranslation();

  const onSubmit = (formData) => {
    const getToastId = addSelfClearingLoadingToast(t('updatingTour'), 6000);

    mutateTour(
      { carrier: formData?.carrier?.uuid },
      {
        onSuccess: () => {
          toast.update(getToastId(), {
            autoClose: 2000,
            closeButton: true,
            render: t('carrierSuccessfullyAssigned'),
            type: 'success',
            isLoading: false,
          });
        },
        onError: (error: AxiosError) => {
          toast.update(getToastId(), {
            autoClose: 6000,
            closeButton: true,
            render: generateAxiosErrorMessage(error),
            type: 'error',
            isLoading: false,
          });
        },
        onSettled: () => {
          refetchOrderAndTourTodos();
          refetchTourDataAndOrders();
        },
      }
    );
  };

  return (
    <FormStyles onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={6}>
        <Controller
          name="carrier"
          control={control}
          render={({ field }) => (
            // TODO REFACTOR THIS
            <CarrierAutoCompleteTextField
              label={t('carrier')}
              sx={{ width: '100%' }}
              {...field}
            />
          )}
        />
        <Box>
          <LoadingButton
            size="large"
            type="submit"
            variant="primary"
            loading={isPending}
            startIcon={<AssignIcon />}
            disabled={!isValid}
          >
            {t('assign')}
          </LoadingButton>
        </Box>
      </Stack>
    </FormStyles>
  );
};
