import { Box } from '@dizzbo/ui';
import { SxProps } from '@mui/material';
import { TourSectionStopItemDetails } from './TourSectionStopItemDetails';
import { StopType } from '@types';
import React, { FC } from 'react';

const tourSectionItemStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  padding: '0 24px',
  gap: '8px',
};
type TourSectionStopItemProps = {
  stop: StopType;
  isApproachingStop?: boolean;
};

export const TourSectionStopItem: FC<TourSectionStopItemProps> = ({
  stop,
  isApproachingStop,
}) => {
  return (stop.isTourLoadingStop || stop.isTourUnloadingStop) &&
    !isApproachingStop ? (
    <Box sx={{ ...tourSectionItemStyles }}>
      <Box>
        {stop.isTourLoadingStop && stop.isTourUnloadingStop ? (
          <Box>
            <TourSectionStopItemDetails
              loadingPoint={stop.loadingPoint}
              loadingType={'both'}
              deliveriesCount={
                stop.tourLoadingsCount + stop.tourUnloadingsCount
              }
            />
          </Box>
        ) : null}
      </Box>
      <Box>
        {!stop.isTourLoadingStop && stop.isTourUnloadingStop ? (
          <Box>
            <TourSectionStopItemDetails
              loadingPoint={stop.loadingPoint}
              loadingType={'unloading'}
              deliveriesCount={stop.tourUnloadingsCount}
            />
          </Box>
        ) : null}
      </Box>
      <Box>
        {stop.isTourLoadingStop && !stop.isTourUnloadingStop ? (
          <Box>
            <TourSectionStopItemDetails
              loadingPoint={stop.loadingPoint}
              loadingType={'loading'}
              deliveriesCount={stop.tourLoadingsCount}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  ) : (
    <Box sx={{ ...tourSectionItemStyles }}>
      <TourSectionStopItemDetails
        key={`delivery_index_destination_${stop.uuid}`}
        loadingPoint={stop.loadingPoint}
        loadingType={isApproachingStop ? 'approaching' : 'none'}
        deliveriesCount={0}
      />
    </Box>
  );
};
