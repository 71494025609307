import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getOrders } from '@core/api/orders';
import { QueryKeys } from '@core/config';
import { BannerButton, DocumentOrderIcon } from '@dizzbo/ui';

export const ConfirmOrderTodo: React.FC = () => {
  const { t } = useTranslation();
  const filter = { orderConfirmationTodo: true };

  const { data: ordersData, isPending } = useQuery({
    queryKey: [QueryKeys.ORDERS, filter],
    queryFn: () => getOrders(filter),
  });

  // const openConfirmationTodoOrders = useLinkClickHandler({
  //   pathname: '/orders',
  //   search: 'todo=order_confirmation_todo',
  // });

  return (
    <BannerButton
      count={!isPending ? ordersData?.count : 0}
      label={t('confirmOrder')}
      icon={<DocumentOrderIcon />}
      // onClick={openConfirmationTodoOrders}
    />
  );
};
