import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js';
import { WaypointWithSectionType } from '@types';

export const getWaypointMarker = (
  waypoint?: WaypointWithSectionType
): H.map.DomMarker => {
  const el = document.createElement('div');
  el.style.width = '16px';
  el.style.height = '16px';
  const innerIconUnnamed =
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#4A73C7"/></svg>';
  const innerIconNamed =
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<circle cx="8" cy="8" r="8" fill="#000000"/>' +
    '<circle cx="8" cy="8" r="6" fill="#D7EFEC" />' +
    '</svg>';

  el.innerHTML = waypoint?.name ? innerIconNamed : innerIconUnnamed;
  function changeOpacity(evt) {
    evt.target.style.opacity = 0.6;
  }

  function changeOpacityToOne(evt) {
    evt.target.style.opacity = 1;
  }

  const marker = new H.map.DomMarker(
    { lat: waypoint.lat, lng: waypoint.lng },
    {
      icon: new H.map.DomIcon(el, {
        onAttach: function (clonedElement, domIcon, domMarker) {
          clonedElement.addEventListener('mouseover', changeOpacity);
          clonedElement.addEventListener('mouseout', changeOpacityToOne);
        },
        // the function is called every time marker leaves the viewport
        onDetach: function (clonedElement, domIcon, domMarker) {
          clonedElement.removeEventListener('mouseover', changeOpacity);
          clonedElement.removeEventListener('mouseout', changeOpacityToOne);
        },
      }),
      volatility: true,
      data: waypoint,
    }
  );
  marker.draggable = !waypoint?.name;
  return marker;
};
