import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, Stack } from '@dizzbo/ui';

import { View } from '@dizzbo/core/views';

import { TabNavigation } from '@dizzbo/core/components/Workspaces';

import { WorkspaceKeys } from '@core/config';
import { WorkspacesProvider } from '@dizzbo/core/components/Workspaces/contexts';
import { SearchValueProvider } from '@orders/context';
import { CreateOrderButton } from '../components/CreateOrderButton';
import { OrderList } from '../components/OrderList';
import { OrderListSettings } from '../components/OrderListSettings';

export const OrderListView: FC = () => {
  const { t } = useTranslation();

  return (
    <WorkspacesProvider workspaceNamespace={WorkspaceKeys.ORDER_LIST}>
      <SearchValueProvider>
        <View title="Orders" sx={{ height: '100%' }}>
          <PageHeader title={t('myOrders')}>
            <Stack spacing={3} direction="row">
              <CreateOrderButton size="medium" />
            </Stack>
          </PageHeader>
          <TabNavigation>
            <OrderListSettings />
            <OrderList />
          </TabNavigation>
        </View>
      </SearchValueProvider>
    </WorkspacesProvider>
  );
};
