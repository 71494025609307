import { confirmOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import { OrderType } from '@types';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  addSelfClearingLoadingToast,
  Currency,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SquareIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { useTourOrdersData } from '@order-detail/contexts';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

const SubtitleStyles = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const ContentCardStyles = styled(Card)(() => ({
  width: '10  0%',
  height: 200,
}));

type Props = {
  orderData: OrderType;
  popupState: PopupState;
};

export const OrderConfirmationDialog: React.FC<Props> = ({
  orderData,
  popupState,
}: Props) => {
  const { t } = useTranslation();
  const { refetchTourDataAndOrders, refetchOrderAndTourTodos } =
    useTourOrdersData();
  const {
    uuid: orderUUID,
    reference,
    status,
    loader,
    price,
    stops,
  } = orderData;
  const sheduledAt: string | undefined =
    stops && stops[0] ? stops[0]?.scheduledAt : undefined;

  const { mutate: mutateConfirmOrder, isPending } = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: () => confirmOrder(orderUUID),
  });

  const handleClick = () => {
    const getToastId = addSelfClearingLoadingToast(t('confirmingOrder'), 6000);

    mutateConfirmOrder(null, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('orderConfirmed'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: async (): Promise<void> => {
        await Promise.all([
          refetchOrderAndTourTodos(),
          refetchTourDataAndOrders(),
        ]);
        popupState.close();
      },
    });
  };
  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        <Typography variant="h3" color="primary">
          {reference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {status}
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <SubtitleStyles>
          <Typography variant="h5">{t('confirmOrder')}</Typography>
        </SubtitleStyles>
        <ContentCardStyles variant="filled-green" elevation={0} square>
          <CardContent>
            {loader ? (
              <>
                <Typography variant="bodyRegular">
                  {t('orderConfirmationDialog_customerNumber')}:&nbsp;
                  {loader.customerNumber || t('customerNumberMissing')}
                </Typography>
                <br />
                <Typography variant="bodyRegular">
                  {t('loader')}:&nbsp;
                  {loader.name && loader.secondName
                    ? `${loader.name || ''} ${loader.secondName || ''}`
                    : t('loaderNameMissing')}
                </Typography>
                <br />
              </>
            ) : (
              <>
                <Typography>{t('loaderInfoMissing')}</Typography>
                <br />
              </>
            )}
            {price ? (
              <>
                <Typography variant="bodyRegular">
                  {t('price')}: <Currency value={price} />
                </Typography>
                <br />
              </>
            ) : (
              <>
                <Typography>{t('priceMissing')}</Typography>
                <br />
              </>
            )}
            {sheduledAt ? (
              <>
                <Typography variant="bodyRegular">
                  {t('sheduledAt')}: {dayjs(sheduledAt).format('DD.MM.YYYY')}
                </Typography>
                <br />
              </>
            ) : (
              <>
                <Typography>{t('sheduledAtDateMissing')}</Typography>
                <br />
              </>
            )}
          </CardContent>
        </ContentCardStyles>
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button
            variant="tertiary"
            onClick={() => popupState.close()}
            disabled={isPending}
          >
            {t('cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="primary"
            onClick={handleClick}
            loading={isPending}
            disabled={isPending}
            startIcon={<SquareIcon />}
          >
            {t('confirmOrder')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
