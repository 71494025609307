import { type ColumnDef } from '@dizzbo/ui';
import { OrderType, TypeOfGoodsType } from '@types';
import dayjs from 'dayjs';
import React from 'react';

import {
  BooleanCell,
  CurrencyCell,
  DateCell,
  LoadingPointCell,
  LoadTypeCell,
  StatusCell,
  StatusIconCell,
  StopListCell,
  TextCell,
  TextCellContent,
  TodosCell,
} from './cells';

export const columns: ColumnDef<OrderType>[] = [
  {
    accessorKey: 'todos',
    id: 'statusIcon', // if we have multiple accessorKeys set an explicit ID for this column
    header: '',
    enableHiding: false,
    enableColumnActions: false,
    enableResizing: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    size: 60,
    maxSize: 60,
    muiTableBodyCellProps: {
      align: 'center',
    },
    Cell: StatusIconCell,
  },
  {
    accessorKey: 'loadType',
    id: 'loadType',
    header: 'FTL/LTL',
    enableClickToCopy: false,
    enableSorting: false,
    Cell: LoadTypeCell,
  },
  {
    accessorKey: 'status',
    id: 'status',
    header: 'Status',
    enableColumnOrdering: false,
    Cell: StatusCell,
  },
  {
    accessorKey: 'reference',
    id: 'reference',
    header: 'Order ID',
    enableClickToCopy: true,
    enableSorting: true,
    Cell: TextCell,
  },
  {
    accessorKey: 'tour.reference',
    id: 'tourReference',
    header: 'Tour ID',
    enableClickToCopy: true,
    enableSorting: false,
    Cell: TextCell,
  },
  // there seems to be a bug on sorting columns if the accessorKey is nested and undefined
  {
    accessorFn: (row) =>
      row.loader ? `${row.loader?.name} ${row.loader?.secondName}` : '-',
    id: 'loader',
    header: 'Customer',
    enableClickToCopy: true,
    enableSorting: true,
    Cell: TextCell,
  },
  // there seems to be a bug on sorting columns if the accessorKey is nested and undefined
  {
    accessorFn: (row) =>
      row.carrier ? `${row.carrier?.name} ${row.carrier?.secondName}` : '-',
    id: 'carrier',
    header: 'Carrier',
    enableSorting: true,
    minSize: 350,
    Cell: TextCell,
  },
  {
    accessorFn: (row) => (row.price ? row.price : ''),
    id: 'price',
    header: 'Customer Price',
    enableSorting: true,
    Cell: CurrencyCell,
  },
  {
    accessorFn: (row) => (row.tour.price ? row.tour.price : ''),
    id: 'costs',
    header: 'Carrier Price',
    enableSorting: true,
    Cell: CurrencyCell,
  },
  {
    accessorFn: (row) => (row.profit ? row.profit : ''),
    id: 'profit',
    header: 'Gross Profit',
    enableSorting: false,
    Cell: CurrencyCell,
  },
  // LOADING POINT #########################################################
  {
    accessorKey: 'stops',
    id: 'firstLoadingPoint',
    header: 'First Loading Point',
    enableSorting: false,
    minSize: 350,
    Cell: ({
      cell: {
        row: { original },
      },
      table,
    }) => (
      <LoadingPointCell
        loadingPoint={original.stops[0]?.loadingPoint}
        highlighted={original.todos.length > 0}
        table={table}
      />
    ),
  },
  {
    accessorKey: 'stops',
    id: 'firstLoadingPointDate',
    header: 'Loading Date',
    enableSorting: false,
    Cell: ({
      cell: {
        row: { original },
      },
      table,
    }) => (
      <TextCellContent highlighted={original.todos.length > 0} table={table}>
        {original.stops[0]?.scheduledAt &&
          dayjs(original.stops[0]?.scheduledAt).format('DD.MM.YYYY')}
      </TextCellContent>
    ),
  },
  {
    accessorKey: 'stops',
    id: 'firstLoadingPointTime',
    header: 'Loading Time',
    enableSorting: false,
    Cell: ({
      cell: {
        row: { original },
      },
      table,
    }) => (
      <TextCellContent highlighted={original.todos.length > 0} table={table}>
        {original.stops[0]?.scheduledAt &&
          dayjs(original.stops[0]?.scheduledAt).format('hh:mm')}
      </TextCellContent>
    ),
  },
  // UNLOADING POINT #########################################################
  {
    accessorKey: 'stops',
    id: 'lastLoadingPoint',
    header: 'Last Loading Point',
    enableSorting: false,
    minSize: 350,
    Cell: ({
      cell: {
        row: { original },
      },
      table,
    }) => (
      <LoadingPointCell
        loadingPoint={original.stops[original?.stops?.length - 1]?.loadingPoint}
        highlighted={original.todos.length > 0}
        table={table}
      />
    ),
  },
  {
    accessorKey: 'stops',
    id: 'lastLoadingPointDate',
    header: 'Unloading Date',
    enableSorting: false,
    Cell: ({
      cell: {
        row: { original },
      },
      table,
    }) => (
      <TextCellContent highlighted={original.todos.length > 0} table={table}>
        {original.stops[original.stops.length - 1]?.scheduledAt &&
          dayjs(original.stops[original.stops.length - 1]?.scheduledAt).format(
            'DD.MM.YYYY'
          )}
      </TextCellContent>
    ),
  },
  {
    accessorKey: 'stops',
    id: 'lastLoadingPointTime',
    header: 'Unloading Time',
    enableSorting: false,
    Cell: ({
      cell: {
        row: { original },
      },
      table,
    }) => (
      <TextCellContent highlighted={original.todos.length > 0} table={table}>
        {original.stops[original.stops.length - 1]?.scheduledAt &&
          dayjs(original.stops[original.stops.length - 1]?.scheduledAt).format(
            'hh:mm'
          )}
      </TextCellContent>
    ),
  },
  {
    accessorKey: 'stops',
    id: 'stopList',
    header: 'Stops',
    enableSorting: false,
    Cell: StopListCell,
  },
  {
    accessorKey: 'typesOfGoods',
    id: 'typesOfGoods',
    enableSorting: false,
    header: 'Type of Goods',
    Cell: ({ renderedCellValue, table }) => (
      <TextCellContent
        highlighted={(renderedCellValue as TypeOfGoodsType[])?.length > 0}
        table={table}
      >
        {(renderedCellValue as TypeOfGoodsType[])
          .map(({ title }) => title)
          .join(', ')}
      </TextCellContent>
    ),
  },
  {
    accessorKey: 'weight',
    id: 'weight',
    header: 'Weight',
    enableSorting: false,
    Cell: TextCell,
  },
  {
    accessorKey: 'distance',
    id: 'distance',
    header: 'Distance',
    enableSorting: false,
    Cell: ({
      cell: {
        row: { original },
      },
      renderedCellValue,
      table,
    }) => (
      <TextCellContent highlighted={original.todos.length > 0} table={table}>
        {typeof renderedCellValue === 'number'
          ? `${(renderedCellValue / 1000).toFixed(3)} km`
          : null}
      </TextCellContent>
    ),
  },
  {
    accessorKey: 'palletExchange',
    id: 'palletExchange',
    header: 'Pallet Exchange',
    enableSorting: false,
    Cell: BooleanCell,
  },
  {
    accessorKey: 'neutralDelivery',
    id: 'neutralDelivery',
    header: 'Neutral Delivery',
    enableSorting: false,
    Cell: BooleanCell,
  },
  {
    accessorKey: 'assignee.displayName',
    id: 'assignee',
    header: 'Assigned to',
    enableSorting: false,
    Cell: TextCell,
  },
  {
    // accessorKey: 'executingDepartment.name',
    accessorFn: (row) => row.executingDepartment?.name,
    id: 'executingDepartment',
    header: 'Executing Team',
    enableSorting: false,
    Cell: TextCell,
  },
  {
    accessorKey: 'createdAt',
    id: 'created_at',
    header: 'Created',
    Cell: DateCell,
  },
  {
    accessorKey: 'todos',
    id: 'todos',
    header: 'Todos',
    enableHiding: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    enableColumnActions: false,
    enableResizing: false,
    enableSorting: false,
    Cell: TodosCell,
  },
];

export const defaultColumnOrder: Array<string> = columns.map(
  (column) => column.id
);
export const defaultColumnVisibility = columns.reduce<
  Partial<Record<keyof OrderType, boolean>>
>((acc, column) => {
  acc[column.id] = true;
  return acc;
}, {});

export const defaultColumnPinning: {
  left: (keyof OrderType)[];
  right: (keyof OrderType)[];
} = { left: ['statusIcon' as any], right: ['todos'] };

export const canHideColumn = (columndId: string) => {
  const result = columns.find((column) => column.id === columndId);
  if (result) {
    if (result.enableHiding === false) return false;
    return true;
  }
  return true;
};

export const getColumnLabel = (columndId: string) => {
  const result = columns.find((column) => column.id === columndId);
  if (result) {
    return result.header;
  }
  return '';
};
