import { QueryKeys } from '@core/config';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { reviewTour } from '@core/api';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SquareIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { useTourOrdersData } from '@order-detail/contexts';
import { AxiosError } from 'axios';

const SubtitleStyles = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const ContentCardStyles = styled(Card)(() => ({
  width: '10  0%',
  height: 200,
}));

type Props = {
  popupState: PopupState;
};

export const TourReviewDialog: FC<Props> = ({ popupState }) => {
  const { t } = useTranslation();
  const { tourData, refetchOrderAndTourTodos, refetchTourDataAndOrders } =
    useTourOrdersData();
  const { uuid: tourUUID } = tourData;

  const { reference, status } = useMemo(() => {
    const reference: string = tourData.reference || '';
    const status: string = tourData.status || 'DRAFT';
    return { reference, status };
  }, [tourData]);

  const { mutate: mutateReviewOrder, isPending } = useMutation({
    mutationKey: [QueryKeys.TOURS, tourUUID],
    mutationFn: () => reviewTour(tourUUID),
  });

  const handleClick = () => {
    const getToastId = addSelfClearingLoadingToast(
      t('markingTourAsReviewed'),
      6000
    );

    mutateReviewOrder(null, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('tourMarkedAsReviewed'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: async (): Promise<void> => {
        await Promise.all([
          refetchOrderAndTourTodos(),
          refetchTourDataAndOrders(),
        ]);
        popupState.close();
      },
    });
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        <Typography variant="h3" color="primary">
          {reference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {status}
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <SubtitleStyles>
          <Typography variant="h5">{t('tourReview')}</Typography>
        </SubtitleStyles>
        <ContentCardStyles variant="filled-green" elevation={0} square>
          <CardContent>
            <Typography variant="bodyRegular">
              {t('tourReference')}:&nbsp;{tourData.reference || ''}
            </Typography>
            <br />
            <Typography variant="bodyRegular">
              {t('price')}:&nbsp;{tourData.price}
            </Typography>
          </CardContent>
        </ContentCardStyles>
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button
            variant="tertiary"
            onClick={() => popupState.close()}
            disabled={isPending}
          >
            {t('cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="primary"
            onClick={handleClick}
            disabled={isPending}
            loading={isPending}
            startIcon={<SquareIcon />}
          >
            {t('markTourAsReviewed')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
