import { Box, Button, colors, RemoveIcon, ResetIcon } from '@dizzbo/ui';
import { oxford, red } from '@dizzbo/ui/theme/colors';
import { styled } from '@mui/system';
import React, { FC } from 'react';

const BoxButtonContainer = styled(Box)(({}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'absolute',
  right: 0,
  top: 0,
  background: `linear-gradient(90deg, transparent , ${colors.turquoise['40']} 15%)`,
  borderRadius: 0,
  height: '100% ',
  width: '200px',
}));

type Props = {
  onEditButtonClick: () => void;
  onRemoveButtonClick: () => void;
  disabled?: boolean;
};

export const EditApproachStopButton: FC<Props> = ({
  onEditButtonClick,
  onRemoveButtonClick,
  disabled = false,
}) => {
  return (
    <BoxButtonContainer>
      <Box
        sx={{
          padding: '6px 36px 6px 6px',
          minWidth: '68px',
          minHeight: '36px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '8px',
        }}
      >
        <Button
          variant="text"
          onClick={onEditButtonClick}
          disabled={disabled}
          sx={{
            minWidth: '30px',
            minHeight: '30px',
            borderRadius: '100%',
            padding: '0',
            color: oxford['40'],
            '&:hover': {
              color: oxford['100'],
            },
          }}
        >
          <ResetIcon sx={{ width: '20px', height: '20px' }} />
        </Button>
        <Button
          size="small"
          variant="text"
          onClick={onRemoveButtonClick}
          disabled={disabled}
          sx={{
            minWidth: '30px',
            minHeight: '30px',
            borderRadius: '100%',
            padding: '0',
            color: red['80'],
            '&:hover': {
              color: red['150'],
            },
          }}
        >
          <RemoveIcon sx={{ width: '20px', height: '20px' }} />
        </Button>
      </Box>
    </BoxButtonContainer>
  );
};
