import { getStatusColor } from '@dizzbo/core';
import { ArrowRightIcon, BannerTodoItem, Box, Typography } from '@dizzbo/ui';
import { oxford } from '@dizzbo/ui/theme/colors';
import { SxProps } from '@mui/material';
import { useTourOrdersData } from '@order-detail/contexts';
import {
  AddressType,
  OrderTodosTasksType,
  OrderTodoType,
  OrderType,
  StopType,
  TourTodosTasksType,
  TourTodoType,
  UUIDType,
} from '@types';
import { assertNever, generateTodoPopupStates } from '@utils';
import { PopupState } from 'material-ui-popup-state/hooks';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoadingTimeDialog,
  OrderConfirmationDialog,
  OrderReviewDialog,
  PoDReviewDialog,
  PoDUploadDialog,
  TourReviewDialog,
  TransportContractDialog,
  UnloadingTimeDialog,
} from './Dialogs';

type Props = {
  type: 'order' | 'tour';
  uuid: UUIDType;
};

export const TodoElement: FC<Props> = ({ type, uuid }) => {
  const { t } = useTranslation();
  const {
    tourData,
    tourOrders,
    ordersTodos,
    ordersTasks,
    tourTodos,
    tourTasks,
  } = useTourOrdersData();

  const reference: string =
    type === 'order'
      ? tourOrders[uuid].reference || ''
      : tourData.reference || '';

  const popupStates: Record<OrderTodoType, PopupState> =
    generateTodoPopupStates(reference);

  const orderData: OrderType | null =
    type === 'order' ? tourOrders[uuid] : null;

  const statusColor: string = getStatusColor(
    type === 'order' ? orderData.status : tourData.status || 'DRAFT'
  );

  const todos: Array<OrderTodoType> | Array<TourTodoType> =
    type === 'order' ? ordersTodos[uuid] : tourTodos;
  const tasks: OrderTodosTasksType | TourTodosTasksType =
    type === 'order' ? ordersTasks[uuid] : tourTasks;

  const stops: Array<StopType> = orderData?.stops || [];
  let addressFrom: AddressType | undefined;
  let addressTo: AddressType | undefined;
  if (stops.length > 1) {
    const stopFrom: StopType = stops[0];
    addressFrom = stopFrom?.loadingPoint?.address;

    const stopTo: StopType = stops[stops.length - 1];
    addressTo = stopTo?.loadingPoint?.address;
  }

  const displayStringFrom: string =
    addressFrom && addressFrom.country && addressFrom.postalCode
      ? `${addressFrom.country}${addressFrom.postalCode}`
      : t('from');
  const displayStringTo: string =
    addressTo && addressTo.country && addressTo.postalCode
      ? `${addressTo.country}${addressTo.postalCode}`
      : t('to');

  const loaderDisplayName: string = orderData?.loader
    ? orderData.loader?.name || t('loaderNameMissing')
    : t('loaderMissing');

  function getTodoBannerContent(
    todoType: OrderTodoType | TourTodoType,
    keybase: string,
    username: string,
    disableAction: boolean,
    popupState: PopupState
  ): ReactElement {
    switch (todoType) {
      case 'order_confirmation_todo':
        return (
          <>
            <BannerTodoItem
              label={t('confirmOrder')}
              username={username}
              buttonLabel={t('sendOrderConfirmation')}
              disableAction={disableAction}
              subTodos={tasks[todoType]}
              buttonAction={() => popupState.open()}
              uuid={orderData.uuid}
              key={`order_banner_${keybase}`}
            />
            <OrderConfirmationDialog
              orderData={orderData}
              popupState={popupState}
              key={`order_dialog_${keybase}`}
            />
          </>
        );
      case 'transport_contract_todo':
        return (
          <>
            <BannerTodoItem
              label={t('confirmTransportContract')}
              username={username}
              buttonLabel={t('sendTransportContract')}
              disableAction={disableAction}
              subTodos={tasks[todoType]}
              buttonAction={() => popupState.open()}
              uuid={tourData.uuid}
              key={`order_banner_${keybase}`}
            />
            <TransportContractDialog
              tourData={tourData}
              popupState={popupState}
              key={`order_dialog_${keybase}`}
            />
          </>
        );
      case 'loading_time_todo':
        return (
          <>
            <BannerTodoItem
              label={t('loadingTime')}
              username={username}
              buttonLabel={t('setLoadingTime')}
              disableAction={false}
              subTodos={tasks[todoType]}
              buttonAction={() => popupState.open()}
              uuid={orderData.uuid}
              key={`order_banner_${keybase}`}
            />
            <LoadingTimeDialog
              orderData={orderData}
              popupState={popupState}
              key={`order_dialog_${keybase}`}
            />
          </>
        );
      case 'unloading_time_todo':
        return (
          <>
            <BannerTodoItem
              label={t('unloadingTime')}
              username={username}
              buttonLabel={t('setUnloadingTime')}
              disableAction={false}
              subTodos={tasks[todoType]}
              buttonAction={() => popupState.open()}
              uuid={orderData.uuid}
              key={`order_banner_${keybase}`}
            />
            <UnloadingTimeDialog
              orderData={orderData}
              popupState={popupState}
              key={`order_dialog_${keybase}`}
            />
          </>
        );
      case 'pod_upload_todo':
        return (
          <>
            <BannerTodoItem
              label={t('podUpload')}
              username={username}
              buttonLabel={t('uploadPoD')}
              disableAction={false}
              subTodos={tasks[todoType]}
              buttonAction={() => popupState.open()}
              uuid={orderData.uuid}
              key={`order_banner_${keybase}`}
            />
            <PoDUploadDialog
              orderData={orderData}
              popupState={popupState}
              key={`order_dialog_${keybase}`}
            />
          </>
        );
      case 'pod_review_todo':
        return (
          <>
            <BannerTodoItem
              label={t('podReview')}
              username={username}
              buttonLabel={t('reviewPoD')}
              disableAction={false}
              subTodos={tasks[todoType]}
              buttonAction={() => popupState.open()}
              uuid={orderData.uuid}
              key={`order_banner_${keybase}`}
            />
            <PoDReviewDialog
              orderData={orderData}
              subTodos={tasks[todoType]}
              popupState={popupState}
              key={`order_dialog_${keybase}`}
            />
          </>
        );
      case 'order_review_todo':
        return (
          <>
            <BannerTodoItem
              label={t('orderReview')}
              username={username}
              buttonLabel={t('reviewOrder')}
              disableAction={disableAction}
              subTodos={tasks[todoType]}
              buttonAction={() => popupState.open()}
              uuid={orderData.uuid}
              key={`order_banner_${keybase}`}
            />
            <OrderReviewDialog
              orderData={orderData}
              popupState={popupState}
              key={`order_dialog_${keybase}`}
            />
          </>
        );
      case 'tour_review_todo':
        return (
          <>
            <BannerTodoItem
              label={t('tourReview')}
              username={username}
              buttonLabel={t('reviewTour')}
              disableAction={disableAction}
              subTodos={tasks[todoType]}
              buttonAction={() => popupState.open()}
              uuid={tourData.uuid}
              key={`order_banner_${keybase}`}
            />
            <TourReviewDialog
              popupState={popupState}
              key={`order_dialog_${keybase}`}
            />
          </>
        );
      default:
        assertNever(todoType);
        return null;
    }
  }

  const todoElementStyles: SxProps = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    minHeight: '100px',
  };

  const leftContainerStyles: SxProps = {
    position: 'relative',
    display: 'flex',
    minWidth: '230px',
    padding: '40px 0 0 60px',
  };

  const rightContainerStyles: SxProps = {
    display: 'flex',
    minWidth: '600px',
    paddingTop: '24px',
  };

  const arrowStyles: SxProps = {
    borderLeft: `4px solid ${statusColor}`,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '36px',
  };

  const iconStyles: SxProps = {
    display: 'flex',
    position: 'absolute',
    left: '38px',
    transform: 'translateX(-50%)',
    zIndex: 1,
    width: '28px',
    height: '28px',
    backgroundColor: statusColor,
    border: `2px solid #FFFFFF`,
    borderRadius: 32,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
  };

  return (
    <Box sx={{ ...todoElementStyles }}>
      <Box sx={{ ...leftContainerStyles }}>
        <Box sx={{ flex: 1, ...arrowStyles }}></Box>
        <Box sx={{ ...iconStyles }}>
          <ArrowRightIcon
            fontSize="small"
            sx={{ width: '16px', height: '16px' }}
          />
        </Box>

        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="h4"
            component={'span'}
            whiteSpace={'nowrap'}
            color={oxford[40]}
          >
            {t(type === 'tour' ? 'tourWithReference' : 'orderWithReference', {
              reference,
            })}
          </Typography>
          {type === 'order' ? (
            <>
              <Typography
                variant="bodyRegularSmall"
                component={'span'}
                whiteSpace={'nowrap'}
                color={oxford[40]}
              >
                {displayStringFrom}&#8594;
                {displayStringTo}
              </Typography>
              <Typography
                variant="bodyRegularSmall"
                component={'span'}
                whiteSpace={'nowrap'}
                color={oxford[40]}
              >
                {loaderDisplayName}
              </Typography>
            </>
          ) : null}
        </Box>
      </Box>
      <Box sx={{ ...rightContainerStyles }}>
        <Box sx={{ alignSelf: 'center', width: '100%', padding: '10px 0' }}>
          {todos
            ? todos.map(
                (todoType: OrderTodoType | TourTodoType, index: number) => {
                  const uniqueKeyBase: string = `${reference}_${todoType}_${index}`;
                  const disableAction: boolean = tasks[todoType].length > 0;

                  if (type === 'order') {
                    return todoType !== 'tour_review_todo' &&
                      todoType !== 'transport_contract_todo' ? (
                      <Box key={`order_box_${uniqueKeyBase}`}>
                        {getTodoBannerContent(
                          todoType,
                          uniqueKeyBase,
                          orderData?.loader?.name || 'user',
                          disableAction,
                          popupStates[todoType]
                        )}
                      </Box>
                    ) : null;
                  } else {
                    return todoType === 'tour_review_todo' ||
                      todoType === 'transport_contract_todo' ? (
                      <Box key={`order_box_${uniqueKeyBase}`}>
                        {getTodoBannerContent(
                          todoType,
                          uniqueKeyBase,
                          orderData?.loader?.name || 'user',
                          disableAction,
                          popupStates[todoType]
                        )}
                      </Box>
                    ) : null;
                  }
                }
              )
            : null}
        </Box>
      </Box>
    </Box>
  );
};
