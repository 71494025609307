import { useMutation } from '@tanstack/react-query';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { FC, useMemo } from 'react';
import { toast } from 'react-toastify';

import { updateOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import {
  CheckIcon,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  LoadingButton,
  Menu,
  MenuItem,
  SwitchIcon,
} from '@dizzbo/ui';
import { useTourOrdersData } from '@order-detail/contexts';
import { OrderType, YesNoDefaultType } from '@types';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

type Props = {
  field: 'podRequired' | 'paperDocumentsRequired';
};

export const RequirementSelect: FC<Props> = ({ field }) => {
  const {
    selectedOrderUUID,
    selectedOrderData,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();

  const {
    podRequired,
    paperDocumentsRequired,
    podRequiredDefault,
    paperDocumentsRequiredDefault,
  } = useMemo(() => {
    return {
      podRequired: selectedOrderData?.podRequired,
      paperDocumentsRequired: selectedOrderData?.paperDocumentsRequired,
      podRequiredDefault: selectedOrderData?.podRequiredDefault,
      paperDocumentsRequiredDefault:
        selectedOrderData?.paperDocumentsRequiredDefault,
    };
  }, [selectedOrderData]);

  const value: YesNoDefaultType =
    field === 'podRequired' ? podRequired : paperDocumentsRequired;
  const defaultValue: boolean | null =
    field === 'podRequired'
      ? podRequiredDefault
      : paperDocumentsRequiredDefault;

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'requirementDialog',
  });

  const { mutate: mutateOrder, isPending } = useMutation<
    OrderType,
    unknown,
    Partial<OrderType>
  >({
    mutationKey: [QueryKeys.ORDERS, selectedOrderUUID],
    mutationFn: (values) =>
      updateOrder({ orderUUID: selectedOrderUUID, orderData: values }),
  });

  const { t } = useTranslation();

  const handleToggle = (value: YesNoDefaultType) => () => {
    const getToastId = addSelfClearingLoadingToast(t('updatingOrder'), 6000);

    mutateOrder(
      { [field]: value },
      {
        onSuccess: () => {
          toast.update(getToastId(), {
            autoClose: 2000,
            closeButton: true,
            render: t('orderUpdated'),
            type: 'success',
            isLoading: false,
          });
        },
        onError: (error: AxiosError) => {
          toast.update(getToastId(), {
            autoClose: 6000,
            closeButton: true,
            render: generateAxiosErrorMessage(error),
            type: 'error',
            isLoading: false,
          });
        },
        onSettled: () => {
          refetchOrderAndTourTodos();
          refetchTourDataAndOrders();
        },
      }
    );
    popupState.close();
  };

  const labels: Record<YesNoDefaultType, string> = {
    YES: t('yes'),
    NO: t('no'),
    DEFAULT: `${t(defaultValue ? 'yes' : 'no')} (${t('cusomerPreset')})`,
  };

  return (
    <>
      <LoadingButton
        variant="secondary"
        size="medium"
        endIcon={<SwitchIcon />}
        loading={isPending}
        {...bindTrigger(popupState)}
        sx={{ fontSize: 13, fontWeight: 400 }}
      >
        {field === 'podRequired'
          ? t('podRequired')
          : t('paperDocumentsRequired')}
        : {labels[value]}
      </LoadingButton>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: { sx: { minWidth: 264 } },
        }}
      >
        <MenuItem>
          <ListItemButton role="button" onClick={handleToggle('YES')} dense>
            <ListItemText>{labels.YES}</ListItemText>
            {value === 'YES' && (
              <ListItemIcon>
                <CheckIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
            )}
          </ListItemButton>
        </MenuItem>
        <MenuItem>
          <ListItemButton role="button" onClick={handleToggle('NO')} dense>
            <ListItemText>{labels.NO}</ListItemText>
            {value === 'NO' && (
              <ListItemIcon>
                <CheckIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
            )}
          </ListItemButton>
        </MenuItem>
        {defaultValue !== null ? (
          <MenuItem>
            <ListItemButton
              role="button"
              onClick={handleToggle('DEFAULT')}
              dense
            >
              <ListItemText>{labels.DEFAULT}</ListItemText>
              {value === 'DEFAULT' && (
                <ListItemIcon>
                  <CheckIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
              )}
            </ListItemButton>
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};
