import { ArrowDownIcon, Box, colors, Typography } from '@dizzbo/ui';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/material';
import { formatDuration } from '@utils';
import { RouteSectionType } from '@types';

const chipStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  minWidth: '80px',
  minHeight: '40px',
  borderRadius: '21px',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '50px',
  paddingRight: '40px',
  color: colors.oxford['15'],
};

const footnotesStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
};

type FootnoteProps = {
  section: RouteSectionType;
};

export const Footnote: FC<FootnoteProps> = ({ section }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ ...chipStyles }}>
        <ArrowDownIcon />
      </Box>
      <Box sx={{ ...footnotesStyles }}>
        {section?.distance ? (
          <Typography variant="footnote" color={colors.oxford[40]}>
            {(section.distance / 1000).toFixed(1)} km
          </Typography>
        ) : null}
        {section?.duration ? (
          <Typography variant="footnote" color={colors.oxford[40]}>
            {formatDuration(section.duration, 'hm')}
          </Typography>
        ) : null}
        {section?.toll ? (
          <Typography variant="footnote" color={colors.oxford[40]}>
            {t('toll')} {section.toll} €
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};
