import { useMutation } from '@tanstack/react-query';
import { bindDialog } from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@dizzbo/ui';
import { PopupState } from 'material-ui-popup-state/hooks';

import { deleteOrder, updateOrder } from '@core/api';
import { CreateOrderForm } from '@order-detail/components/CreateOrderForm';
import { useTourOrdersData } from '@order-detail/contexts';
import { OrderType } from '@types';

interface CreateOrderDialogProps {
  orderData: OrderType;
  popupState: PopupState;
}

export const CreateOrderDialog: FC<CreateOrderDialogProps> = ({
  orderData,
  popupState,
}) => {
  const {
    selectedOrderData,
    refetchTourDataAndOrders,
    refetchOrderAndTourTodos,
  } = useTourOrdersData();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: mutateDeleteOrder } = useMutation({
    mutationFn: (values: any) => deleteOrder(values.orderUUID),
  });

  const { mutate: mutateUpdateOrder } = useMutation({
    mutationFn: () =>
      updateOrder({
        orderUUID: selectedOrderData?.uuid,
        orderData: {
          status: 'DRAFT',
        },
      }),
    onSuccess: () => {
      refetchTourDataAndOrders();
      refetchOrderAndTourTodos();
      navigate(`/orders/${selectedOrderData.uuid}`);
    },
  });

  const handleConfirmOrder = (): void => {
    mutateUpdateOrder();
  };

  const handleCloseDialog = (): void => {
    mutateDeleteOrder({ orderUUID: selectedOrderData?.uuid });
    refetchTourDataAndOrders();
    popupState.close();
  };

  return orderData ? (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
      }}
      {...bindDialog(popupState)}
      onClose={handleCloseDialog}
      fullWidth={true}
      sx={{ '.MuiDialog-paper': { maxWidth: 1280, minHeight: '90vh' } }}
      scroll="paper"
    >
      <DialogTitle onClose={handleCloseDialog}>
        {t('createNewOrder')}
      </DialogTitle>
      <DialogContent dividers>
        <CreateOrderForm />
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button variant="tertiary" onClick={handleCloseDialog}>
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            form="form"
            disabled={false}
            onClick={handleConfirmOrder}
          >
            {t('saveAsDraft')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  ) : null;
};
