import FormControlLabel from '@mui/material/FormControlLabel';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { updateOrder } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  MultiLineTextfield,
  Select,
  Stack,
  Switch,
  TextField,
} from '@dizzbo/ui';

import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import {
  OrderType,
  RequestedVehicleType,
  RequestedVehicleTypeEnum,
} from '@types';
import { getVehicleTypeLabel } from '@utils';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

type FormData = {
  notes: string;
  requestedVehicleType: RequestedVehicleType;
  transportInsuranceValue: string;
};

type FullFormData = FormData & {
  transportInsurance: boolean;
};

type Props = {
  orientation?: 'horizontal' | 'vertical';
};

export const OrderInformation: React.FC<Props> = ({
  orientation = 'vertical',
}) => {
  const {
    selectedOrderUUID,
    selectedOrderData,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();

  const { notes, requestedVehicleType, transportInsuranceValue } =
    useMemo(() => {
      const notes: string = selectedOrderData?.notes || '';
      const requestedVehicleType: RequestedVehicleType | undefined =
        selectedOrderData?.requestedVehicleType;
      const transportInsuranceValue: number | undefined =
        selectedOrderData?.transportInsuranceValue;

      return { notes, requestedVehicleType, transportInsuranceValue };
    }, [selectedOrderData]);

  const { t } = useTranslation();

  const {
    mutate: mutateOrder,
    isError,
    error,
  } = useMutation<OrderType, AxiosError, FormData>({
    mutationKey: [QueryKeys.ORDERS, selectedOrderUUID],
    mutationFn: (values: FormData) => {
      const { notes, requestedVehicleType, transportInsuranceValue } = values;
      const parsedFormData: Partial<OrderType> = {
        notes,
        requestedVehicleType,
        transportInsuranceValue:
          typeof transportInsuranceValue === 'string'
            ? Number(transportInsuranceValue)
            : 0,
      };
      return updateOrder({
        orderUUID: selectedOrderUUID,
        orderData: parsedFormData,
      });
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useForm({
    mode: 'all',
    values: {
      notes,
      requestedVehicleType,
      transportInsurance: !!transportInsuranceValue,
      transportInsuranceValue:
        transportInsuranceValue && typeof transportInsuranceValue === 'number'
          ? transportInsuranceValue.toFixed(2)
          : '',
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);
  const watchTransportInsurance = watch('transportInsurance');

  useEffect(() => {
    if (!watchTransportInsurance) {
      setValue('transportInsuranceValue', '');
    }
  }, [watchTransportInsurance]);

  const onSubmit = (formData: FullFormData) => {
    const getToastId = addSelfClearingLoadingToast(t('updatingOrder'), 6000);

    const { notes, requestedVehicleType, transportInsuranceValue } = formData;
    const parsedFormData: FormData = {
      notes,
      requestedVehicleType,
      transportInsuranceValue,
    };

    mutateOrder(parsedFormData, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('orderUpdated'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
      },
    });
  };

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: orientation === 'horizontal' ? 0 : 374,
      }}
    >
      <CardHeader title={t('orderInformation')} variant="small" />
      <CardContent>
        <Stack
          spacing={6}
          direction={orientation === 'horizontal' ? 'row' : 'column'}
          divider={
            orientation === 'vertical' && (
              <Divider orientation="horizontal" flexItem />
            )
          }
        >
          <Controller
            name="requestedVehicleType"
            control={control}
            defaultValue="STANDARD_PLANE"
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="vehicle-type-label">
                  {t('requestedVehicleType')}
                </InputLabel>
                <Select
                  labelId="vehicle-type-label"
                  id="vehicle-type"
                  label="vehicle-type"
                  {...field}
                >
                  {Object.values(RequestedVehicleTypeEnum).map(
                    (vehicleType) => (
                      <MenuItem key={vehicleType} value={vehicleType}>
                        {getVehicleTypeLabel(vehicleType)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
          />
          <Box sx={{ width: '100%' }}>
            <Controller
              name="notes"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <MultiLineTextfield
                  label={t('additionalInternalInformationAndNotes')}
                  characterLimit={400}
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  placeholder={'-'}
                  {...field}
                />
              )}
            />
          </Box>

          <Box sx={{ flexDirection: 'column', width: '100%' }}>
            <Controller
              name="transportInsurance"
              control={control}
              render={({ field: { value, ...field } }) => (
                <FormControlLabel
                  label={t('transportInsuranceRequired')}
                  control={
                    <Switch
                      {...field}
                      checked={!!value}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              )}
            />
            {watchTransportInsurance ? (
              <Box sx={{ display: 'flex', marginTop: 6 }}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginRight: 4, marginLeft: 3 }}
                />
                <Controller
                  name="transportInsuranceValue"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      label={t('valueOfGoods')}
                      fullWidth
                      required
                      type="number"
                      error={hasFieldError(field)}
                      helperText={fieldError(field)}
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            ) : null}
          </Box>
        </Stack>
        <AutoSave
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isValid={isValid}
          control={control}
        />
      </CardContent>
    </Card>
  );
};
