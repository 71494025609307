import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React from 'react';

import {
  Box,
  InfoOutlineIcon,
  LicensePlate,
  Tooltip,
  TruckIcon,
  Typography,
} from '@dizzbo/ui';
import { getVehicleTypeLabel } from '@utils';
import { useTranslation } from 'react-i18next';

const ContainerStyles = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: 12,
  minWidth: '100%',
  display: 'flex',
  backgroundColor: theme.palette.turquoise[20],
}));

const ContentStyles = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
}));

const LicensePlateStyles = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  marginBottom: 8,
}));

const FooterStyles = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  gap: '6px',
}));

const CarrierNameStyles = styled(Box)(({ theme }) => ({
  maxWidth: 180,
  ...theme.typography.tiny,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

type Props = {
  data: any;
  sx?: SxProps;
};

export const TruckListItem = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <ContainerStyles>
      <ContentStyles>
        <LicensePlateStyles>
          <TruckIcon sx={{ width: 16 }} />
          <LicensePlate
            sx={{ marginLeft: 2 }}
            size="medium"
            countryCode=""
            number={data.originalData.licensePlate}
          />
        </LicensePlateStyles>
        <FooterStyles>
          <CarrierNameStyles>{data.originalData.carrierName}</CarrierNameStyles>
          <Tooltip
            title={
              <Box sx={{ textAlign: 'left' }}>
                {data.originalData?.vehicleType ? (
                  <Typography variant={'bodyRegularSmall'}>
                    {t('vehicleType')}:{' '}
                    {getVehicleTypeLabel(data.originalData.vehicleType)}
                  </Typography>
                ) : null}
                <br />
                {data.originalData?.contract ? (
                  <Typography variant={'bodyRegularSmall'}>
                    {t('contractType')}: {data.originalData.contract}
                  </Typography>
                ) : null}
              </Box>
            }
            placement={'right'}
          >
            <InfoOutlineIcon
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
          </Tooltip>
        </FooterStyles>
      </ContentStyles>
    </ContainerStyles>
  );
};
