import { yupResolver } from '@hookform/resolvers/yup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  createDeliveryGood,
  getTypeOfGoods,
  getUnitsOfMeasurement,
  updateDeliveryGood,
} from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import { emptyStringToNull } from '@dizzbo/core/utils';
import {
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from '@dizzbo/ui';

import { useTourOrdersData } from '@order-detail/contexts';
import { DeliveryGoodType, UUIDType } from '@types';
import { DeliveryGoodDetailHeader } from './DeliveryGoodDetailHeader';
import { DeliveryGoodTypeAutoCompleteTextField } from './DeliveryGoodTypeAutoCompleteTextField';

const schema = yup.object().shape({
  typeOfGoods: yup.object().shape({
    uuid: yup.string(),
    title: yup.string(),
  }),
  measurement: yup.number().transform(emptyStringToNull).nullable(),
  unitOfMeasurement: yup.string(),
  weight: yup.number().transform(emptyStringToNull).nullable(),
  loadingMeters: yup.number().transform(emptyStringToNull).nullable(),
  volume: yup.number().transform(emptyStringToNull).nullable(),
  length: yup.number().transform(emptyStringToNull).nullable(),
  width: yup.number().transform(emptyStringToNull).nullable(),
  height: yup.number().transform(emptyStringToNull).nullable(),
  palletExchange: yup.string(),
  minimumTemperatureAllowed: yup
    .number()
    .transform(emptyStringToNull)
    .nullable(),
  maximumTemperatureAllowed: yup
    .number()
    .transform(emptyStringToNull)
    .nullable(),
  hazardousMaterials: yup.boolean(),
  loadingReference: yup.string().max(50, 'Must be max 50 digits'),
});

type Props = {
  orderUUID: UUIDType;
  deliveryUUID: UUIDType;
  deliveryGoodData?: DeliveryGoodType;
  title: string;
  setActiveTab: (value: number) => void;
};

export const DeliveryGoodDetail: React.FC<Props> = ({
  deliveryUUID,
  deliveryGoodData,
  title,
  setActiveTab,
}): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { selectedOrderUUID } = useTourOrdersData();

  const createOrUpdateDeliveryGood = (values) => {
    if (deliveryGoodData && deliveryGoodData.uuid) {
      return updateDeliveryGood(deliveryUUID, deliveryGoodData.uuid, values);
    }
    return createDeliveryGood(deliveryUUID, values);
  };

  // TODO REFACTOR THIS
  // SENDS REQUEST EVEN WHEN NOT TOUCHED
  const {
    mutate: mutateDeliveryGood,
    isError,
    error,
  } = useMutation({
    mutationKey: [
      QueryKeys.ORDERS,
      selectedOrderUUID,
      QueryKeys.DELIVERY_GOODS,
    ],
    mutationFn: (values) => createOrUpdateDeliveryGood(values),
  });

  const { data: deliveryGoodTypesData, isPending: isLoadingTypesOfGoods } =
    useQuery({
      queryKey: [QueryKeys.TYPE_OF_GOODS],
      queryFn: () => getTypeOfGoods(),
    });

  const {
    data: unitsOfMeasurementData,
    isPending: isLoadingUnitsOfMeasurementData,
  } = useQuery({
    queryKey: [QueryKeys.UNITS_OF_MEASUREMENT],
    queryFn: () => getUnitsOfMeasurement(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    values: {
      typeOfGoods: deliveryGoodData?.typeOfGoods,
      measurement: deliveryGoodData?.measurement
        ? deliveryGoodData?.measurement
        : undefined,
      unitOfMeasurement: deliveryGoodData?.unitOfMeasurement
        ? deliveryGoodData?.unitOfMeasurement
        : 'PIECE',
      weight: deliveryGoodData?.weight,
      loadingMeters: deliveryGoodData?.loadingMeters,
      volume: deliveryGoodData?.volume || undefined,
      length: deliveryGoodData?.length || undefined,
      width: deliveryGoodData?.width || undefined,
      height: deliveryGoodData?.height || undefined,
      palletExchange: deliveryGoodData?.palletExchange,
      hazardousMaterials: deliveryGoodData?.hazardousMaterials,
      minimumTemperatureAllowed:
        deliveryGoodData?.minimumTemperatureAllowed || undefined,
      maximumTemperatureAllowed:
        deliveryGoodData?.maximumTemperatureAllowed || undefined,
      loadingReference: deliveryGoodData?.loadingReference || '',
    },
    resolver: yupResolver(schema),
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData) => {
    const data = {
      ...formData,
      typeOfGoods: formData.typeOfGoods.uuid,
    };
    mutateDeliveryGood(data, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, selectedOrderUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TODOS, selectedOrderUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TASKS, selectedOrderUUID],
        });
      },
      onError: () => {},
    });
  };

  return !isLoadingTypesOfGoods && !isLoadingUnitsOfMeasurementData ? (
    <CardContent>
      <Stack spacing={6}>
        <DeliveryGoodDetailHeader
          deliveryUUID={deliveryUUID}
          deliveryGoodUUID={deliveryGoodData?.uuid}
          title={title}
          setActiveTab={setActiveTab}
        />

        <Stack spacing={6} direction="row">
          <Controller
            name="typeOfGoods"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <DeliveryGoodTypeAutoCompleteTextField
                sx={{ width: '100%' }}
                label={`${t('typeOfGoods')} (required)`}
                data={deliveryGoodTypesData}
                {...field}
              />
            )}
          />

          <Controller
            name="measurement"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                label={`${t('count')} (required)`}
                fullWidth
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
              />
            )}
          />
          <Controller
            name="unitOfMeasurement"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="unit-of-measurement-class-label">
                  {t('unitOfMeasurement')}
                </InputLabel>
                <Select
                  labelId="unit-of-measurement-class-label"
                  id="unit-of-measurement"
                  label="unit-of-measurement"
                  variant="filled"
                  {...field}
                >
                  {unitsOfMeasurementData.map((unitType) => (
                    <MenuItem key={unitType.value} value={unitType.value}>
                      {unitType.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Stack>

        <Stack spacing={6} direction="row">
          <Controller
            name="weight"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                label={`${t('weight')} (required)`}
                fullWidth
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kg</InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="loadingMeters"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                label={t('loadingMeters')}
                fullWidth
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
              />
            )}
          />
          <Controller
            name="volume"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                label={t('volume')}
                fullWidth
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m³</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>

        <Stack spacing={6} direction="row">
          <Controller
            name="length"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                label={t('length')}
                fullWidth
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="width"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                label={t('width')}
                fullWidth
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="height"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                label={t('height')}
                fullWidth
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Stack spacing={6} direction="row">
          <Controller
            name="palletExchange"
            defaultValue="DEFAULT"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 280 }}>
                <InputLabel id="pallet-exchange-class-label">
                  {t('palletExchange')}
                </InputLabel>
                <Select
                  labelId="pallet-exchange-class-label"
                  id="pallet-exchange"
                  label="pallet-exchange"
                  {...field}
                  variant="filled"
                >
                  <MenuItem value="DEFAULT">{t('customerPreset')}</MenuItem>
                  <MenuItem value="YES">{t('yes')}</MenuItem>
                  <MenuItem value="NO">{t('no')}</MenuItem>
                </Select>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="hazardousMaterials"
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} />}
                label={t('hazardousMaterialsADR')}
              />
            )}
          />
        </Stack>
        <Stack spacing={6} direction="row">
          <Controller
            name="minimumTemperatureAllowed"
            control={control}
            render={({ field }) => (
              <TextField
                label={t('minTemperature')}
                fullWidth
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ºC</InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="maximumTemperatureAllowed"
            control={control}
            render={({ field }) => (
              <TextField
                label={t('maxTemperature')}
                fullWidth
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ºC</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Controller
          name="loadingReference"
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              label={t('goodLoadingReference')}
              fullWidth
              error={hasFieldError(field)}
              helperText={fieldError(field)}
              {...field}
            />
          )}
        />
      </Stack>
      <AutoSave
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        isValid={isValid}
        control={control}
      />
    </CardContent>
  ) : null;
};
