import React, { FC, useMemo } from 'react';

import { Chip, Stack } from '@dizzbo/ui';

import { useTourOrdersData } from '@order-detail/contexts';
import { useTranslation } from 'react-i18next';
import { RequirementSelect } from './RequirementSelect';

type Props = {};

export const Requirements: FC<Props> = () => {
  const { selectedOrderData } = useTourOrdersData();
  const { t } = useTranslation();

  const { neutralDelivery, palletExchange, transportInsuranceValue } =
    useMemo(() => {
      return {
        neutralDelivery: selectedOrderData?.neutralDelivery,
        palletExchange: selectedOrderData?.palletExchange,
        transportInsuranceValue: selectedOrderData?.transportInsuranceValue,
      };
    }, [selectedOrderData]);

  return (
    <Stack spacing={3} direction="row" flexWrap="wrap" useFlexGap>
      <RequirementSelect field={'podRequired'} />
      <RequirementSelect field={'paperDocumentsRequired'} />
      <Chip
        label={`${t('neutralDelivery')}: ${t(neutralDelivery ? 'yes' : 'no')}`}
        size="large"
        variant="outlined"
      />
      <Chip
        label={`${t('palletExchange')}: ${t(palletExchange ? 'yes' : 'no')}`}
        size="large"
        variant="outlined"
      />
      <Chip
        label={`${t('transportInsurance')}: ${t(!!transportInsuranceValue ? 'yes' : 'no')}`}
        size="large"
        variant="outlined"
      />
    </Stack>
  );
};
