import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  PlusIcon,
  Typography,
  WarningIcon,
} from '@dizzbo/ui';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';

import { getDepartments, updateTour } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useAuth } from '@dizzbo/core/hooks/useAuth';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DepartmentsAutoCompleteTextField } from '@order-detail/components/OrderDetailForm/Contributors/DepartmentsAutoCompleteTextField';
import { useTourOrdersData } from '@order-detail/contexts';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TourType } from '@types';
import { AxiosError } from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AddParticipantsDialog } from './AddParticipantsDialog';
import { AssigneeListItem } from './AssigneeListItem';
import { ParticipantsListItem } from './ParticipantsListItem';

type Props = {};

export const Contributors: FC<Props> = () => {
  const { tourData, refetchOrderAndTourTodos, refetchTourDataAndOrders } =
    useTourOrdersData();

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'participantsAddDialog',
  });

  const { participants, executingDepartment, owningDepartment } = tourData;
  const { user } = useAuth();
  const { mutate: mutateTour } = useMutation<
    TourType,
    unknown,
    Partial<TourType>
  >({
    mutationKey: [QueryKeys.TOURS, tourData.uuid, QueryKeys.DEPARTMENTS],
    mutationFn: (values) =>
      updateTour({ tourUUID: tourData.uuid, tourData: values }),
  });

  const { data: departmentsData } = useQuery({
    queryKey: [QueryKeys.DEPARTMENTS],
    queryFn: () => getDepartments(),
  });
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    values: {
      executingDepartment: executingDepartment,
      owningDepartment: owningDepartment || user?.department,
    },
  });

  const onSubmit = (formData) => {
    const getToastId = addSelfClearingLoadingToast(t('updatingTour'), 6000);

    const data = {
      executingDepartment: formData.executingDepartment.uuid,
      owningDepartment: formData.owningDepartment.uuid,
    };
    mutateTour(data, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('departmentsUpdated'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
      },
    });
  };

  const handleClick = (e) => {
    popupState.open();
    e.stopPropagation();
  };

  const { t } = useTranslation();

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: 374,
      }}
    >
      <CardHeader title={t('contributors')} variant="small" />
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <Stack
              spacing={6}
              divider={<Divider orientation="horizontal" flexItem />}
            >
              <Stack spacing={6}>
                <Stack spacing={6}>
                  <Typography variant="h6">{t('assignee')}</Typography>
                  <AssigneeListItem />
                </Stack>
              </Stack>
              <Stack spacing={6}>
                <Typography variant="h6">{t('participants')}</Typography>
                {participants && participants.length > 0
                  ? participants.map((user) => (
                      <ParticipantsListItem
                        key={user.uuid}
                        username={user.displayName}
                        userUUID={user.uuid}
                      />
                    ))
                  : null}
                <Box>
                  <Button
                    variant="tertiary"
                    size="medium"
                    startIcon={<PlusIcon />}
                    onClick={handleClick}
                  >
                    {t('addParticipants')}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={6}>
              <Typography variant="h6">{t('departments')}</Typography>
              {!owningDepartment && !executingDepartment ? (
                <Alert
                  title={t('owningAndExecutiveDepartmentsAreRequired')}
                  variant="standard"
                  severity="warning"
                  icon={<WarningIcon />}
                  sx={{ marginBottom: 6 }}
                />
              ) : null}
              <Stack direction="column" spacing={6}>
                <Controller
                  name="owningDepartment"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <DepartmentsAutoCompleteTextField
                      sx={{ width: '100%' }}
                      label={t('ownerDepartment')}
                      data={departmentsData?.results ?? []}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="executingDepartment"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <DepartmentsAutoCompleteTextField
                      sx={{ width: '100%' }}
                      label={t('executiveDepartment')}
                      data={departmentsData?.results ?? []}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <AutoSave
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isValid={isValid}
          control={control}
        />
      </CardContent>
      <AddParticipantsDialog
        tourUUID={tourData.uuid}
        popupState={popupState}
        participants={participants}
      />
    </Card>
  );
};
