import { QueryKeys } from '@core/config';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { FC } from 'react';

import {
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
} from '@dizzbo/ui';

import { deleteOrderStop } from '@core/api';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { UUIDType } from '@types';
import { AxiosError } from 'axios';
import { PopupState as PopupStateType } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type Props = {
  stopUUID: UUIDType;
};

export const StopActions: FC<Props> = ({ stopUUID }) => {
  const {
    selectedOrderUUID,
    selectedOrderData,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();
  const { route: routeUUID } = selectedOrderData;
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: mutateDeleteOrderStop } = useMutation({
    mutationKey: [QueryKeys.ORDERS, selectedOrderUUID, stopUUID],
    mutationFn: () => deleteOrderStop(selectedOrderUUID, stopUUID),
  });

  const handleDelete = (popupState: PopupStateType): void => {
    const getToastId = addSelfClearingLoadingToast(t('updatingOrder'), 6000);

    mutateDeleteOrderStop(null, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('orderUpdated'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, selectedOrderUUID, QueryKeys.STOPS],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ROUTES, routeUUID],
        });
        popupState.close();
      },
    });
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <IconButton
            size="medium"
            variant="tertiary"
            {...bindTrigger(popupState)}
          >
            <MoreVerticalIcon />
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={() => handleDelete(popupState)}>
              <ListItemIcon>
                <ErrorIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
              <ListItemText>{t('deleteStop')}</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
};
