import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { updateOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import { AssignIcon, Box, LoadingButton, Stack } from '@dizzbo/ui';

import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { CustomerType, OrderType, UUIDType } from '@types';
import { AxiosError } from 'axios';
import { CustomerAutoCompleteTextField } from './CustomerAutoCompleteTextField';

const FormStyles = styled('form')(({}) => ({
  width: '100%',
}));

const schema = yup.object().shape({
  customer: yup.object().required('Vehicle is required'),
});

type FullFormData = {
  customer: CustomerType;
};

type FormData = {
  loader: UUIDType | null;
};

type Props = {};

export const CustomerForm: FC<Props> = () => {
  const { t } = useTranslation();
  const {
    selectedOrderUUID,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();

  const { mutate: mutateOrder, isPending } = useMutation<
    OrderType,
    AxiosError,
    FormData
  >({
    mutationKey: [QueryKeys.ORDERS, selectedOrderUUID],
    mutationFn: (values: FormData) => {
      return updateOrder({
        orderUUID: selectedOrderUUID,
        // @ts-ignore
        orderData: values,
      });
    },
  });

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData: FullFormData): void => {
    const getToastId = addSelfClearingLoadingToast(
      t('assigningCustomer'),
      6000
    );

    const loaderID: UUIDType | null = formData?.customer?.uuid || null;

    mutateOrder(
      { loader: loaderID },
      {
        onSuccess: () => {
          toast.update(getToastId(), {
            autoClose: 2000,
            closeButton: true,
            render: t('customerSuccessfullyAssigned'),
            type: 'success',
            isLoading: false,
          });
        },
        onError: (error: AxiosError) => {
          toast.update(getToastId(), {
            autoClose: 6000,
            closeButton: true,
            render: generateAxiosErrorMessage(error),
            type: 'error',
            isLoading: false,
          });
        },
        onSettled: () => {
          refetchOrderAndTourTodos();
          refetchTourDataAndOrders();
        },
      }
    );
  };

  return (
    <FormStyles onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={6}>
        <Controller
          name="customer"
          control={control}
          render={({ field }) => {
            const value = field.value as CustomerType | undefined;
            return (
              <CustomerAutoCompleteTextField
                {...field}
                label={t('customer')}
                value={value}
                sx={{ width: '100%' }}
              />
            );
          }}
        />
        <Box>
          <LoadingButton
            size="large"
            type="submit"
            variant="primary"
            loading={isPending}
            startIcon={<AssignIcon />}
            disabled={!isValid}
          >
            {t('assign')}
          </LoadingButton>
        </Box>
      </Stack>
    </FormStyles>
  );
};
