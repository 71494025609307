import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { createVehicle } from '@core/api';
import { addSelfClearingLoadingToast } from '@dizzbo/core';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  LoadingButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@dizzbo/ui';
import { CarrierType, RequestedVehicleTypeEnum, VehicleType } from '@types';
import { getVehicleTypeLabel } from '@utils';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  licensePlate: yup.string().required('Please enter a License Plate'),
  vehicleType: yup.string().required('Please select a Vehicle Type'),
});

type Props = {
  popupState: PopupState;
  setVehicle?: (vehicle: VehicleType) => void;
  carrier?: CarrierType;
};

export const CreateVehicleDialog: React.FC<Props> = ({
  popupState,
  setVehicle,
  carrier,
}: Props) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!popupState.isOpen) {
      reset();
    }
  }, [popupState]);

  const {
    mutateAsync: mutateVehicle,
    isPending,
    isError,
    error,
  } = useMutation<VehicleType, unknown, Partial<VehicleType>>({
    mutationFn: (values) => createVehicle(values),
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const { t } = useTranslation();

  const onSubmit = async (formData) => {
    const getToastId = addSelfClearingLoadingToast(t('creatingVehicle'), 6000);

    try {
      const data = await mutateVehicle({
        licensePlate: formData.licensePlate,
        vehicleType: formData.vehicleType,
        contract: 'spot',
        carrier: carrier?.uuid,
      });
      setVehicle(data);
      popupState.close();
      toast.update(getToastId(), {
        autoClose: 2000,
        render: t('vehicleCreated'),
        type: 'success',
        isLoading: false,
      });
    } catch {
      popupState.close();
      toast.update(getToastId(), {
        autoClose: 6000,
        render: t('vehicleCouldNotBeCreated'),
        type: 'error',
        isLoading: false,
      });
    }
  };

  return (
    <>
      <Dialog
        PaperProps={{
          elevation: 6,
          variant: 'filled-primary',
          sx: {
            minWidth: 600,
          },
        }}
        scroll="paper"
        {...bindDialog(popupState)}
      >
        <DialogTitle onClose={popupState.close}>Create Vehicle</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={6}>
            <Typography variant="h6">
              {t('createANewVehicleFor')} {carrier.name} {carrier.secondName}
            </Typography>

            <Controller
              name="licensePlate"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  label="License Plate"
                  required
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
            <Controller
              name="vehicleType"
              control={control}
              defaultValue="STANDARD_PLANE"
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="vehicle-type-label">
                    {t('vehicleType')}
                  </InputLabel>
                  <Select
                    labelId="vehicle-type-label"
                    id="vehicle-type"
                    label="vehicle-type"
                    {...field}
                  >
                    {Object.values(RequestedVehicleTypeEnum).map(
                      (vehicleType) => (
                        <MenuItem key={vehicleType} value={vehicleType}>
                          {getVehicleTypeLabel(vehicleType)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack spacing={3} direction="row">
            <Button
              type="button"
              variant="tertiary"
              onClick={() => popupState.close()}
            >
              {t('cancel')}
            </Button>
            <LoadingButton
              type="button"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
              loading={isPending}
            >
              {t('createVehicle')}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
