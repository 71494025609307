import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { FC } from 'react';
import { toast } from 'react-toastify';

import {
  ErrorIcon,
  IconButton,
  LicensePlate,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Stack,
  TrailerIcon,
  TruckIcon,
} from '@dizzbo/ui';

import { disconnectApproachStop, updateTour } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { TourType, UUIDType } from '@types';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useGetGroupedRoute } from '@core/hooks';

type Props = {};

export const VehicleDisplay: FC<Props> = () => {
  const { tourData, refetchOrderAndTourTodos, refetchTourDataAndOrders } =
    useTourOrdersData();
  const { uuid: tourUUID, route: routeUUID } = tourData;
  const { data: routeData, isPending: isRouteDataFetching } =
    useGetGroupedRoute(routeUUID, tourUUID);
  const sections =
    !isRouteDataFetching && routeData && routeData?.sections
      ? routeData.sections
      : null;

  const hasApproachingStop: boolean =
    sections?.[0]?.origin?.isApproachStop || false;
  const { mutate: mutateTour } = useMutation<
    TourType,
    unknown,
    Partial<TourType>
  >({
    mutationKey: [QueryKeys.TOURS, tourUUID],
    mutationFn: (values) =>
      updateTour({ tourUUID: tourUUID, tourData: values }),
  });

  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { mutate: mutateDisconnectApproachStop, isPending: isDisconnecting } =
    useMutation({
      mutationFn: (tourUUID: UUIDType) => disconnectApproachStop(tourUUID),
    });
  const handleDelete = (popupState) => {
    const getToastId = addSelfClearingLoadingToast(t('updatingTour'), 6000);

    mutateTour(
      { vehicle: null },
      {
        onSuccess: () => {
          if (!hasApproachingStop) {
            toast.update(getToastId(), {
              autoClose: 2000,
              closeButton: true,
              render: t('tourUpdated'),
              type: 'success',
              isLoading: false,
            });
          }
        },
        onError: (error: AxiosError) => {
          toast.update(getToastId(), {
            autoClose: 6000,
            closeButton: true,
            render: generateAxiosErrorMessage(error),
            type: 'error',
            isLoading: false,
          });
        },
        onSettled: () => {
          refetchOrderAndTourTodos();
          refetchTourDataAndOrders();
          queryClient.invalidateQueries({
            queryKey: [tourUUID, QueryKeys.ROUTES, routeUUID],
          });
          if (!hasApproachingStop) {
            popupState.close();
          }
        },
      }
    );
    if (hasApproachingStop) {
      mutateDisconnectApproachStop(tourUUID, {
        onSuccess: () => {
          toast.update(getToastId(), {
            autoClose: 2000,
            closeButton: true,
            render: t('tourUpdated'),
            type: 'success',
            isLoading: false,
          });
        },
        onError: (error: AxiosError) => {
          toast.error(generateAxiosErrorMessage(error), {
            autoClose: 6000,
            closeButton: true,
          });
        },
        onSettled: () => {
          refetchOrderAndTourTodos();
          refetchTourDataAndOrders();
          queryClient.invalidateQueries({
            queryKey: [tourUUID, QueryKeys.ROUTES, routeUUID],
          });
          popupState.close();
        },
      });
    }
  };

  return (
    <Stack direction="row" spacing={6} alignItems="center">
      <Stack width="100%" direction="row" alignItems="center">
        <Stack direction="row" mr={2}>
          <TruckIcon sx={{ width: 24, height: 24 }} />
          <TrailerIcon sx={{ width: 24, height: 24 }} />
        </Stack>
        <LicensePlate
          number={tourData?.vehicle?.licensePlate || ''}
          size="large"
        />
      </Stack>
      <PopupState variant="popover" popupId="carrier-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <IconButton
              size="medium"
              variant="tertiary"
              {...bindTrigger(popupState)}
            >
              <MoreVerticalIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => handleDelete(popupState)}>
                <ListItemIcon>
                  <ErrorIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('removeVehicle')}</ListItemText>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </Stack>
  );
};
