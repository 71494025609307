import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'yup-phone-lite';

import { createTourContact, searchContactsByEmail } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
  isValidEmail,
} from '@dizzbo/core';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  LoadingButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  WarningIcon,
} from '@dizzbo/ui';
import { useTourOrdersData } from '@order-detail/contexts';
import { UUIDType } from '@types';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

type Props = {
  popupState: PopupState;
  parentPopupState: PopupState;
};

export const AddExistingTourContactDialog: FC<Props> = ({
  popupState,
  parentPopupState,
}) => {
  const { tourData } = useTourOrdersData();
  const { uuid: tourUUID } = tourData;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [emailValue, setEmailValue] = useState<string>('');
  const [contactTypeValue, setContactTypeValue] = useState<string>('main');
  const [existingContactUUID, setExistingContactUUID] =
    useState<UUIDType | null>(null);
  const [isEmailInputValid, setIsEmailInputValid] = useState<boolean>(true);

  const { mutate: mutateTourContact, isPending: isCreatingContact } =
    useMutation({
      mutationKey: [QueryKeys.TOURS, tourUUID, QueryKeys.CONTACTS],
      mutationFn: (values: { contact: UUIDType; contact_type: string }) =>
        createTourContact(tourUUID, values),
    });

  const {
    data: contactSearchData,
    isPending: isContactSearchPending,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.USERS, emailValue],
    queryFn: () => searchContactsByEmail(emailValue),
    enabled: false,
  });

  async function handleAddExistingContact() {
    const getToastId = addSelfClearingLoadingToast(
      t('addingExistingContact'),
      6000
    );

    mutateTourContact(
      {
        contact: existingContactUUID,
        contact_type: contactTypeValue,
      },
      {
        onSuccess: () => {
          toast.update(getToastId(), {
            autoClose: 2000,
            closeButton: true,
            render: t('contactAdded'),
            type: 'success',
            isLoading: false,
          });
          parentPopupState.close();
        },
        onError: (error: AxiosError) => {
          toast.update(getToastId(), {
            autoClose: 6000,
            closeButton: true,
            render: generateAxiosErrorMessage(error),
            type: 'error',
            isLoading: false,
          });
        },
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.CONTACTS, tourUUID],
          });
          popupState.close();
        },
      }
    );
  }

  function handleEmailInputChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const { currentTarget } = event;
    const { value } = currentTarget;
    setEmailValue(value);
  }

  useEffect(() => {
    if (!popupState.isOpen) {
      setEmailValue('');
      setExistingContactUUID(null);
      setContactTypeValue('main');
    }
  }, [popupState]);

  useEffect(() => {
    if (emailValue.trim().length === 0) {
      setIsEmailInputValid(true);
    } else {
      setIsEmailInputValid(isValidEmail(emailValue));

      if (isValidEmail(emailValue)) {
        refetch();
      }
    }
  }, [emailValue]);

  useEffect(() => {
    if (contactSearchData && contactSearchData?.length !== 0) {
      const foundCustomerUUID: UUIDType | undefined = contactSearchData.find(
        (el) => el?.email === emailValue
      )?.uuid;
      if (foundCustomerUUID) {
        setExistingContactUUID(foundCustomerUUID);
        return;
      }
    }
    setExistingContactUUID(null);
  }, [contactSearchData]);

  return (
    <>
      <Dialog
        PaperProps={{
          elevation: 6,
          variant: 'filled-primary',
          sx: {
            minWidth: 600,
          },
        }}
        scroll="paper"
        {...bindDialog(popupState)}
      >
        <DialogTitle onClose={popupState.close}>
          {t('addExistingContact')}
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={6}>
            {!isContactSearchPending &&
            isEmailInputValid &&
            !existingContactUUID ? (
              <Alert
                title={t('customerWithThisEmailNotFound')}
                variant="standard"
                severity="warning"
                icon={<WarningIcon />}
                sx={{ marginBottom: 6 }}
              />
            ) : null}
            <TextField
              label={t('emailAddress')}
              type="email"
              defaultValue={''}
              value={emailValue}
              onChange={(e) => handleEmailInputChange(e)}
              error={!isEmailInputValid}
              disabled={isCreatingContact}
            />
            <FormControl fullWidth>
              <InputLabel id="contact-type-label">
                {t('contactType')}
              </InputLabel>
              <Select
                id="contact-type"
                labelId="contact-type-label"
                value={contactTypeValue}
                onChange={(event) => {
                  const selectedValue =
                    (event?.target?.value as string | undefined) || 'main';
                  setContactTypeValue(selectedValue);
                }}
              >
                <MenuItem value="transportcontract">
                  <Typography variant="bodyRegularSmall">
                    {t('transportContract')}
                  </Typography>
                </MenuItem>
                <MenuItem value="invoice">
                  <Typography variant="bodyRegularSmall">
                    {t('invoice')}
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack spacing={3} direction="row">
            <Button
              type="button"
              variant="tertiary"
              onClick={() => popupState.close()}
              disabled={isCreatingContact}
            >
              {t('cancel')}
            </Button>
            <LoadingButton
              type="button"
              disabled={
                isContactSearchPending ||
                isCreatingContact ||
                !existingContactUUID
              }
              loading={isCreatingContact}
              onClick={() => handleAddExistingContact()}
            >
              {t('addContact')}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
