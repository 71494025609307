import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';

export const isTimeSlotInputValid = (
  timeslotInput: DateRange<Dayjs | null>
): boolean => {
  return (
    timeslotInput[0] !== null &&
    timeslotInput[0] !== undefined &&
    timeslotInput[0].isValid() &&
    timeslotInput[1] !== null &&
    timeslotInput[1] !== undefined &&
    timeslotInput[1].isValid() &&
    (timeslotInput[1].isSame(timeslotInput[0]) ||
      timeslotInput[1].isAfter(timeslotInput[0]))
  );
};

export function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return emailRegex.test(email.trim());
}
