import Fade from '@mui/material/Fade';
import { useTheme } from '@mui/material/styles';
import React, { FC, PropsWithChildren, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { View } from '@dizzbo/core/views';
import { Box, LoadingScreen, Stack, StickyBox, Typography } from '@dizzbo/ui';

import { OrderDetailForm } from '../components/OrderDetailForm';
import { OrderDetailHeader } from '../components/OrderDetailHeader';
import { OrderDetailNavigation } from '../components/OrderDetailNavigation';
import { Settings } from '../components/Settings';
import {
  OrderDetailNavigationProvider,
  useOrderDetailNavigation,
  useTourOrdersData,
} from '../contexts';

import { TourDetailForm } from '@order-detail/components';
import { TourOrdersDataProvider } from '@order-detail/contexts';
import { UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { TodosSection } from '../components/TodosSection';

interface TabPanelProps extends PropsWithChildren {
  index: number;
}

type Props = {};

export const OrderDetailView: FC<Props> = () => {
  const { uuid: urlOrderUUID } = useParams();

  return (
    <TourOrdersDataProvider
      contextType={'existingOrder'}
      initialDisplayedOrderUUID={urlOrderUUID}
    >
      <OrderDetailViewContent />
    </TourOrdersDataProvider>
  );
};

const OrderDetailViewContent: FC<Props> = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const containerRef = useRef();

  const {
    selectedOrderData,
    tourData,
    tourOrders,
    tourOrdersUUIDS,
    isOrderAndTourDataLoadedOnce,
    isErrorFetchingOrderData,
  } = useTourOrdersData();

  if (isErrorFetchingOrderData) {
    return (
      <Box sx={{ display: 'flex', padding: '6px 12px', textAlign: 'center' }}>
        <Typography variant={'bodyBold'}>Order Not Found!</Typography>
      </Box>
    );
  }

  if (!isOrderAndTourDataLoadedOnce) {
    return <LoadingScreen sx={{ position: 'absolute' }} />;
  }

  return (
    <View
      title={t('dizzboOrderDetailWithReference', {
        reference: selectedOrderData?.reference || '',
      })}
      sx={{ height: '100%', overflowY: 'auto', paddingBottom: 6 }}
      ref={containerRef}
    >
      <OrderDetailNavigationProvider>
        <StickyBox
          container={containerRef}
          zIndex={theme.zIndex.appBar + 3}
          stickTo="top"
          offset={0}
          stuckStyles={{}}
          unstuckStyles={{}}
        >
          <OrderDetailHeader />
        </StickyBox>

        <TodosSection />

        <StickyBox
          container={containerRef}
          zIndex={theme.zIndex.appBar + 4}
          stickTo="top"
          offset={80}
          stuckStyles={{}}
          unstuckStyles={{}}
        >
          <OrderDetailNavigation
            tourReference={tourData.reference}
            ordersData={tourOrders}
            rearrangedOrderUUIDS={tourOrdersUUIDS}
          />
        </StickyBox>

        <TabPanel index={0}>
          <TourDetailForm />
        </TabPanel>

        {tourOrdersUUIDS && tourOrdersUUIDS.length > 0
          ? tourOrdersUUIDS.map((orderUUID: UUIDType, index: number) => {
              return (
                <TabPanel index={index + 1} key={`order_tab_${orderUUID}`}>
                  <OrderDetailForm />
                </TabPanel>
              );
            })
          : null}

        <Stack
          direction="row"
          spacing={6}
          justifyContent="space-between"
          sx={{
            marginTop: theme.spacing(6),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
          }}
        >
          <Settings />
        </Stack>
      </OrderDetailNavigationProvider>
    </View>
  );
};

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, index, ...other } = props;
  const { activeTabIndex } = useOrderDetailNavigation();

  return (
    <Fade in={activeTabIndex === index}>
      <div
        role="tabpanel"
        hidden={activeTabIndex !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {activeTabIndex === index && children}
      </div>
    </Fade>
  );
};
