import { SxProps } from '@mui/material/styles';
import React, { FC, useState } from 'react';

import { Box, Card, CardActions, Paper, Typography } from '@dizzbo/ui';

import { RoutingMap } from '@core/components/RoutingMap';
import {
  useGetMultipleOrdersDeliveries,
  useGetGroupedRoute,
} from '@core/hooks';

import { useTourOrdersData } from '@order-detail/contexts';
import { PricingBar } from '@planner/components';
import { useTranslation } from 'react-i18next';
import { TourStopsList } from './TourStopsList';

type Props = {};

export const TourPlan: FC<Props> = () => {
  const { tourData, tourOrdersUUIDS } = useTourOrdersData();

  const { route: routeUUID, uuid: tourUUID } = tourData;
  const { data: routeData, isPending: isRouteDataFetching } =
    useGetGroupedRoute(routeUUID, tourUUID);

  const { t } = useTranslation();

  const { data: deliveriesData, isFetching: isDeliveryDataFetching } =
    useGetMultipleOrdersDeliveries(tourOrdersUUIDS);

  const [totalPrice, setTotalPrice] = useState<number>(0);

  const cardContentStyles: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  };

  const routingMapContainerStyles: SxProps = {
    width: '100%',
    height: '600px',
  };

  const stopListContainerStyles: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    height: '600px',
  };

  return !isDeliveryDataFetching && !isRouteDataFetching ? (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Card sx={{ ...cardContentStyles }}>
        <Box sx={{ ...stopListContainerStyles }}>
          <Box py={4} px={6} sx={{ height: '50px' }}>
            <Typography variant="h5" color="primary">
              {t('route')}
            </Typography>
          </Box>
          <TourStopsList
            sections={routeData?.sections || []}
            deliveriesData={deliveriesData}
          />
        </Box>
        <Box sx={{ ...routingMapContainerStyles }}>
          <RoutingMap route={routeData} />
        </Box>
      </Card>
      <Paper elevation={3}>
        <CardActions sx={{ padding: 0, overflow: 'hidden' }}>
          <PricingBar
            routeUUID={routeUUID}
            totalPrice={totalPrice}
            setTotalPrice={setTotalPrice}
          />
        </CardActions>
      </Paper>
    </Card>
  ) : null;
};
