import { styled, useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { createOrderDelivery } from '@core/api';
import { QueryKeys } from '@core/config';
import { useGetOrderStops } from '@core/hooks';
import {
  Box,
  Divider,
  IconButton,
  PlusIcon,
  Stack,
  Tab,
  Tabs,
  Tooltip,
} from '@dizzbo/ui';

import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { DeliveryListItemType } from '@types';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

function a11yProps(index: number) {
  return {
    id: `loading-plan-tab-${index}`,
    'aria-controls': `loading-plan-tabpanel-${index}`,
  };
}

const ActionStyles = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  paddingRight: 24,
}));

const LoadingPlanTabStyles = styled(Tab)(({ theme }) => ({
  marginRight: 24,
  overflow: 'visible',
  '&::after': {
    content: "' '",
    position: 'absolute',
    borderRight: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
    top: 12,
    bottom: 12,
    right: -12,
  },
}));

type Props = {
  activeTab: number;
  setActiveTab: (value: number) => void;
  deliveriesData: DeliveryListItemType[];
};

export const LoadingPlanTabs: React.FC<Props> = ({
  activeTab,
  setActiveTab,
  deliveriesData = [],
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    selectedOrderUUID: orderUUID,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const { data: stopsData } = useGetOrderStops(orderUUID);

  const queryClient = useQueryClient();

  const { mutate: mutateDeliveries } = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DELIVERIES],
    mutationFn: () =>
      createOrderDelivery(orderUUID, {
        loadingStop: stopsData[0]?.uuid,
        unloadingStop: stopsData[1]?.uuid,
      }),
  });

  const handleAddDelivery = () => {
    const getToastId = addSelfClearingLoadingToast(t('addingDelivery'), 6000);

    mutateDeliveries(null, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('deliveryAdded'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DELIVERIES],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.STOPS],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.DELIVERIES, orderUUID],
        });
      },
    });
  };

  return (
    <Stack
      sx={{
        transition: theme.transitions.create('border '),
        paddingLeft: 3,
        position: 'relative',
        borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
      }}
      direction="row"
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Loading plan tabs"
        variant="scrollable"
        visibleScrollbar={true}
      >
        <LoadingPlanTabStyles label={t('loadingPlan')} {...a11yProps(0)} />
        {deliveriesData.map((delivery, index) => (
          <Tab
            key={delivery.uuid}
            label={`${t('delivery')} ${delivery.index}`}
            {...a11yProps(index + 1)}
          />
        ))}
      </Tabs>
      <ActionStyles>
        <Divider orientation="vertical" sx={{ height: 32, marginRight: 3 }} />
        <Tooltip title={t('addDelivery')} placement="top">
          <IconButton variant="tertiary" onClick={handleAddDelivery}>
            <PlusIcon />
          </IconButton>
        </Tooltip>
      </ActionStyles>
    </Stack>
  );
};
