import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { updateOrderDelivery } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Box,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  MultiLineTextfield,
  Select,
  Stack,
  TextField,
} from '@dizzbo/ui';

import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { DeliveryType, StopType } from '@types';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DeliveryDetailHeader } from './DeliveryDetailHeader';
import { DeliveryGoodsSection } from './DeliveryGoodsSection';
import { DeliveryLoadingPointItem } from './DeliveryLoadingPointItem';

const CardContentStyles = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: 0,
}));

const LoadingPointListStyles = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const schema = yup.object().shape({
  packagingRequirements: yup.string(),
  loadingReference: yup.string().max(50, 'Must be max 50 digits'),
  unloadingReference: yup.string().max(50, 'Must be max 50 digits'),
  loadingNotes: yup.string(),
  unloadingNotes: yup.string(),
});

type Props = {
  deliveryData: DeliveryType;
  setActiveTab: (value: number) => void;
};

export const DeliveryDetail: FC<Props> = ({ deliveryData, setActiveTab }) => {
  const {
    selectedOrderUUID,
    refetchOrderAndTourTodos,
    refetchTourDataAndOrders,
  } = useTourOrdersData();
  const {
    uuid: deliveryUUID,
    packagingRequirements,
    loadingReference,
    unloadingReference,
    loadingNotes,
    unloadingNotes,
  } = deliveryData;

  const { loadingStop, unloadingStop } = useMemo(() => {
    const loadingStop: StopType | undefined =
      deliveryData?.loadingStop || undefined;
    const unloadingStop: StopType | undefined =
      deliveryData?.unloadingStop || undefined;
    return { loadingStop, unloadingStop };
  }, [deliveryData]);

  const queryClient = useQueryClient();

  const {
    mutate: mutateDelivery,
    isError,
    error,
  } = useMutation({
    mutationKey: [
      QueryKeys.ORDERS,
      selectedOrderUUID,
      QueryKeys.DELIVERIES,
      deliveryData.uuid,
    ],
    mutationFn: (values: Partial<DeliveryType>) =>
      updateOrderDelivery(selectedOrderUUID, deliveryData.uuid, values),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    values: {
      packagingRequirements: packagingRequirements,
      loadingReference: loadingReference,
      unloadingReference: unloadingReference,
      loadingNotes: loadingNotes,
      unloadingNotes: unloadingNotes,
    },
    resolver: yupResolver(schema),
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData: Partial<DeliveryType>) => {
    const getToastId = addSelfClearingLoadingToast(t('updatingDelivery'), 6000);

    mutateDelivery(formData, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('deliveryUpdated'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, selectedOrderUUID, QueryKeys.DELIVERIES],
        });
      },
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <CardContentStyles>
        <Stack spacing={3} direction="column" width="100%">
          <Box pl={6} pr={6}>
            <DeliveryDetailHeader
              deliveryUUID={deliveryUUID}
              deliveryIndex={deliveryData.index}
              setActiveTab={setActiveTab}
            />
          </Box>
          <LoadingPointListStyles>
            <DeliveryLoadingPointItem
              index={0}
              action="loading"
              stop={loadingStop}
              deliveryUUID={deliveryUUID}
            />
            <DeliveryLoadingPointItem
              index={1}
              lastItem
              action="unloading"
              stop={unloadingStop}
              deliveryUUID={deliveryUUID}
            />
          </LoadingPointListStyles>
        </Stack>
      </CardContentStyles>
      <DeliveryGoodsSection
        deliveryUUID={deliveryData.uuid}
        deliveryIndex={deliveryData.index}
      />
      <CardContent sx={{ borderTop: '0 !important' }}>
        <Stack spacing={6}>
          <Stack spacing={6} direction="row">
            <Controller
              name="packagingRequirements"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="unit-of-measurement-class-label">
                    {t('packagingRequirements')}
                  </InputLabel>
                  <Select
                    labelId="unit-of-measurement-class-label"
                    id="unit-of-measurement"
                    label="unit-of-measurement"
                    {...field}
                  >
                    <MenuItem value="">No Requirements</MenuItem>
                    <MenuItem value="PACKAGING_NEUTRAL">
                      {t('neutralPackaging')}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="loadingReference"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label={t('loadingReference')}
                  fullWidth
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
            <Controller
              name="unloadingReference"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label={t('unloadingReference')}
                  fullWidth
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
          </Stack>
          <Stack spacing={6} direction="row">
            <Controller
              name="loadingNotes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MultiLineTextfield
                  label={t('loadingNotes')}
                  characterLimit={600}
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
            <Controller
              name="unloadingNotes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MultiLineTextfield
                  label={t('unloadingNotes')}
                  characterLimit={600}
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
          </Stack>
        </Stack>
        <AutoSave
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isValid={isValid}
          control={control}
        />
      </CardContent>
    </>
  );
};
