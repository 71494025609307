import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { FC, useMemo } from 'react';

import {
  addSelfClearingLoadingToast,
  ConversationDialog,
  getStatusColor,
} from '@dizzbo/core';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CommunicationIcon,
  HistoryIcon,
  PlusIcon,
  TruckFTLIcon,
  TruckLTLIcon,
  Typography,
} from '@dizzbo/ui';
import { PopupState as PopupStateType } from 'material-ui-popup-state/hooks';

import { copyOrder, createOrderComment, getOrderComments } from '@core/api';
import { QueryKeys } from '@core/config';

import { Stack } from '@mui/material';
import { useTourOrdersData } from '@order-detail/contexts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { HistoryDialog } from '../../History';

type Props = {};

export const OrderSummary: FC<Props> = () => {
  const { selectedOrderUUID, selectedOrderData, switchToCopiedOrder } =
    useTourOrdersData();
  const { status } = selectedOrderData;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderConversationDialog',
  });

  const historyDialogPopupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderHistoryDialog',
  });

  const { loadType, reference } = useMemo(() => {
    return {
      loadType: selectedOrderData?.loadType || 'FTL',
      reference: selectedOrderData?.reference || '',
    };
  }, [selectedOrderData]);

  const fetchComments = (params) => {
    return getOrderComments(selectedOrderUUID, params);
  };

  const createComment = (data) => {
    return createOrderComment(selectedOrderUUID, data);
  };

  const { mutateAsync: mutateCopyOrder } = useMutation({
    mutationFn: () => copyOrder(selectedOrderUUID),
  });

  async function handleCopy(popupState: PopupStateType): Promise<void> {
    const getCopyingToastId = addSelfClearingLoadingToast(
      t('copyingOrder'),
      6000
    );

    try {
      const data = await mutateCopyOrder();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, data.uuid],
      });

      toast.update(getCopyingToastId(), {
        render: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {t('orderSuccessfullyCopied.')}
            <Button
              component={RouterLink}
              to={`/orders/${data.uuid}`}
              variant="secondary"
              onClick={() => {
                toast.dismiss(getCopyingToastId());
                switchToCopiedOrder(data.uuid);
              }}
              size="medium"
            >
              {t('openCopy')}
            </Button>
          </Box>
        ),
        autoClose: 6000,
        type: 'success',
        isLoading: false,
      });
    } catch {
      toast.update(getCopyingToastId(), {
        render: t('theOrderCouldNotBeCopied'),
        autoClose: 6000,
        closeButton: true,
        type: 'error',
        isLoading: false,
      });
    }

    popupState.close();
  }

  return (
    <>
      <Card
        variant="filled-secondary"
        elevation={1}
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <CardHeader
          avatar={
            loadType === 'LTL' ? (
              <Chip
                label="LTL"
                size="medium"
                variant="green-100"
                icon={<TruckLTLIcon />}
              />
            ) : (
              <Chip
                label="FTL"
                size="medium"
                variant="oxford-40"
                icon={<TruckFTLIcon />}
              />
            )
          }
          title={
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h4" color="primary">
                {t('dizzboOrderWithReference', { reference })}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: getStatusColor(status),
                  textTransform: 'capitalize',
                }}
              >
                &nbsp;-&nbsp; {status}
              </Typography>
            </Box>
          }
          action={
            <Stack direction="row" spacing={2}>
              <Button
                size="medium"
                variant="primary"
                startIcon={<PlusIcon />}
                onClick={() => handleCopy(popupState)}
              >
                {t('copyAndCreateNewOrder')}
              </Button>
              <Button
                aria-label="Communication"
                size="medium"
                variant="primary"
                startIcon={<CommunicationIcon />}
                onClick={() => popupState.open()}
              >
                {t('comments')}
              </Button>
              <Button
                aria-label="History"
                size="medium"
                variant="primary"
                startIcon={<HistoryIcon />}
                onClick={() => historyDialogPopupState.open()}
              >
                {t('history')}
              </Button>
            </Stack>
          }
          variant="small"
          sx={{ borderBottom: 0 }}
        />
      </Card>
      <ConversationDialog
        popupState={popupState}
        query={{
          queryFn: fetchComments,
          queryKey: [QueryKeys.ORDERS, selectedOrderUUID, 'comments'],
        }}
        mutation={{
          mutationFn: createComment,
          mutationKey: [QueryKeys.ORDERS, selectedOrderUUID, 'comments'],
        }}
      />
      <HistoryDialog popupState={historyDialogPopupState} />
    </>
  );
};
