import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  bindDialog,
  PopupState,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  createTransportContract,
  getOrderTasks,
  getTour,
  getTransportContractPreview,
} from '@core/api';
import { QueryKeys } from '@core/config';
import { base64toBlob } from '@dizzbo/core/utils';
import {
  Alert,
  ArrowRightIcon,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Skeleton,
  SquareIcon,
  Stack,
  Typography,
  ViewIcon,
  WarningIcon,
} from '@dizzbo/ui';

import { TransportContractPreviewDialog } from '@order-detail/components/TourDetailForm/TransportContract/TransportContractPreviewDialog';
import { OrderSubtodoType, OrderType, UUIDType } from '@types';
import { generateOrderAllTasksArray } from '@utils';
import TransportContractBackgroundImage from '../../../../assets/images/transport-contract-half-thumbnail.png';

const SubtitleStyles = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const PreviewCardStyles = styled(Card)(() => ({
  width: '40%',
  minHeight: 200,
  backgroundImage: `url(${TransportContractBackgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  backgroundPositionY: 'bottom',
}));

const PreviewCardContentStyles = styled(CardContent)(() => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'flex-end',
}));

const ContentCardStyles = styled(Card)(() => ({
  width: '60%',
}));

type Props = {
  orderData: OrderType;
  tourUUID: UUIDType;
  popupState: PopupState;
};

export const TransportContractDialog: React.FC<Props> = ({
  orderData,
  tourUUID,
  popupState,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { uuid: orderUUID, reference, status } = orderData;

  const podPreviewDialogPopupState = usePopupState({
    variant: 'dialog',
    popupId: 'podReviewDialog',
  });

  const { data: orderTasks, isPending: isOrderTasksPending } = useQuery({
    queryKey: [QueryKeys.TASKS, orderUUID],
    queryFn: () => getOrderTasks(orderUUID),
    enabled: popupState.isOpen,
  });

  const allOrderTasks: Array<OrderSubtodoType> = generateOrderAllTasksArray(
    orderTasks,
    isOrderTasksPending
  );

  const { data: tourData, isPending: isTourDataPending } = useQuery({
    queryKey: [QueryKeys.TOURS, tourUUID],
    queryFn: () => getTour(orderData.tour.uuid),
    enabled: !!tourUUID,
  });

  const {
    data: transportContractData,
    isPending: isPendingTransportContratPreview,
  } = useQuery({
    queryKey: [QueryKeys.TOURS, tourUUID, QueryKeys.TRANSPORT_CONTRACTS],
    queryFn: () => getTransportContractPreview(tourUUID),
    refetchOnWindowFocus: false,
    enabled: !!tourUUID && podPreviewDialogPopupState.isOpen,
  });

  let url = undefined;

  if (transportContractData && transportContractData.file) {
    const blob = base64toBlob(transportContractData.file, 'application/pdf');
    url = URL.createObjectURL(blob);
  }

  const { mutate: mutateCreateTransportContract, isPending }: any = useMutation(
    {
      mutationKey: [QueryKeys.ORDERS, orderUUID],
      mutationFn: () => createTransportContract(tourUUID),
      onSuccess() {
        toast.success(t('transportContractCreated'));
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TOURS, tourUUID],
        });
        popupState.close();
      },
      onError() {
        toast.error(t('transportContractCouldNotBeCreated'));
        popupState.close();
      },
    }
  );

  const handleClick = () => {
    mutateCreateTransportContract();
    popupState.close();
  };

  if (isOrderTasksPending || isTourDataPending) return null;

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 700,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        <Typography variant="h3" color="primary">
          {reference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {status}
        </Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <SubtitleStyles>
          <Typography variant="h5">{t('sendTransportContract')}</Typography>
        </SubtitleStyles>
        <Stack direction="row">
          <PreviewCardStyles variant="filled-secondary" elevation={0} square>
            <PreviewCardContentStyles>
              <>
                <LoadingButton
                  loading={
                    isPendingTransportContratPreview &&
                    podPreviewDialogPopupState.isOpen
                  }
                  startIcon={<ViewIcon />}
                  size="medium"
                  variant="primary"
                  onClick={() => podPreviewDialogPopupState.open()}
                >
                  {t('view')}
                </LoadingButton>
                {url && (
                  <TransportContractPreviewDialog
                    tourReference={tourData?.reference}
                    popupState={podPreviewDialogPopupState}
                    file={url}
                  />
                )}
              </>
            </PreviewCardContentStyles>
          </PreviewCardStyles>
          {allOrderTasks?.length > 0 ? (
            <ContentCardStyles variant="filled-disabled" elevation={0} square>
              <CardContent>
                {isOrderTasksPending ? (
                  <Skeleton width="100%" height={56} />
                ) : (
                  <Alert
                    title={t('transportContractCannotBeSent')}
                    message={allOrderTasks.map((subTodo) => (
                      <Box key={subTodo.uuid + subTodo.field}>
                        <Typography component={'span'}>
                          {subTodo.fieldLabel && subTodo.fieldLabel.length > 0
                            ? subTodo.fieldLabel
                            : 'Todo'}
                          :&nbsp;
                          {subTodo.message}
                        </Typography>
                        <br />
                      </Box>
                    ))}
                    variant="standard"
                    severity="warning"
                    icon={<WarningIcon />}
                  />
                )}
              </CardContent>
            </ContentCardStyles>
          ) : (
            <ContentCardStyles variant="filled-turquoise" elevation={0} square>
              <CardContent>
                <Typography variant="buttonRegularSmall">
                  {t('carrierNumber')}: {orderData.carrier?.customerNumber}
                </Typography>
                <br />
                <Typography variant="labelSmall">
                  {t('carrier')}: {orderData.carrier?.name}{' '}
                  {orderData.carrier?.secondName}
                </Typography>
              </CardContent>
            </ContentCardStyles>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button
            variant="tertiary"
            onClick={() => popupState.close()}
            disabled={isPending}
          >
            {t('cancel')}
          </Button>
          {allOrderTasks?.length > 0 ? (
            <Button
              startIcon={<ArrowRightIcon />}
              size="medium"
              component={RouterLink}
              to={`/orders/${orderUUID}`}
            >
              {t('goToOrderDetails')}
            </Button>
          ) : (
            <LoadingButton
              type="submit"
              variant="primary"
              onClick={handleClick}
              loading={isPending}
              disabled={isPending}
              startIcon={<SquareIcon />}
            >
              {t('sendTransportContract')}
            </LoadingButton>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
