import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';
import React, { FC, useEffect, useRef } from 'react';

import { SchedulerResourceModel } from '@bryntum/schedulerpro';
import { Box, LoadingIndicator } from '@dizzbo/ui';
import { OrderType, TourListItemType, TourType, VehicleType } from '@types';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useDrop } from 'react-dnd';
import { useSchedulerSettings } from '../../context';
import { VehicleAsssignmentDialog } from '../Dialogs';
import { SchedulerStylesWrapper } from '../SchedulerStylesWrapper';
import { schedulerProConfig } from './SchedulerConfig';

type Props = {};

type ResourceModel = SchedulerResourceModel & { originalData: unknown };

const ROWS_REMAINING_TO_REFETCH: number = 10;

export const Scheduler: FC<Props> = () => {
  const {
    calendarParams,
    visibleDate,
    displayedVehicles,
    isFetching,
    selectedTour,
    setSelectedTour,
    setTargetVehicle,
    displayedTours,
    isToursDataFetching,
    setSelectedOrder,
    fetchVehiclesNextPage,
    isVehiclesDataFetching,
    isVehiclesNextPagePresent,
  } = useSchedulerSettings();
  const { sliderPosition } = calendarParams;

  const vehicleAssignmentPopupState = usePopupState({
    variant: 'dialog',
    popupId: 'vehicle_assignment_popupState',
  });

  const schedulerProRef = useRef<BryntumSchedulerPro | null>(null);

  /*
   * Set the schedulerProRef and attach event listeners
   * @param ref BryntumSchedulerPro
   * @returns
   */
  function setSchedulerProRef(ref: BryntumSchedulerPro) {
    if (!ref) return;

    schedulerProRef.current = ref;

    if (!ref?.instance) return;

    ref.instance.eventStore.on({
      loadDateRange(e) {
        if (!e.changed) return;
      },
    });
  }

  useEffect(() => {
    if (!schedulerProRef.current?.instance || !visibleDate) {
      return;
    }

    schedulerProRef.current.instance.visibleDate = visibleDate;
    schedulerProRef.current.instance.scrollToDate(visibleDate, {
      block: 'center',
      animate: true,
    });
  }, [visibleDate]);

  useEffect(() => {
    if (!schedulerProRef.current?.instance || !visibleDate) {
      return;
    }

    schedulerProRef.current.instance.scrollToDate(visibleDate, {
      block: 'center',
      animate: false,
    });
  }, [calendarParams]);

  function handleEventDrop({ context }): void {
    const targetVehicleData: VehicleType = context?.newResource?.data;
    if (!selectedTour || !targetVehicleData) {
      return;
    }
    setTargetVehicle(targetVehicleData);
    vehicleAssignmentPopupState.open();
  }

  function handleBeforeEventDrag({ eventRecords }): boolean {
    const currentlySelectedTourData: TourType = eventRecords[0]?.data;
    if (
      !!currentlySelectedTourData &&
      !isToursDataFetching &&
      (currentlySelectedTourData?.status === 'DRAFT' ||
        currentlySelectedTourData?.status === 'NEW')
    ) {
      setSelectedTour(currentlySelectedTourData);
      return true;
    }
    return false;
  }

  function handleScroll({ source, scrollTop }): void {
    if (isVehiclesDataFetching || !isVehiclesNextPagePresent) {
      return;
    }
    const { rowHeight } = source;

    const rowCount: number = displayedVehicles.length;
    const maxScroll: number = rowHeight * rowCount;
    if (scrollTop >= maxScroll - rowHeight * ROWS_REMAINING_TO_REFETCH) {
      fetchVehiclesNextPage();
    }
  }

  const findAncestor = (el, cls) => {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  };

  const [, drop] = useDrop<OrderType>(() => ({
    accept: 'ORDER',
    drop: (item, monitor) => {
      if (!item) {
        console.error('Dropped item is null or undefined');
        return;
      }
      const coords = monitor.getClientOffset();
      if (!coords || !schedulerProRef.current) return;

      const targetElement = document.elementFromPoint(coords.x, coords.y);
      if (!targetElement) return;

      const resourceRowElement = findAncestor(targetElement, 'b-grid-row');
      if (!resourceRowElement) return;

      const resource = schedulerProRef.current.instance.resolveResourceRecord(
        resourceRowElement
      ) as ResourceModel;

      const vehicleData = resource?.originalData as VehicleType;
      const tourData: TourListItemType = item?.tour;

      setSelectedTour(tourData);
      setTargetVehicle(vehicleData);
      setSelectedOrder(item);
      vehicleAssignmentPopupState.open();
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return !isFetching ? (
    <SchedulerStylesWrapper ref={drop}>
      <BryntumSchedulerPro
        ref={setSchedulerProRef}
        events={
          !isToursDataFetching && !isVehiclesDataFetching ? displayedTours : []
        }
        // @ts-ignore
        resources={displayedVehicles}
        onBeforeEventDrag={handleBeforeEventDrag}
        disabled={isFetching}
        onEventDrop={handleEventDrop}
        tickSize={sliderPosition}
        onScroll={handleScroll}
        {...schedulerProConfig}
      />
      {isToursDataFetching || isVehiclesDataFetching ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: '999',
            justifySelf: 'center',
            alignSelf: 'center',
          }}
        >
          <LoadingIndicator />
        </Box>
      ) : null}
      <VehicleAsssignmentDialog popupState={vehicleAssignmentPopupState} />
    </SchedulerStylesWrapper>
  ) : null;
};
