import {
  Box,
  colors,
  DateRangeSelector,
  DateRangeSelectorFilterType,
  Divider,
  LocationIcon,
  ResetIcon,
  SearchInput,
  Typography,
} from '@dizzbo/ui';
import { styled, SxProps } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { CustomerAutoCompleteTextField } from '@order-detail/components/OrderDetailForm/CustomerAssignment/CustomerAutoCompleteTextField';
import { CustomerType } from '@types';
import { Dayjs } from 'dayjs';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSchedulerSettings } from '../../context';

const BoxOrderFilters = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '15px',
  height: '80px',
  padding: '8px 24px',
  overflowX: 'auto',
  overflowY: 'hidden',
  maxWidth: '100%',
}));

const BoxResetIcon = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

const FilterContainerStyles: SxProps = {
  minWidth: '210px',
  maxWidth: '210px',
  height: '40px',
};

const FilterButtonStyles: SxProps = {
  minWidth: '160px',
  maxWidth: '160px',
  height: '40px',
};

type Props = {};

export const OrderFilters: FC<Props> = () => {
  const { t } = useTranslation();
  const {
    orderFilters,
    isFetching,
    updateOrderFilters,
    clearOrderFilters,
    paginationState,
    updateOrdersPagination,
    refetchOrders,
    DISPLAYED_DATE_RANGE_MAX,
  } = useSchedulerSettings();

  const {
    search,
    loadingPoint,
    unloadingPoint,
    unloadingDateRange,
    loadingDateSelector,
    unloadingDateSelector,
    customer,
  } = orderFilters;

  function handlePaginationReset(): void {
    const { pageIndex, pageSize } = paginationState;
    if (pageIndex !== 0) {
      updateOrdersPagination({
        pageIndex: 0,
        pageSize,
      });
    }
    refetchOrders();
  }

  return !isFetching ? (
    <BoxOrderFilters>
      <SearchInput
        label={t('orderSearch')}
        searchValue={search}
        onSearchChange={(event) => {
          updateOrderFilters({ search: event?.target?.value || '' });
          handlePaginationReset();
        }}
        onSearchClear={() => {
          updateOrderFilters({ search: '' });
          handlePaginationReset();
        }}
        sx={{ ...FilterContainerStyles }}
        icon={<LocationIcon />}
      />
      <Divider variant={'fullWidth'} orientation={'vertical'} />
      <CustomerAutoCompleteTextField
        label={t('customer')}
        onChange={(customer: CustomerType) => {
          updateOrderFilters({ customer });
          handlePaginationReset();
        }}
        value={customer}
        sx={{ ...FilterContainerStyles }}
      />
      <Divider variant={'fullWidth'} orientation={'vertical'} />
      <SearchInput
        label={t('loadingLocation')}
        searchValue={loadingPoint}
        onSearchChange={(event) => {
          updateOrderFilters({ loadingPoint: event?.target?.value || '' });
          handlePaginationReset();
        }}
        onSearchClear={() => {
          updateOrderFilters({ loadingPoint: '' });
          handlePaginationReset();
        }}
        sx={{ ...FilterContainerStyles }}
        icon={<LocationIcon />}
      />
      <SearchInput
        label={t('unloadingLocation')}
        searchValue={unloadingPoint}
        onSearchChange={(event) => {
          updateOrderFilters({ unloadingPoint: event?.target?.value || '' });
          handlePaginationReset();
        }}
        onSearchClear={() => {
          updateOrderFilters({ unloadingPoint: '' });
          handlePaginationReset();
        }}
        sx={{ ...FilterContainerStyles }}
        icon={<LocationIcon />}
      />
      <Divider variant={'fullWidth'} orientation={'vertical'} />
      <DateRangeSelector
        label={t('loadingDate')}
        dateRangeValue={orderFilters.loadingDateRange}
        filterValue={loadingDateSelector}
        onChange={(
          dateRange: DateRange<Dayjs>,
          dateSelector: DateRangeSelectorFilterType
        ) => {
          updateOrderFilters({
            loadingDateRange: dateRange,
            loadingDateSelector: dateSelector,
          });
          handlePaginationReset();
        }}
        filters={['today', 'this_week', 'this_month', 'this_year']}
        buttonStyles={{ borderRadius: '4px', ...FilterButtonStyles }}
        maxDateRange={DISPLAYED_DATE_RANGE_MAX}
      />
      <DateRangeSelector
        label={t('unloadingDate')}
        dateRangeValue={unloadingDateRange}
        filterValue={unloadingDateSelector}
        onChange={(
          dateRange: DateRange<Dayjs>,
          dateSelector: DateRangeSelectorFilterType
        ) => {
          updateOrderFilters({
            unloadingDateRange: dateRange,
            unloadingDateSelector: dateSelector,
          });
          handlePaginationReset();
        }}
        filters={['today', 'this_week', 'this_month', 'this_year']}
        buttonStyles={{ borderRadius: '4px', ...FilterButtonStyles }}
        maxDateRange={DISPLAYED_DATE_RANGE_MAX}
      />
      <Divider variant={'fullWidth'} orientation={'vertical'} />
      <BoxResetIcon
        onClick={() => {
          clearOrderFilters();
          handlePaginationReset();
        }}
      >
        <ResetIcon sx={{ color: `${colors.turquoise[150]}` }} />
        <Typography
          variant={'textLinkRegularSmall'}
          color={colors.turquoise[150]}
        >
          {t('clear')}
        </Typography>
      </BoxResetIcon>
    </BoxOrderFilters>
  ) : null;
};
