import { connectApproachStop, disconnectApproachStop } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { Box, Button, ChainIcon, ListItem, Typography } from '@dizzbo/ui';
import { SxProps } from '@mui/material';
import { useTourOrdersData } from '@order-detail/contexts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RouteSectionType, UUIDType } from '@types';
import { AxiosError } from 'axios';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { EditApproachStopButton } from './EditApproachStopButton';
import { Footnote } from './Footnote';
import { TourSectionStopItem } from './TourSectionStopItem';
import { TourWaypointAddItem } from './TourWaypointAddItem';
import { TourWaypointItem } from './TourWaypointItem';
import { WaypointActivationButton } from './WaypointActivationButton';

const listItemStyles: SxProps = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  height: 'auto',
  cursor: 'auto',
  padding: '6px 0',
  alignItems: 'start',
};

type TourSectionItemProps = {
  section: RouteSectionType;
  index: number;
  sectionsCount: number;
};

type VisibilityOptions = {
  origin: boolean;
  destination: boolean;
};

export const TourSectionItem: FC<TourSectionItemProps> = ({
  section,
  index,
  sectionsCount,
}) => {
  const queryClient = useQueryClient();
  const { tourData, refetchOrderAndTourTodos, refetchTourDataAndOrders } =
    useTourOrdersData();
  const { uuid: tourUUID, route: routeUUID } = tourData;
  const { t } = useTranslation();
  const { origin, destination } = section;
  const isApprochingStop: boolean =
    index === 0 && section.origin.isApproachStop;

  const isFixedContract: boolean = useMemo(
    () => tourData?.vehicle?.contract === 'fix' || false,
    [tourData]
  );

  const [showHoverButton, setShowHoverButton] = useState<VisibilityOptions>({
    origin: false,
    destination: false,
  });
  const [showAddSection, setShowAddSection] = useState<VisibilityOptions>({
    origin: false,
    destination: false,
  });
  const hideOptions: VisibilityOptions = { origin: false, destination: false };

  const { mutate: mutateConnectAppoachStop, isPending: isConnecting } =
    useMutation({
      mutationFn: (tourUUID: UUIDType) => connectApproachStop(tourUUID),
    });

  const { mutate: mutateDisconnectApproachStop, isPending: isDisconnecting } =
    useMutation({
      mutationFn: (tourUUID: UUIDType) => disconnectApproachStop(tourUUID),
    });

  async function handleConnectApproachStop(): Promise<void> {
    const getConnectToastId = addSelfClearingLoadingToast(
      t('connecting'),
      6000
    );

    mutateConnectAppoachStop(tourUUID, {
      onSuccess: () => {
        toast.update(getConnectToastId(), {
          closeButton: true,
          autoClose: 2000,
          render: t('approachStopConnected'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getConnectToastId(), {
          closeButton: true,
          autoClose: 6000,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
        queryClient.invalidateQueries({
          queryKey: [tourUUID, QueryKeys.ROUTES, routeUUID],
        });
      },
    });
  }

  async function handleDisconnectApproachStop(): Promise<void> {
    const getDisconnectToastId = addSelfClearingLoadingToast(
      t('disconnecting'),
      6000
    );

    mutateDisconnectApproachStop(tourUUID, {
      onSuccess: () => {
        toast.update(getDisconnectToastId(), {
          closeButton: true,
          autoClose: 2000,
          render: t('approachStopDisconnected'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getDisconnectToastId(), {
          closeButton: true,
          autoClose: 6000,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
        queryClient.invalidateQueries({
          queryKey: [tourUUID, QueryKeys.ROUTES, routeUUID],
        });
      },
    });
  }

  return (
    <>
      {index === 0 && !section.origin.isApproachStop && isFixedContract ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px 24px',
          }}
        >
          <Button
            variant={'secondary'}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
            disabled={isConnecting}
            onClick={() => handleConnectApproachStop()}
          >
            {!isConnecting ? (
              <>
                <ChainIcon sx={{ width: '16px', height: '16px' }} />
                <Typography variant={'buttonRegularSmall'}>
                  {t('connectLastUnloadingPoint')}
                </Typography>
              </>
            ) : (
              <Typography variant={'buttonRegularSmall'}>
                {t('connecting')}
              </Typography>
            )}
          </Button>
        </Box>
      ) : null}
      <ListItem
        sx={{ ...listItemStyles }}
        onMouseEnter={() => {
          setShowHoverButton({ origin: true, destination: false });
        }}
        onMouseLeave={() => {
          setShowHoverButton({ origin: false, destination: false });
        }}
      >
        <TourSectionStopItem
          stop={origin}
          isApproachingStop={isApprochingStop}
        />
        {!isApprochingStop ? <Footnote section={section} /> : null}
        {showHoverButton.origin ? (
          !isApprochingStop ? (
            <WaypointActivationButton
              btnOnClick={() =>
                setShowAddSection({ origin: true, destination: false })
              }
            />
          ) : (
            <EditApproachStopButton
              onEditButtonClick={() => {
                handleConnectApproachStop();
              }}
              onRemoveButtonClick={() => {
                handleDisconnectApproachStop();
              }}
              disabled={isConnecting || isDisconnecting}
            />
          )
        ) : null}
      </ListItem>
      {showAddSection.origin && (
        <ListItem sx={{ ...listItemStyles }}>
          <TourWaypointAddItem
            sectionUUID={section.uuid}
            closeAction={setShowAddSection}
            closeOptions={hideOptions}
          />
        </ListItem>
      )}
      {section.waypoints.map((waypoint) => {
        if (waypoint.name) {
          return (
            <ListItem sx={{ ...listItemStyles }} key={waypoint.uuid}>
              <Box>
                <TourWaypointItem
                  waypoint={waypoint}
                  sectionUUID={section.uuid}
                />
              </Box>
            </ListItem>
          );
        }
      })}

      {index === sectionsCount - 1 ? (
        <ListItem sx={{ ...listItemStyles }}>
          <TourSectionStopItem stop={destination} />
          {index !== sectionsCount - 1 ? <Footnote section={section} /> : null}
          {showHoverButton.destination ? (
            <WaypointActivationButton
              btnOnClick={() =>
                setShowAddSection({ origin: false, destination: true })
              }
            />
          ) : null}
        </ListItem>
      ) : null}
    </>
  );
};
