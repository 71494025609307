import { GooglePlacesAutocomplete } from '@core/components/GooglePlacesAutocomplete';
import { useCreateSectionWaypoint } from '@core/components/RoutingMap/hooks';
import { QueryKeys } from '@core/config';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import {
  Box,
  Button,
  Chip,
  LoadingButton,
  LocationIcon,
  Stack,
  TruckIcon,
} from '@dizzbo/ui';
import { Grid, SxProps } from '@mui/material';
import { useTourOrdersData } from '@order-detail/contexts';
import { useQueryClient } from '@tanstack/react-query';
import { AddWaypointWithSectionType, LocationType, UUIDType } from '@types';
import { AxiosError } from 'axios';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const iconStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
};
const itemStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  padding: '0 24px',
};
const chipStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  minWidth: '80px',
  minHeight: '40px',
  borderRadius: '21px',
  alignItems: 'center',
  justifyContent: 'center',
};
type TourWaypointAddItemProps = {
  sectionUUID: UUIDType;
  closeAction: Dispatch<
    SetStateAction<{ origin: boolean; destination: boolean }>
  >;
  closeOptions: { origin: boolean; destination: boolean };
};
export const TourWaypointAddItem: FC<TourWaypointAddItemProps> = ({
  sectionUUID,
  closeAction,
  closeOptions,
}) => {
  const { t } = useTranslation();

  const { tourData, refetchOrderAndTourTodos, refetchTourDataAndOrders } =
    useTourOrdersData();
  const queryClient = useQueryClient();
  const { route: routeUUID, uuid: tourUUID } = tourData;
  const [location, setLocation] = useState<LocationType>({
    lat: null,
    lng: null,
  });
  const [address, setAddress] = useState<string>(null);

  const { mutateAsync: mutateCreateWaypoint, isPending: isMutating } =
    useCreateSectionWaypoint(routeUUID);

  const onSubmit = (): void => {
    const { lat, lng } = location;

    const parsedData: AddWaypointWithSectionType = {
      name: address,
      lat,
      lng,
      sectionUUID,
    };
    const getToastId = addSelfClearingLoadingToast(
      t('waypointAddingToast'),
      6000
    );

    mutateCreateWaypoint(parsedData, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('waypointAdded'),
          type: 'success',
          isLoading: false,
        });
        closeAction(closeOptions);
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: () => {
        refetchOrderAndTourTodos();
        refetchTourDataAndOrders();
        queryClient.invalidateQueries({
          queryKey: [tourUUID, QueryKeys.ROUTES, routeUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ROUTES, routeUUID],
        });
      },
    });
  };
  return (
    <Box>
      <Box sx={{ ...itemStyles }}>
        <Box>
          <Chip
            sx={{ ...chipStyles }}
            size="large"
            variant="oxford-10"
            icon={<LocationIcon sx={{ ...iconStyles }} />}
            label={<TruckIcon sx={{ ...iconStyles }} />}
          />
        </Box>
        <Grid sx={{ width: '100%' }}>
          <Stack spacing={6}>
            <GooglePlacesAutocomplete
              label={t('waypointAutocompleteText')}
              setLocation={setLocation}
              resetForm={() => {}}
              keepValue={true}
              setFieldValue={() => {}}
              setAddressDescription={setAddress}
            />
          </Stack>
        </Grid>
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0 24px' }}
      >
        <Button
          sx={{ marginTop: 6 }}
          size="large"
          type="button"
          variant="text"
          onClick={() => {
            closeAction(closeOptions);
          }}
        >
          {t('cancel')}
        </Button>
        <LoadingButton
          sx={{ marginTop: 6 }}
          size="large"
          type="button"
          variant="text"
          disabled={!address}
          loading={isMutating}
          onClick={onSubmit}
        >
          {t('add')}
        </LoadingButton>
      </Box>
    </Box>
  );
};
