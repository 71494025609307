import { useQuery } from '@tanstack/react-query';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { getOrderProofOfDeliveryList } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Stack,
  UploadIcon,
} from '@dizzbo/ui';

import { useTourOrdersData } from '@order-detail/contexts';
import { useTranslation } from 'react-i18next';
import { ProofOfDeliveryItem } from './ProofOfDeliveryItem';
import { ProofOfDeliveryUploadDialog } from './ProofOfDeliveryUploadDialog';

type Props = {};

export const ProofOfDeliveryDocuments: React.FC<Props> = () => {
  const { selectedOrderUUID: orderUUID } = useTourOrdersData();
  const { t } = useTranslation();

  const locked = false;
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'podUploadDialog',
  });

  const { isPending, data: proofOfDeliveriesData } = useQuery({
    queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.ORDER_PODS],
    queryFn: () => getOrderProofOfDeliveryList(orderUUID),
  });

  return !isPending ? (
    <Card
      variant={locked ? 'filled-disabled' : 'filled-secondary'}
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CardHeader title="Proof of Delivery" variant="small" />
      <CardContent>
        <Stack spacing={3} divider={<Divider flexItem />}>
          {!isPending ? (
            proofOfDeliveriesData.length > 0 ? (
              proofOfDeliveriesData.map((pod) => (
                <ProofOfDeliveryItem
                  orderUUID={orderUUID}
                  podData={pod}
                  key={pod.uuid}
                />
              ))
            ) : (
              '-'
            )
          ) : (
            <>
              <Skeleton width="100%" height={56} />
              <Skeleton width="100%" height={56} />
              <Skeleton width="100%" height={56} />
            </>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          startIcon={<UploadIcon />}
          size="medium"
          variant="tertiary"
          onClick={() => popupState.open()}
        >
          {t('uploadProofOfDelivery')}
        </Button>
        {/* <Button startIcon={<SendIcon />} size="medium" variant="tertiary">
          Share upload Link
        </Button> */}
      </CardActions>
      <ProofOfDeliveryUploadDialog
        orderUUID={orderUUID}
        popupState={popupState}
      />
    </Card>
  ) : null;
};
