import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import React, { CSSProperties, FC } from 'react';
import { useDrag } from 'react-dnd';
import { Link as RouterLink } from 'react-router-dom';

import {
  ArrowRightIcon,
  Box,
  Button,
  colors,
  Divider,
  InfoOutlineIcon,
  Tooltip,
  Typography,
} from '@dizzbo/ui';
import { LoadingPointType, OrderType, RequestedVehicleTypeEnum } from '@types';
import { getVehicleTypeLabel } from '@utils';
import { useTranslation } from 'react-i18next';

const BoxOrderContainer = styled(Box)(() => ({
  display: 'flex',
  height: '90px',
  alignItems: 'center',
  justifyContent: 'center',
  width: '220px',
  maxWidth: '220px',
}));

const BoxDraggableContainer = styled(Box)(({ theme }) => ({
  height: '90px',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.turquoise[20],
  border: `1px solid ${theme.palette.oxford[10]}`,
  borderRadius: 8,
  width: '220px',
  maxWidth: '220px',
}));

const BoxFlagContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'countryCode' && prop !== 'position',
})<{ countryCode: string; flagPosition?: 'right' }>(({
  countryCode,
  flagPosition,
}) => {
  let cc = 'dizzbo';
  if (countryCode) {
    cc = countryCode.toLowerCase();
  }
  const flagImgUrl = new URL(
    `../../../../assets/images/flags/vertical-flags/flag-${cc}.svg`,
    import.meta.url
  );
  return {
    height: '100%',
    width: '8px',
    flexShrink: 0,
    backgroundImage: `url("${flagImgUrl}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderTopLeftRadius: flagPosition === 'right' ? 0 : 8,
    borderBottomLeftRadius: flagPosition === 'right' ? 0 : 8,
    borderTopRightRadius: flagPosition === 'right' ? 8 : 0,
    borderBottomRightRadius: flagPosition === 'right' ? 8 : 0,
  };
});

const BoxContent = styled(Box)(() => ({
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 0,
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: '0 16px',
}));

const BoxOrderSummary = styled(Box)(() => ({
  padding: '4px 0',
  gap: '1px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '90px',
  width: '100%',
}));

const HeaderStyles = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  minWidth: '100%',
  alignItems: 'center',
  marginBottom: 2,
}));

const TitleStyles = styled(Box)(() => ({
  whiteSpace: 'nowrap',
  marginRight: 4,
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '15px',
}));

const SubtitleStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '80%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.tiny,
  flexShrink: 1,
}));

const InfoStyles = styled(Box)(() => ({
  marginLeft: 'auto',
  display: 'flex',
  width: 16,
  height: 16,
}));

const StopsContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const StopStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'last',
})<{ last?: boolean }>(({ last }) => {
  return {
    maxWidth: 80,
    textAlign: last ? 'right' : 'left',
  };
});

const AddressStyles = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.tiny,
  marginBottom: 2,
}));

const DateStyles = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.tiny,
}));

type Props = {
  orderData: OrderType;
};

export const OrderListItem: FC<Props> = ({ orderData }) => {
  const { t } = useTranslation();
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    // "type" is required. It is used by the "accept" specification of drop targets.
    type: 'ORDER',
    item: orderData,
    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      item: monitor.getItem(),
      isDragging: monitor.isDragging(),
      end: monitor.getDropResult(),
    }),
  }));

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : 1,
  };

  const { stops, uuid: orderUUID } = orderData;
  const palletExchange: boolean = orderData?.palletExchange || false;
  const invoiceReference: string | null = orderData?.invoiceReference || null;
  const { length: stopsLength } = stops;

  const isValidDataPresent: boolean =
    stops &&
    stopsLength > 1 &&
    stops[0].loadingPoint &&
    stops[0].loadingPoint.address &&
    stops[0].loadingPoint.address.country &&
    stops[0].scheduledAt &&
    stops[stopsLength - 1].loadingPoint &&
    stops[stopsLength - 1].loadingPoint.address &&
    stops[stopsLength - 1].loadingPoint.address.country &&
    stops[stopsLength - 1].scheduledAt &&
    true;

  const reference: string = orderData.reference || '';

  const loaderName: string = orderData?.loader?.name || '';

  const origin: LoadingPointType | undefined = stops[0]?.loadingPoint;
  const originDate: string = stops[0]?.scheduledAt
    ? dayjs(stops[0].scheduledAt).format('MMM DD, hh:mm')
    : '-';

  const destination: LoadingPointType | undefined =
    stops.length > 1 ? stops[stops.length - 1]?.loadingPoint : undefined;
  const destinationDate: string =
    destination && stops[stops.length - 1]?.scheduledAt
      ? dayjs(stops[stops.length - 1].scheduledAt).format('MMM DD, hh:mm')
      : '-';

  return (
    <BoxOrderContainer
      ref={dragPreview}
      style={style}
      onDoubleClick={() => {
        window.open(`/orders/${orderUUID}`, '_blank');
      }}
    >
      <BoxDraggableContainer
        role="Handle"
        ref={isValidDataPresent ? drag : null}
        sx={
          isValidDataPresent
            ? {
                cursor: 'grab',
              }
            : {
                cursor: 'default',
                opacity: 0.6,
              }
        }
      >
        {origin?.address?.country ? (
          <BoxFlagContainer countryCode={origin.address.country} />
        ) : null}

        <BoxContent>
          <BoxOrderSummary>
            <HeaderStyles
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <TitleStyles>{reference}</TitleStyles>
              <SubtitleStyles>{loaderName}</SubtitleStyles>
              <Box
                sx={{
                  display: 'flex',
                  position: 'absolute',
                  alignItems: 'center',
                  justifyContent: 'center',
                  right: '0',
                  top: '0',
                }}
              >
                <Tooltip
                  color={colors.turquoise[150]}
                  title={
                    <Typography variant={'bodyRegularSmall'}>
                      {t('changeInOrderDetails')}
                    </Typography>
                  }
                  placement={'top'}
                >
                  <Button
                    size={'small'}
                    variant={'tertiary'}
                    component={RouterLink}
                    to={`/orders/${orderUUID}`}
                    sx={{
                      minWidth: '20px',
                      minHeight: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      padding: 0,
                    }}
                  >
                    <ArrowRightIcon sx={{ width: '16px', height: '16px' }} />
                  </Button>
                </Tooltip>
                <Tooltip
                  color={
                    isValidDataPresent ? colors.turquoise[150] : colors.red[100]
                  }
                  title={
                    isValidDataPresent ? (
                      <>
                        <Typography variant={'bodyRegularSmall'}>
                          {t('transportInsurance')}:{' '}
                          {orderData?.transportInsuranceValue
                            ? t('yes')
                            : t('no')}
                        </Typography>
                        <br />
                        <Typography variant={'bodyRegularSmall'}>
                          {t('requestedVehicleType')}:{' '}
                          {orderData?.requestedVehicleType
                            ? getVehicleTypeLabel(
                                orderData.requestedVehicleType as RequestedVehicleTypeEnum
                              )
                            : '-'}
                        </Typography>
                        <br />
                        <Typography variant={'bodyRegularSmall'}>
                          {t('invoiceReference')}:{' '}
                          {invoiceReference ? invoiceReference : '-'}
                        </Typography>
                        <br />
                        <Typography variant={'bodyRegularSmall'}>
                          {t('palletExchange')}:{' '}
                          {palletExchange ? t('yes') : t('no')}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant={'bodyRegularSmall'}>
                        {t('dataIsIncomplete')}
                      </Typography>
                    )
                  }
                  placement={'top'}
                >
                  <InfoStyles
                    sx={{
                      minWidth: '20px',
                      minHeight: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      padding: 0,
                      cursor: 'default',
                    }}
                  >
                    <InfoOutlineIcon
                      sx={{
                        width: '16px',
                        height: '16px',
                      }}
                    />
                  </InfoStyles>
                </Tooltip>
              </Box>
            </HeaderStyles>
            <StopsContainer>
              <StopStyles>
                <AddressStyles>
                  {origin?.address?.country || ''},{' '}
                  {origin?.address?.city || ''}
                </AddressStyles>
                <DateStyles>{originDate}</DateStyles>
              </StopStyles>
              <StopStyles last={true}>
                <AddressStyles>
                  {destination?.address?.country || ''},{' '}
                  {destination?.address?.city || ''}
                </AddressStyles>
                <DateStyles>{destinationDate}</DateStyles>
              </StopStyles>
            </StopsContainer>
          </BoxOrderSummary>
          {orderData.weight ? <Divider variant={'fullWidth'} /> : null}
          <Typography
            variant={'tiny'}
            textAlign={'center'}
            sx={{ padding: '2px 0' }}
          >
            {orderData.weight ? `${orderData.weight.toFixed(0)} kg` : null}
          </Typography>
        </BoxContent>
        {destination?.address?.country ? (
          <BoxFlagContainer
            countryCode={destination.address.country}
            flagPosition={'right'}
          />
        ) : null}
      </BoxDraggableContainer>
    </BoxOrderContainer>
  );
};
