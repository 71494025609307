import React from 'react';

import {
  EventModel,
  PresetManager,
  ResourceModel,
  SchedulerEventModel,
  ViewPreset,
} from '@bryntum/schedulerpro';
import { BryntumSchedulerProProps } from '@bryntum/schedulerpro-react';
import { TourItem } from '../TourItem';
import { TruckListItem } from '../TruckListItem';

const customViewPreset = new ViewPreset({
  id: 'customPreset', // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'customPreset'
  name: 'My view preset', // A human-readable name provided to be used in GUI, e.i. preset picker, etc.
  base: 'dayAndWeek', // Extends 'hourAndDay' view preset provided by PresetManager. You can pick out any of PresetManager's view presets: PresetManager.records
  tickWidth: 100,
  // timeResolution: {
  //   // Override time resolution
  //   unit: 'minute',
  //   increment: 15, // Make it increment every 15 mins
  // },

  // headers: [
  //   // Override headers
  //   {
  //     unit: 'day',
  //     dateFormat: 'DD.MM.YYYY', // Use different date format for top header 01.10.2020
  //   },
  //   {
  //     unit: 'hour',
  //     dateFormat: 'LT',
  //   },
  // ],
});

PresetManager.add(customViewPreset);

const eventRenderer = ({
  eventRecord,
}: {
  eventRecord: SchedulerEventModel;
}) => {
  return <TourItem eventRecord={eventRecord} />;
};

class TourEvent extends EventModel {
  static get fields() {
    return [
      {
        name: 'startDate',
        dataSource: 'scheduler.startsAt',
      },
      {
        name: 'endDate',
        dataSource: 'scheduler.arrivesAt',
      },
      { name: 'id', dataSource: 'uuid' },
      { name: 'resourceId', dataSource: 'vehicle.uuid' },
      { name: 'name', dataSource: 'reference' },
      { name: 'durationUnit', defaultValue: 'second' },
    ];
  }
}

class VehicleModel extends ResourceModel {
  static get fields() {
    return [
      { name: 'id', dataSource: 'uuid' },
      { name: 'name', dataSource: 'uuid' },
    ];
  }
}

export const schedulerProConfig: BryntumSchedulerProProps = {
  // project: {
  //   // Set default project calendar
  //   calendar: 'weekends',
  //   // Inline calendar tree
  //   calendarsData: [
  //     // Calendar definition
  //     {
  //       id: 'weekends',
  //       name: 'Weekends',
  //       // Intervals to define all SA and SU as non-working days
  //       intervals: [
  //         // @ts-ignore
  //         {
  //           recurrentStartDate: 'on Sat at 0:00',
  //           recurrentEndDate: 'on Mon at 0:00',
  //           isWorking: false,
  //         },
  //       ],
  //     },
  //   ],
  //   // other Project configs, for example eventStore, resourceStore, assignmentStore, etc
  // },
  eventStore: {
    modelClass: TourEvent,
  },
  resourceStore: {
    modelClass: VehicleModel,
  },
  // https://bryntum.com/products/scheduler/docs/api/Scheduler/view/SchedulerBase
  // ############################################################################
  timeZone: 'Europe/Berlin',

  barMargin: 2,
  rowHeight: 72,

  startDate: new Date(),
  weekStartDay: 1,

  // endDate: new Date(2023, 12, 14),

  viewPreset: 'customPreset',
  allowOverlap: true, // disable overlapping events
  infiniteScroll: true, // make the timeline infinite scrollable
  zoomOnTimeAxisDoubleClick: false,
  zoomOnMouseWheel: false,
  createEventOnDblClick: false,
  eventTooltipFeature: {
    disabled: true,
  },
  taskEditFeature: {
    disabled: true,
  },
  // viewPreset: 'dayAndWeek',
  // viewPreset: {
  //   base: 'hourAndDay',

  //   tickWidth: 30,
  //   displayDateFormat: 'll HH:mm',
  //   headers: [
  //     {
  //       unit: 'day',
  //       dateFormat: 'ddd DD/MM', // Mon 01/10
  //     },
  //     {
  //       unit: 'hour',
  //       dateFormat: 'HH',
  //     },
  //   ],
  // },
  // eventStyle: 'border',
  // forceFit: true,
  // eventTooltipFeature: {
  //   // Tooltip configs can be used here
  //   align: 'l-r', // Align left to right,
  //   // A custom HTML template
  //   template: (data) => `<dl>
  //     <dt>Assigned to:</dt>
  //        <dt>Time:</dt>
  //        <dd>
  //            ${DateHelper.format(
  //              data.eventRecord.startDate,
  //              'LT'
  //            )} - ${DateHelper.format(data.eventRecord.endDate, 'LT')}
  //        </dd>
  //        ${
  //          data.eventRecord.get('note')
  //            ? `<dt>Note:</dt><dd>${data.eventRecord.note}</dd>`
  //            : ''
  //        }

  //        ${
  //          data.eventRecord.get('image')
  //            ? `<dt>Image:</dt><dd><img class="image" src="${data.eventRecord.get(
  //                'image'
  //              )}"/></dd>`
  //            : ''
  //        }
  //    </dl>`,
  // },
  eventMenuFeature: {
    // disable the context menu on right click on the event itself
    disabled: true,
  },
  timeAxisHeaderMenuFeature: {
    // diable the context menu on right click in the top time axis
    disabled: true,
    items: {
      zoomLevel: false,
    },
  },
  scheduleMenuFeature: {
    // disable the right click menu in the timeline
    disabled: true,
  },
  timeRangesFeature: {
    disabled: false,
    showCurrentTimeLine: true, // how the vertical indicator for the current datetime
    showHeaderElements: true,
    enableResizing: true, // allow to resize time ranges in the timeline
  },
  columnLinesFeature: {
    disabled: false,
  },
  eventDragFeature: {
    constrainDragToTimeSlot: true,
  },
  eventResizeFeature: {
    disabled: true,
  },
  eventDragCreateFeature: {
    disabled: true, // disable the event create via drag/click on the timeline
  },
  scheduleTooltipFeature: {
    // works -> disabling all the tooltips in the timeline
    disabled: true,
  },
  dependenciesFeature: {
    // disable the dependencies & interface
    disabled: true,
  },
  nonWorkingTimeFeature: {
    disabled: false,
  },
  headerZoomFeature: {
    // zoom via click an drag on the header timeline
    disabled: true,
  },
  eventRenderer,
  // tbar: [
  //   {
  //     type: 'widget',
  //     cls: 'widget-title',
  //     html: 'Schedule View',
  //     flex: 1,
  //   },
  //   {
  //     text: 'New event',
  //     icon: 'b-fa b-fa-plus',
  //     color: 'b-green b-raised',
  //     onClick: 'up.onNewEventClick',
  //   },
  //   {
  //     type: 'datefield',
  //     ref: 'dateField',
  //     width: 190,
  //     editable: false,
  //     step: 1,
  //     onChange: 'up.onDateFieldChange',
  //   },
  //   {
  //     type: 'textfield',
  //     ref: 'filterByName',
  //     placeholder: 'Filter tasks',
  //     clearable: true,
  //     keyStrokeChangeDelay: 100,
  //     triggers: {
  //       filter: {
  //         align: 'start',
  //         cls: 'b-fa b-fa-filter',
  //       },
  //     },
  //     onChange: 'up.onFilterChange',
  //   },
  // ],
  sortFeature: false,
  columns: [
    // {
    //   type: 'resourceInfo',
    //   text: '',
    //   // field: 'name',
    //   showEventCount: true,
    //   width: 150,
    // },
    {
      // disable the right click context menu
      enableCellContextMenu: false,
      enableHeaderContextMenu: false,
      text: 'Vehicles',
      field: 'name',
      width: 260,
      minWidth: 260,
      readOnly: true,
      // align: 'center',
      renderer: ({ record }) => <TruckListItem data={record} />,
    },
  ],

  // mark a give range of time on the timeline
  // timeRanges: [
  // {
  //   id: 1,
  //   startDate: DateHelper.add(first, 2, 'days'),
  //   duration: 1,
  //   durationUnit: 'day',
  // },
  // ],

  listeners: {
    onBeforeEventDrag(e) {
      console.log('eventRecord', e);
    },
  },
  // listeners: {
  //   horizontalScroll() {
  //     // Keep scrollTo date synced with the visible date
  // this basically updates the dropdown/widget in the header where
  // you can select the date you want to go to
  //     this.widgetMap.scrollTo.value = DateHelper.floor(
  //       this.viewportCenterDate,
  //       '1 day'
  //     );
  //   },
  // },
};
