import SvgIcon from '@mui/material/SvgIcon';
import React, { ComponentProps, FC } from 'react';
import { Box } from '../Box';

type MuiSvgIconProps = ComponentProps<typeof SvgIcon>;

export const ChainIcon: FC<MuiSvgIconProps> = (props) => {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <SvgIcon {...props}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5406 1.59442C15.2993 2.60978 15.9018 4.85855 14.8865 6.61719L12.6288 10.5276C11.8078 11.9496 10.1803 12.6158 8.65767 12.2819L9.37915 11.0323C10.2111 11.0547 11.0287 10.6323 11.4741 9.86096L13.7318 5.95052C14.3789 4.8296 13.9949 3.39629 12.8739 2.74912C11.753 2.10196 10.3197 2.48602 9.67255 3.60694L7.41486 7.51737C7.06523 8.12294 7.01658 8.81969 7.22249 9.4344L6.43346 10.8011C5.63893 9.66669 5.52202 8.12918 6.26016 6.8507L8.51785 2.94027C9.5332 1.18163 11.782 0.579071 13.5406 1.59442Z"
            fill="#081324"
          />
          <path
            d="M8.39842 8.6172C8.73889 8.02749 8.79394 7.35132 8.60645 6.74865L9.40362 5.36791C10.1766 6.49882 10.2838 8.01826 9.55312 9.28386L7.29543 13.1943C6.28008 14.9529 4.03131 15.5555 2.27267 14.5401C0.514023 13.5248 -0.0885317 11.276 0.926822 9.51737L3.18451 5.60694C4.01246 4.17289 5.66054 3.50755 7.19424 3.86133L6.47689 5.10382C5.63032 5.06545 4.79219 5.48903 4.33921 6.27361L2.08152 10.184C1.43436 11.305 1.81841 12.7383 2.93933 13.3854C4.06025 14.0326 5.49357 13.6486 6.14073 12.5276L8.39842 8.6172Z"
            fill="#081324"
          />
        </svg>
      </SvgIcon>
    </Box>
  );
};

ChainIcon.displayName = 'ChainIcon';
