import {
  Box,
  Chip,
  colors,
  LoadingAndUnloadingIcon,
  LoadingIcon,
  LocationIcon,
  Typography,
  UnloadingIcon,
} from '@dizzbo/ui';
import { Grid, SxProps } from '@mui/material';
import { LoadingPointType, LoadingType } from '@types';
import { assertNever } from '@utils';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const iconStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
};

const chipStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  minWidth: '80px',
  minHeight: '40px',
  borderRadius: '21px',
  alignItems: 'center',
  justifyContent: 'center',
};

const itemDetailsStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
};

const boxDetailsStyles: SxProps = {
  maxWidth: '320px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

type TourSectionStopItemDetailsProps = {
  loadingPoint: LoadingPointType;
  loadingType: LoadingType | 'none' | 'approaching';
  deliveriesCount: number;
};

export const TourSectionStopItemDetails: FC<
  TourSectionStopItemDetailsProps
> = ({ loadingType, loadingPoint, deliveriesCount }) => {
  const { t } = useTranslation();

  switch (loadingType) {
    case 'loading':
      return (
        <Box sx={{ ...itemDetailsStyles }}>
          <Box>
            <Chip
              sx={{ ...chipStyles }}
              size="large"
              variant="oxford-40"
              icon={<LocationIcon sx={{ ...iconStyles }} />}
              label={<LoadingIcon sx={{ ...iconStyles }} />}
            />
          </Box>
          <Grid>
            <Typography variant="labelSmall" color={colors.oxford[25]}>
              {t('loadingPoint')}
            </Typography>
            <Box sx={{ ...boxDetailsStyles }}>
              <Typography variant="bodyRegular" color={colors.oxford[100]}>
                {loadingPoint.address.formatted}
              </Typography>
            </Box>

            {deliveriesCount > 1 ? (
              <Typography variant="labelSmall">
                {t('totalLoadings', {
                  loadingsCount: deliveriesCount,
                })}
              </Typography>
            ) : null}
          </Grid>
        </Box>
      );
    case 'unloading':
      return (
        <Box sx={{ ...itemDetailsStyles }}>
          <Box>
            <Chip
              sx={{ ...chipStyles }}
              size="large"
              variant="green-100"
              icon={<LocationIcon sx={{ ...iconStyles }} />}
              label={<UnloadingIcon sx={{ ...iconStyles }} />}
            />
          </Box>
          <Grid>
            <Typography variant="labelSmall" color={colors.oxford[25]}>
              {t('unloadingPoint')}
            </Typography>
            <Box sx={{ ...boxDetailsStyles }}>
              <Typography variant="bodyRegular" color={colors.oxford[100]}>
                {loadingPoint.address.formatted}
              </Typography>
            </Box>

            {deliveriesCount > 1 ? (
              <Typography variant="labelSmall">
                {t('totalLoadings', {
                  loadingsCount: deliveriesCount,
                })}
              </Typography>
            ) : null}
          </Grid>
        </Box>
      );
    case 'both':
      return (
        <Box sx={{ ...itemDetailsStyles }}>
          <Box>
            <Chip
              sx={{ ...chipStyles }}
              size="large"
              variant="mixed"
              icon={<LocationIcon sx={{ ...iconStyles }} />}
              label={<LoadingAndUnloadingIcon sx={{ ...iconStyles }} />}
            />
          </Box>
          <Grid>
            <Typography variant="labelSmall" color={colors.oxford[25]}>
              {t('unloadingPoint')}
            </Typography>
            <Box sx={{ ...boxDetailsStyles }}>
              <Typography variant="bodyRegular" color={colors.oxford[100]}>
                {loadingPoint.address.formatted}
              </Typography>
            </Box>

            {deliveriesCount > 1 ? (
              <>
                <Typography variant="labelSmall">
                  {t('totalLoadings', {
                    loadingsCount: deliveriesCount,
                  })}
                </Typography>
              </>
            ) : null}
          </Grid>
        </Box>
      );
    case 'approaching':
      return (
        <Box sx={{ ...itemDetailsStyles }}>
          <Box>
            <Chip
              sx={{ ...chipStyles }}
              size="large"
              variant="outline-oxford-40-thick"
              icon={<LocationIcon sx={{ ...iconStyles }} />}
              label={<UnloadingIcon sx={{ ...iconStyles }} />}
            />
          </Box>
          <Grid>
            <Typography variant="labelSmall" color={colors.oxford[25]}>
              {t('lastUnloadingPoint')}
            </Typography>
            <Box sx={{ ...boxDetailsStyles }}>
              <Typography variant="bodyRegular" color={colors.oxford[100]}>
                {loadingPoint.address.formatted}
              </Typography>
            </Box>

            {deliveriesCount > 1 ? (
              <Typography variant="labelSmall">
                {t('totalLoadings', {
                  loadingsCount: deliveriesCount,
                })}
              </Typography>
            ) : null}
          </Grid>
        </Box>
      );
    case 'none':
      return (
        <Box sx={{ ...itemDetailsStyles }}>
          <Box>
            <Chip
              sx={{ ...chipStyles }}
              size="large"
              variant="green-80"
              icon={<LocationIcon sx={{ ...iconStyles }} />}
            />
          </Box>
          <Grid>
            <Typography variant="labelSmall" color={colors.oxford[25]}>
              {t('stop')}
            </Typography>
            <Box sx={{ ...boxDetailsStyles }}>
              <Typography variant="bodyRegular" color={colors.oxford[100]}>
                {loadingPoint.address.formatted}
              </Typography>
            </Box>

            {deliveriesCount > 1 ? (
              <Typography variant="labelSmall">
                {t('totalLoadings', {
                  loadingsCount: deliveriesCount,
                })}
              </Typography>
            ) : null}
          </Grid>
        </Box>
      );

    default:
      assertNever(loadingType);
      break;
  }
};
