import { Box, colors, List, Typography } from '@dizzbo/ui';
import { SxProps } from '@mui/material';
import { DeliveryType, RouteSectionType } from '@types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TourSectionItem } from './TourSectionItem';

type TourStopsListProps = {
  sections: Array<RouteSectionType>;
  deliveriesData: Record<string, Array<DeliveryType>>;
};

export const TourStopsList: FC<TourStopsListProps> = ({ sections }) => {
  const { length: sectionsCount } = sections;

  const { t } = useTranslation();

  const listStyles: SxProps = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '0',
    padding: '12px 0',
    width: '480px',
    justifyContent: 'flex-start',
    maxHeight: '550px',
    overflowY: 'auto',
  };

  return sectionsCount > 0 ? (
    <Box>
      <List sx={{ ...listStyles }}>
        {sections.map((section: RouteSectionType, index: number) => {
          const { uuid } = section;
          return (
            <TourSectionItem
              section={section}
              index={index}
              sectionsCount={sectionsCount}
              key={`tour_stops_section_${uuid}_${index}`}
            />
          );
        })}
      </List>
    </Box>
  ) : (
    <Box>
      <Typography variant="bodyRegular" color={colors.oxford[100]}>
        {t('loadingDataMissing')}
      </Typography>
    </Box>
  );
};
