import React, { FC } from 'react';

import { Card, CardContent, Stack } from '@dizzbo/ui';

import { useTourOrdersData } from '@order-detail/contexts';
import { CarrierSlot } from './CarrierSlot';
import { StatusMessage } from './StatusMessage';
import { VehicleSlot } from './VehicleSlot';

type Props = {};

export const VehicleAsssignment: FC<Props> = () => {
  const { tourData } = useTourOrdersData();

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: 374,
      }}
    >
      <CardContent>
        <Stack spacing={6}>
          <StatusMessage hasVehicle={!!tourData?.vehicle} />
          <CarrierSlot />
          <VehicleSlot />
        </Stack>
      </CardContent>
    </Card>
  );
};
