import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { createOrderContact } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  LoadingButton,
  MenuItem,
  Select,
  Stack,
} from '@dizzbo/ui';

import { UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { ContactsAutoCompleteTextField } from './ContactsAutoCompleteTextField';

type Props = {
  orderUUID: UUIDType;
  customerUUID?: UUIDType;
  popupState: PopupState;
};

export const AddContactsDialog: React.FC<Props> = ({
  orderUUID,
  customerUUID,
  popupState,
}: Props) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    // reset the form if the dialog is not open.
    // Because this Dialog doesn't unmount.
    if (popupState.isOpen) {
      reset();
    }
  }, [popupState]);

  // TODO REFACTOR THIS
  const { mutate: mutateOrderContact, isPending }: any = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.CONTACTS],
    mutationFn: (values: { contact: UUIDType; contact_type: string }) =>
      createOrderContact(orderUUID, values),
  });

  const onSubmit = (formData: any) => {
    mutateOrderContact(
      {
        contact: formData.contact.uuid,
        contactType: formData.contactType,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.CONTACTS],
          });
          toast.success(`Contact added.`);
          popupState.close();
        },
        onError: () => {
          toast.error(`Couldn't add Contact.`);
          popupState.close();
        },
      }
    );
  };

  const { t } = useTranslation();

  return (
    <>
      <Dialog
        PaperProps={{
          elevation: 6,
          variant: 'filled-primary',
          sx: {
            minWidth: 600,
          },
        }}
        scroll="paper"
        {...bindDialog(popupState)}
      >
        <DialogTitle onClose={popupState.close}>Add Contacts</DialogTitle>
        <DialogContent dividers>
          <form id="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={6}>
              <Controller
                name="contact"
                control={control}
                render={({ field }) => (
                  <ContactsAutoCompleteTextField
                    label="Contacts"
                    customerUUID={customerUUID}
                    parentPopupState={popupState}
                    type="order"
                    {...field}
                  />
                )}
              />
              <Controller
                name="contactType"
                control={control}
                defaultValue="main"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel id="adr-class-label">Contact type</InputLabel>
                    <Select
                      labelId="contact-type-class-label"
                      id="contact-type"
                      label="contact-type"
                      {...field}
                    >
                      <MenuItem value="pod">PoD</MenuItem>
                      <MenuItem value="orderconfirmation">
                        {t('orderConfirmation')}
                      </MenuItem>
                      <MenuItem value="exportlicense">Exportlicense</MenuItem>
                      <MenuItem value="invoice">Invoice</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Stack spacing={3} direction="row">
            <Button variant="tertiary" onClick={() => popupState.close()}>
              {t('cancel')}
            </Button>
            <LoadingButton
              loading={isPending}
              type="submit"
              form="form"
              disabled={!isDirty || !isValid}
            >
              {t('add')}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
