import { reviewOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SquareIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { useTourOrdersData } from '@order-detail/contexts';
import { OrderType } from '@types';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

const SubtitleStyles = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const ContentCardStyles = styled(Card)(() => ({
  width: '10  0%',
  height: 200,
}));

type Props = {
  orderData: OrderType;
  popupState: PopupState;
};

export const OrderReviewDialog: FC<Props> = ({ orderData, popupState }) => {
  const { t } = useTranslation();
  const { refetchTourDataAndOrders, refetchOrderAndTourTodos } =
    useTourOrdersData();
  const { uuid: orderUUID, reference, status } = orderData;

  const { mutate: mutateReviewOrder, isPending } = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: () => reviewOrder(orderUUID),
  });

  const handleClick = () => {
    const getToastId = addSelfClearingLoadingToast(
      t('markingOrderAsReviewed'),
      6000
    );

    mutateReviewOrder(null, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('orderMarkedAsReviewed'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: async (): Promise<void> => {
        await Promise.all([
          refetchOrderAndTourTodos(),
          refetchTourDataAndOrders(),
        ]);
        popupState.close();
      },
    });
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        <Typography variant="h3" color="primary">
          {reference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {status}
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <SubtitleStyles>
          <Typography variant="h5">{t('orderReview')}</Typography>
        </SubtitleStyles>
        <ContentCardStyles variant="filled-green" elevation={0} square>
          <CardContent>
            <Typography variant="bodyRegular">
              {t('loader')}:&nbsp;
              {orderData?.loader
                ? `${orderData.loader.name || ''} ${orderData.loader.name || ''}`
                : 'Loader info missing!'}
            </Typography>
            <br />
            <Typography variant="bodyRegular">
              {t('customerNumber')}:&nbsp;
              {orderData?.loader
                ? orderData.loader.customerNumber
                : 'Loader info missing!'}
            </Typography>
          </CardContent>
        </ContentCardStyles>
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button
            variant="tertiary"
            onClick={() => popupState.close()}
            disabled={isPending}
          >
            {t('cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="primary"
            onClick={handleClick}
            disabled={isPending}
            loading={isPending}
            startIcon={<SquareIcon />}
          >
            {t('markOrderAsReviewed')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
