import { bindTrigger, PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { RoutingMap } from '@core/components/RoutingMap';
import { useGetRoute } from '@core/hooks';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  SettingsGearIcon,
} from '@dizzbo/ui';

import { useTourOrdersData } from '@order-detail/contexts';
import { useTranslation } from 'react-i18next';

type Props = {
  popupState: PopupState;
};

export const MapPreview: React.FC<Props> = ({ popupState }) => {
  const { selectedOrderData } = useTourOrdersData();
  const { route: routeUUID } = selectedOrderData;
  const { data } = useGetRoute(routeUUID);

  const { t } = useTranslation();

  return (
    <Card variant="filled-secondary" elevation={1}>
      <CardContent
        sx={{
          padding: 0,
          height: 156,
          width: 215,
        }}
      >
        {!popupState.isOpen && <RoutingMap route={data} hideWaypoints={true} />}
      </CardContent>
      <CardActions sx={{ justifyContent: 'center' }}>
        <Button
          startIcon={<SettingsGearIcon />}
          size="medium"
          variant="tertiary"
          {...bindTrigger(popupState)}
        >
          {t('routeSettings')}
        </Button>
      </CardActions>
    </Card>
  );
};
