import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Currency } from '@dizzbo/core/i18n';
import {
  Alert,
  ArrowRightIcon,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SquareIcon,
  Stack,
  Typography,
  WarningIcon,
} from '@dizzbo/ui';

import { confirmOrder, getOrderTasks } from '@core/api';
import { QueryKeys } from '@core/config';
import { OrderSubtodoType, OrderType } from '@types';
import { generateOrderAllTasksArray } from '@utils';

const SubtitleStyles = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const ContentCardStyles = styled(Card)(() => ({
  width: '100%',
  height: '100%',
}));

type Props = {
  orderData: OrderType;
  popupState: PopupState;
};

export const OrderConfirmationDialog: React.FC<Props> = ({
  orderData,
  popupState,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    uuid: orderUUID,
    reference,
    status,
    loader,
    price,
    stops,
  } = orderData;
  const sheduledAt: string | undefined =
    stops && stops[0] ? stops[0]?.scheduledAt : undefined;

  const { data: orderTasks, isPending: isOrderTasksPending } = useQuery({
    queryKey: [QueryKeys.TASKS, orderUUID],
    queryFn: () => getOrderTasks(orderUUID),
    enabled: popupState.isOpen,
  });

  const allOrderTasks: Array<OrderSubtodoType> = generateOrderAllTasksArray(
    orderTasks,
    isOrderTasksPending
  );

  const { mutate: mutateConfirmOrder, isPending } = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: () => confirmOrder(orderUUID),
    onSuccess() {
      toast.success(t('orderConfirmed'));
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS],
      });
      popupState.close();
    },
    onError() {
      toast.error(t('orderCouldNotBeConfirmed'));
      popupState.close();
    },
  });

  const handleClick = () => {
    mutateConfirmOrder();
    popupState.close();
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        <Typography variant="h3" color="primary">
          {reference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {status}
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <SubtitleStyles>
          <Typography variant="h5">{t('confirmOrder')}</Typography>
        </SubtitleStyles>
        <ContentCardStyles variant="filled-green" elevation={0} square>
          <CardContent>
            {loader ? (
              <>
                <Typography variant="bodyRegular">
                  {t('orderConfirmationDialog_customerNumber')}:&nbsp;
                  {loader.customerNumber || t('customerNumberMissing')}
                </Typography>
                <br />
                <Typography variant="bodyRegular">
                  {t('loader')}:&nbsp;
                  {loader.name && loader.secondName
                    ? `${loader.name || ''} ${loader.secondName || ''}`
                    : t('loaderNameMissing')}
                </Typography>
                <br />
              </>
            ) : (
              <>
                <Typography>{t('loaderInfoMissing')}</Typography>
                <br />
              </>
            )}
            {price ? (
              <>
                <Typography variant="bodyRegular">
                  {t('price')}: <Currency value={price} />
                </Typography>
                <br />
              </>
            ) : (
              <>
                <Typography>{t('priceMissing')}</Typography>
                <br />
              </>
            )}
            {sheduledAt ? (
              <>
                <Typography variant="bodyRegular">
                  {t('sheduledAt')}: {dayjs(sheduledAt).format('DD.MM.YYYY')}
                </Typography>
                <br />
              </>
            ) : (
              <>
                <Typography>{t('sheduledAtDateMissing')}</Typography>
                <br />
              </>
            )}
          </CardContent>
        </ContentCardStyles>
        {!isOrderTasksPending && allOrderTasks.length > 0 ? (
          <ContentCardStyles variant="filled-disabled" elevation={0} square>
            <CardContent>
              <Alert
                title={t('orderCannotBeConfirmed')}
                message={allOrderTasks.map((subTodo) => (
                  <Box key={subTodo.uuid + subTodo.field}>
                    <Typography component={'span'}>
                      {subTodo.fieldLabel && subTodo.fieldLabel.length > 0
                        ? subTodo.fieldLabel
                        : 'Todo'}
                      :&nbsp;
                      {subTodo.message}
                    </Typography>
                    <br />
                  </Box>
                ))}
                variant="standard"
                severity="warning"
                icon={<WarningIcon />}
              />
            </CardContent>
          </ContentCardStyles>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button
            variant="tertiary"
            onClick={() => popupState.close()}
            disabled={isPending}
          >
            {t('cancel')}
          </Button>
          {allOrderTasks?.length > 0 ? (
            <Button
              startIcon={<ArrowRightIcon />}
              size="medium"
              component={RouterLink}
              to={`/orders/${orderUUID}`}
            >
              {t('goToOrderDetails')}
            </Button>
          ) : (
            <LoadingButton
              type="submit"
              variant="primary"
              onClick={handleClick}
              loading={isPending}
              disabled={isPending}
              startIcon={<SquareIcon />}
            >
              {t('confirmOrder')}
            </LoadingButton>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
