import React, { FC, useMemo } from 'react';

import { Card, CardContent } from '@dizzbo/ui';

import { useTourOrdersData } from '@order-detail/contexts';
import { CustomerType } from '@types';
import { CustomerDisplay } from './CustomerDisplay';
import { CustomerForm } from './CustomerForm';

type Props = {};

export const CustomerSlot: FC<Props> = () => {
  const { selectedOrderData } = useTourOrdersData();

  const loader: CustomerType | undefined = useMemo(
    () => selectedOrderData?.loader,
    [selectedOrderData]
  );

  return (
    <Card variant="filled-secondary" elevation={0}>
      <CardContent>
        {loader ? <CustomerDisplay /> : <CustomerForm />}
      </CardContent>
    </Card>
  );
};
