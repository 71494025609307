import { styled, useTheme } from '@mui/material/styles';
import PopupState, { bindTrigger } from 'material-ui-popup-state';
import React, { FC, useMemo } from 'react';

import { AvatarListItem, Card, EditIcon, IconButton, Stack } from '@dizzbo/ui';

import { useTourOrdersData } from '@order-detail/contexts';
import { UserType } from '@types';
import { useTranslation } from 'react-i18next';
import { EditAssigneesDialog } from './EditAssigneesDialog';
const AssigneeListItemWrapperStyles = styled(Card)(() => ({
  display: 'flex',
  backgroundColor: 'transparent',
  position: 'relative',
  width: '100%',
  height: 56,
  paddingTop: 12,
  paddingBottom: 12,
  alignItems: 'center',
}));

type Props = {};

export const AssigneeListItem: FC<Props> = () => {
  const theme = useTheme();
  const { tourData } = useTourOrdersData();
  const { uuid: tourUUID } = tourData;

  const assignee: UserType | null = useMemo(
    () => tourData?.assignee || null,
    [tourData]
  );

  const { t } = useTranslation();

  return (
    <AssigneeListItemWrapperStyles>
      <AvatarListItem
        username={
          assignee
            ? assignee?.displayName || t('displayNameNotFound')
            : t('noAssignee')
        }
        avatarSx={{
          width: 32,
          height: 32,
          ...theme.typography.bodyBoldSmall,
        }}
      />
      <Stack sx={{ marginLeft: 'auto' }}>
        <PopupState variant="popover" popupId="participant-menu">
          {(popupState) => (
            <>
              <IconButton
                size="medium"
                variant="tertiary"
                {...bindTrigger(popupState)}
              >
                <EditIcon />
              </IconButton>
              <EditAssigneesDialog
                key={`key_assignee_tour_${tourUUID}_${assignee}`}
                tourUUID={tourUUID}
                popupState={popupState}
                assignees={[assignee]}
              />
            </>
          )}
        </PopupState>
      </Stack>
    </AssigneeListItemWrapperStyles>
  );
};
