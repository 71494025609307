import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { FC, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import {
  getTransportContract,
  recreateTransportContract,
  sendTransportContract,
} from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  LoadingButton,
  LoadingIndicator,
  LockedIcon,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Paper,
  SendIcon,
  Stack,
  ViewIcon,
} from '@dizzbo/ui';

import { TransportContractPreviewDialog } from './TransportContractPreviewDialog';

import { addSelfClearingLoadingToast } from '@dizzbo/core';
import { useTourOrdersData } from '@order-detail/contexts';
import { TransportContractType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import HeroBackgroundImage from '../../../../../assets/images/transport-contract-thumbnail.png';

const PaperStyles = styled(Paper)(() => ({
  width: '100%',
  height: '100%',
  minHeight: 320,
  backgroundImage: `url(${HeroBackgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  backgroundPositionY: 'center',
  justifyContent: 'center',
  alignItems: 'flex-end',
  display: 'flex',
  padding: 24,
}));

type Props = {};

export const TransportContract: FC<Props> = () => {
  const queryClient = useQueryClient();
  const { tourData } = useTourOrdersData();
  const { uuid: tourUUID } = tourData;

  const tourReference: string | null = useMemo(
    () => tourData?.reference || null,
    [tourData]
  );

  const [transportContractUUID, setTransportContractUUID] = useState<UUIDType>(
    tourData.transportContract || ''
  );

  const podPreviewDialogPopupState = usePopupState({
    variant: 'dialog',
    popupId: 'podReviewDialog',
  });

  const menuPopupState = usePopupState({
    variant: 'popover',
    popupId: 'transportContractMenu',
  });

  const {
    data: transportContractData,
    isFetching: isTransportContractDataFetching,
  } = useQuery({
    queryKey: [
      QueryKeys.TOURS,
      tourUUID,
      QueryKeys.TRANSPORT_CONTRACTS,
      transportContractUUID,
    ],
    queryFn: () => getTransportContract(tourUUID, transportContractUUID),
    enabled: !!transportContractUUID,
  });

  const { mutateAsync: mutateSendTransportContract } = useMutation({
    mutationKey: [
      QueryKeys.TOURS,
      tourUUID,
      QueryKeys.TRANSPORT_CONTRACTS,
      transportContractUUID,
    ],
    mutationFn: () => sendTransportContract(tourUUID, transportContractUUID),
  });

  const { mutateAsync: mutateRecreateTransportContract } = useMutation({
    mutationKey: [
      QueryKeys.TOURS,
      tourUUID,
      QueryKeys.TRANSPORT_CONTRACTS,
      transportContractUUID,
    ],
    mutationFn: () =>
      recreateTransportContract(tourUUID, transportContractUUID),
  });

  const { t } = useTranslation();

  async function handleResendTransportContract(): Promise<void> {
    const getResendToastId = addSelfClearingLoadingToast(
      t('sendingTransportContract'),
      6000
    );

    try {
      await mutateSendTransportContract();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TOURS, tourUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TOURS, tourUUID, QueryKeys.TRANSPORT_CONTRACTS],
      });
      menuPopupState.close();
      toast.update(getResendToastId(), {
        closeButton: true,
        autoClose: 2000,
        render: t('transportContractSent'),
        type: 'success',
        isLoading: false,
      });
    } catch {
      toast.update(getResendToastId(), {
        closeButton: true,
        autoClose: 6000,
        render: t('transportContractCouldNotBeSent'),
        type: 'error',
        isLoading: false,
      });
    }
  }

  async function handleRecreateTransportContract(): Promise<void> {
    const getRecreateToastId = addSelfClearingLoadingToast(
      t('recreatingTransportContract'),
      6000
    );

    try {
      const data: TransportContractType =
        await mutateRecreateTransportContract();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TOURS, tourUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeys.TOURS,
          tourUUID,
          QueryKeys.TRANSPORT_CONTRACTS,
          transportContractUUID,
        ],
      });
      setTransportContractUUID(data.uuid);
      menuPopupState.close();
      toast.update(getRecreateToastId(), {
        closeButton: true,
        autoClose: 2000,
        render: t('transportContractRecreated'),
        type: 'success',
        isLoading: false,
      });
    } catch {
      toast.update(getRecreateToastId(), {
        closeButton: true,
        autoClose: 6000,
        render: t('transportContractCouldNotBeRecreated'),
        type: 'error',
        isLoading: false,
      });
    }
  }

  return !isTransportContractDataFetching && !transportContractData ? (
    <Card
      variant="filled-disabled"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CardHeader
        title="Transport Contract"
        variant="small"
        action={
          <IconButton aria-label="settings" variant="tertiary">
            <LockedIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Alert
          title={t('availableAsSoonAsTransportContractIsAvailable')}
          severity="info"
        />
      </CardContent>
    </Card>
  ) : (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CardHeader title="Transport Contract" variant="small" />
      <CardContent sx={{ padding: '0!important' }}>
        {isTransportContractDataFetching ? (
          <Box
            sx={{ width: '100%', textAlign: 'center', padding: '40px 10px' }}
          >
            <LoadingIndicator />
          </Box>
        ) : (
          <PaperStyles variant="filled-secondary" square>
            <Stack direction="row" spacing={3}>
              <LoadingButton
                loading={
                  isTransportContractDataFetching &&
                  podPreviewDialogPopupState.isOpen
                }
                startIcon={<ViewIcon />}
                size="medium"
                variant="primary"
                onClick={() => podPreviewDialogPopupState.open()}
              >
                {t('view')}
              </LoadingButton>
              {transportContractData && transportContractData.file ? (
                <TransportContractPreviewDialog
                  tourReference={tourReference}
                  popupState={podPreviewDialogPopupState}
                  file={transportContractData.file}
                  createdAt={transportContractData.createdAt}
                  emailSentAt={transportContractData.emailSentAt}
                />
              ) : null}
              {transportContractUUID ? (
                <IconButton
                  size="medium"
                  variant="primary"
                  {...bindTrigger(menuPopupState)}
                >
                  <MoreVerticalIcon />
                </IconButton>
              ) : null}
            </Stack>
            <Menu {...bindMenu(menuPopupState)}>
              <MenuItem onClick={() => handleResendTransportContract()}>
                <ListItemIcon>
                  <SendIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('resendTransportContract')}</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleRecreateTransportContract()}>
                <ListItemIcon>
                  <SendIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('recreateTransportContract')}</ListItemText>
              </MenuItem>
            </Menu>
          </PaperStyles>
        )}
      </CardContent>
    </Card>
  );
};
