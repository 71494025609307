import { axiosAuthenticatedRequest } from '@dizzbo/core/api';
import {
  ContactType,
  CustomerContactType,
  CustomerType,
  UUIDType,
} from '@types';

export const getCustomersAutocomplete = async (
  params
): Promise<CustomerType[]> => {
  const res = await axiosAuthenticatedRequest.get('/v2/loaders/autocomplete/', {
    params: params,
  });
  return res.data;
};

export const getCustomerContacts = async (
  loaderUUID: UUIDType
): Promise<CustomerContactType[]> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/loaders/${loaderUUID}/contacts/`
  );
  return res.data;
};

export const searchContactsByEmail = async (
  email: string
): Promise<Array<Partial<ContactType>>> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/contacts/autocomplete/`,
    { params: { q: email } }
  );
  return res.data;
};
