import React, { FC, useMemo } from 'react';

import { Card, CardContent } from '@dizzbo/ui';

import { useTourOrdersData } from '@order-detail/contexts';
import { CarrierType } from '@types';
import { CarrierDisplay } from './CarrierDisplay';
import { CarrierForm } from './CarrierForm';

type Props = {};

export const CarrierSlot: FC<Props> = () => {
  const { tourData } = useTourOrdersData();

  const carrier: CarrierType | null = useMemo(
    () => tourData?.carrier || null,
    [tourData]
  );

  return (
    <Card variant="filled-secondary" elevation={0}>
      <CardContent>
        {carrier ? <CarrierDisplay /> : <CarrierForm />}
      </CardContent>
    </Card>
  );
};
