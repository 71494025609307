import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { updateOrderStop } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  DatePicker,
  DateTimePicker,
  Divider,
  SingleInputTimeRangeField,
  Stack,
} from '@dizzbo/ui';

import { isTimeSlotInputValid } from '@dizzbo/core';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useTourOrdersData } from '@order-detail/contexts';
import {
  ISODateTimeType,
  ISODateType,
  LoadingPointType,
  OrderDetailStopDateFormData,
  StopType,
  UUIDType,
} from '@types';
import {
  parseOrderDetailStopDateFormData,
  parseTimeSlotDateRange,
} from '@utils';
import { useTranslation } from 'react-i18next';

type Props = {
  stopUUID: UUIDType;
  requestedDate?: ISODateType;
  scheduledAt?: ISODateTimeType;
  arrivedAt?: ISODateTimeType;
  timeslotStartsAt?: ISODateTimeType;
  timeslotEndsAt?: ISODateTimeType;
  loadingPoint?: LoadingPointType;
};

export const StopDates: React.FC<Props> = ({
  stopUUID,
  requestedDate,
  scheduledAt,
  arrivedAt,
  timeslotStartsAt,
  timeslotEndsAt,
}) => {
  const { selectedOrderUUID } = useTourOrdersData();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const methods = useForm({
    mode: 'all',
    values: {
      requestedDate: requestedDate ? dayjs(requestedDate) : null,
      scheduledAt: scheduledAt ? dayjs(scheduledAt) : null,
      arrivedAt: arrivedAt ? dayjs(arrivedAt) : null,
      timeSlot:
        timeslotStartsAt || timeslotEndsAt
          ? [
              timeslotStartsAt && dayjs(timeslotStartsAt),
              timeslotEndsAt && dayjs(timeslotEndsAt),
            ]
          : null,
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  // TODO REFACTOR THIS
  const mutation = useMutation({
    mutationKey: [
      QueryKeys.ORDERS,
      selectedOrderUUID,
      QueryKeys.STOPS,
      stopUUID,
    ],
    mutationFn: (values: Partial<StopType>) =>
      updateOrderStop(selectedOrderUUID, stopUUID, values),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeys.ORDERS,
          selectedOrderUUID,
          QueryKeys.STOPS,
          stopUUID,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, selectedOrderUUID, QueryKeys.DELIVERIES],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TODOS, selectedOrderUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TASKS, selectedOrderUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, selectedOrderUUID, QueryKeys.STOPS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.DELIVERIES, selectedOrderUUID],
      });
    },
    onError: (error) => {
      console.log(
        '%c <<<Error in useMutationHook in StopDates.tsx>>> Error: ',
        'background: #222; color: #bada55',
        error
      );
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(
    mutation.isError,
    errors,
    mutation.error
  );

  const onSubmit = (formData: OrderDetailStopDateFormData) => {
    const parsedData: Partial<StopType> =
      parseOrderDetailStopDateFormData(formData);

    mutation.mutate(parsedData);
  };

  return (
    <FormProvider {...methods}>
      <Stack spacing={3} direction="row">
        <Controller
          name="requestedDate"
          control={control}
          render={({ field }) => (
            <DatePicker
              sx={{ width: '100%', minWidth: 160, maxWidth: 160 }}
              label={t('customerPlan')}
              slotProps={{
                textField: {
                  error: hasFieldError(field),
                  helperText: fieldError(field),
                },
              }}
              {...field}
            />
          )}
        />
        <Divider orientation="vertical" flexItem />
        <Controller
          name="timeSlot"
          control={control}
          render={({ field }) => {
            const { value: timeSlotDateRange, ...restFieldProps } = field;
            const parsedTimeSlotDateRange: DateRange<Dayjs | null> =
              parseTimeSlotDateRange(timeSlotDateRange);
            const isInputMissing: boolean =
              parsedTimeSlotDateRange[0] === null &&
              parsedTimeSlotDateRange[1] === null;

            return (
              <SingleInputTimeRangeField
                sx={{
                  width: '100%',
                  minWidth: 120,
                  maxWidth: 120,
                }}
                label={t('timeslot')}
                format="HH:mm"
                slotProps={{
                  textField: {
                    error:
                      hasFieldError(field) ||
                      (!isInputMissing &&
                        !isTimeSlotInputValid(parsedTimeSlotDateRange)),
                    helperText: (
                      <>
                        {!isInputMissing &&
                        !isTimeSlotInputValid(parsedTimeSlotDateRange)
                          ? t('timeslotInputWrong')
                          : fieldError(field)}
                      </>
                    ),
                  },
                }}
                {...restFieldProps}
                value={parsedTimeSlotDateRange}
              />
            );
          }}
        />

        <Controller
          name="scheduledAt"
          control={control}
          render={({ field }) => (
            <DateTimePicker
              sx={{ width: '100%', minWidth: 200, maxWidth: 200 }}
              label={t('dispoPlan')}
              slotProps={{
                textField: {
                  error: hasFieldError(field),
                  helperText: fieldError(field),
                },
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="arrivedAt"
          control={control}
          render={({ field }) => (
            <DateTimePicker
              sx={{ width: '100%', minWidth: 200, maxWidth: 200 }}
              label={t('actualTime')}
              {...field}
              slotProps={{
                textField: {
                  error: hasFieldError(field),
                  helperText: fieldError(field),
                },
              }}
            />
          )}
        />
      </Stack>

      <AutoSave
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        isValid={isValid}
        control={control}
      />
    </FormProvider>
  );
};
