import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { getTransportContracts } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Stack,
} from '@dizzbo/ui';

import { useTourOrdersData } from '@order-detail/contexts';
import { TransportContractItem } from './TransportContractItem';

type Props = {};

export const TransportContractList: React.FC<Props> = () => {
  const { tourData } = useTourOrdersData();
  const { uuid: tourUUID } = tourData;

  const { isPending, data: transportContractsData } = useQuery({
    queryKey: [QueryKeys.TOURS, tourUUID, QueryKeys.TRANSPORT_CONTRACTS],
    queryFn: () => getTransportContracts(tourUUID),
  });

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CardHeader title="Transport Contracts" variant="small" />
      <CardContent>
        <Stack spacing={3} divider={<Divider flexItem />}>
          {!isPending ? (
            transportContractsData.length > 0 ? (
              transportContractsData.map((transportContract) => (
                <TransportContractItem
                  transportContractData={transportContract}
                  key={transportContract.uuid}
                />
              ))
            ) : (
              '-'
            )
          ) : (
            <>
              <Skeleton width="100%" height={56} />
              <Skeleton width="100%" height={56} />
              <Skeleton width="100%" height={56} />
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
