import React, { FC } from 'react';

import { View } from '@dizzbo/core/views';
import { PageHeader } from '@dizzbo/ui';

import { WorkspaceKeys } from '@core/config';
import { WorkspacesProvider } from '@dizzbo/core/components/Workspaces/contexts';
import { SxProps } from '@mui/material';
import { SchedulerContent } from '@scheduler/components/SchedulerContent';
import { useTranslation } from 'react-i18next';
import { SchedulerSettingsProvider } from '../context';

const ViewStyles: SxProps = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
};

type Props = {};

export const SchedulerView: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <View title={t('dispoPlan')} sx={{ ...ViewStyles }}>
      <PageHeader title={t('dispoPlan')} />
      <WorkspacesProvider workspaceNamespace={WorkspaceKeys.SHEDULER}>
        <SchedulerSettingsProvider>
          <SchedulerContent />
        </SchedulerSettingsProvider>
      </WorkspacesProvider>
    </View>
  );
};
