import { getDepartments, getOrders, getTours, getVehicles } from '@core/api';
import { QueryKeys } from '@core/config';
import { useAuth, useWorkspaces } from '@dizzbo/core/hooks';
import { PaginationState } from '@dizzbo/core/types';
import { DateRangeSelectorFilterType } from '@dizzbo/ui';
import { DateRange } from '@mui/x-date-pickers-pro';
import {
  InfiniteData,
  InfiniteQueryObserverResult,
  keepPreviousData,
  QueryObserverResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import {
  CustomerType,
  DepartmentType,
  ISODateType,
  OrderType,
  PaginatedListType,
  RequestedVehicleType,
  TourListItemType,
  TourType,
  UUIDType,
  VehicleType,
} from '@types';
import { parseDateRangeData } from '@utils';
import dayjs, { Dayjs } from 'dayjs';
import React, {
  Context,
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

const DEFAULT_PAGINATION_STATE: PaginationState = {
  pageIndex: 0,
  pageSize: 20,
};

const DEFAULT_CALENDAR_PARAMS: CalendarParams = {
  sliderPosition: 100,
  calendarDateRange: [dayjs().subtract(1, 'week'), dayjs().add(1, 'week')],
  calendarDateSelector: null,
};

const DEFAULT_ORDER_FILTERS: OrderFilters = {
  search: '',
  loadingPoint: '',
  unloadingPoint: '',
  loadingDateRange: [null, null],
  unloadingDateRange: [null, null],
  loadingDateSelector: null,
  unloadingDateSelector: null,
  customer: null,
};

const DEFAULT_VEHICLE_FILTERS: VehicleFilters = {
  search: '',
  department: null,
  vehicleType: '',
};

const DEFAULT_TOUR_LIMIT: number = 100;
const DISPLAYED_DATE_RANGE_MAX: number = 40;

type OrderFilters = {
  search: string | null;
  loadingPoint: string | null;
  unloadingPoint: string | null;
  loadingDateRange: DateRange<Dayjs | null>;
  unloadingDateRange: DateRange<Dayjs | null>;
  loadingDateSelector: DateRangeSelectorFilterType | null;
  unloadingDateSelector: DateRangeSelectorFilterType | null;
  customer: CustomerType | null;
};

type VehicleFilters = {
  search?: string;
  vehicleType?: RequestedVehicleType | '';
  department?: DepartmentType | null;
};

type ParsedOrderFilters = {
  search?: string;
  loading_stop?: string;
  unloading_stop?: string;
  planned_loading_date_after?: ISODateType;
  planned_loading_date_before?: ISODateType;
  planned_unloading_date_after?: ISODateType;
  planned_unloading_date_before?: ISODateType;
  loader?: UUIDType;
};

type PaginationQueryParams = {
  limit: number;
  offset: number;
};

type ParsedTourQueryParams = {
  hasRoute: boolean;
  hasVehicle: boolean;
  scheduled_after: ISODateType;
  scheduled_before: ISODateType;
};

type CalendarParams = {
  sliderPosition: number;
  calendarDateRange: DateRange<Dayjs | null>;
  calendarDateSelector: DateRangeSelectorFilterType | null;
};

type SchedulerSettingsContextType = {
  paginationState: PaginationState;
  calendarParams: CalendarParams;
  updateCalendarParams: (newCalendarParams: CalendarParams) => void;
  ordersCount: number;
  visibleDate: Date;
  setVisibleDate: Dispatch<SetStateAction<Date>>;
  selectedTour: TourListItemType;
  setSelectedTour: Dispatch<SetStateAction<TourListItemType>>;
  selectedOrder: OrderType;
  setSelectedOrder: Dispatch<SetStateAction<OrderType>>;
  targetVehicle: VehicleType;
  setTargetVehicle: Dispatch<SetStateAction<VehicleType>>;
  orderFilters: OrderFilters;
  updateOrderFilters: (updatedFilter: Partial<OrderFilters>) => void;
  clearOrderFilters: () => void;
  vehicleFilters: VehicleFilters;
  updateVehicleFilters: (updatedFilter: Partial<VehicleFilters>) => void;
  clearVehicleFilters: (params?: { withSearch?: boolean }) => void;
  displayedOrders: Array<OrderType>;
  displayedTours: Array<TourType>;
  displayedVehicles: Array<VehicleType>;
  isFetching: boolean;
  isToursDataFetching: boolean;
  isVehiclesDataFetching: boolean;
  isVehiclesNextPagePresent: boolean;
  refetchOrders: () => Promise<
    QueryObserverResult<
      InfiniteData<PaginatedListType<OrderType>, unknown>,
      Error
    >
  >;
  refetchTours: () => Promise<
    QueryObserverResult<PaginatedListType<TourType>, Error>
  >;
  updateOrdersPagination: (newPaginationState: PaginationState) => void;
  defaultCalendarDateRange: DateRange<Dayjs>;
  clearSelection: () => void;
  departmentsData: PaginatedListType<DepartmentType>;
  fetchVehiclesNextPage: () => Promise<
    InfiniteQueryObserverResult<InfiniteData<PaginatedListType<VehicleType>>>
  >;
  DISPLAYED_DATE_RANGE_MAX: number;
};

const SchedulerSettingsContext: Context<SchedulerSettingsContextType> =
  createContext<SchedulerSettingsContextType | undefined>(undefined);

export const SchedulerSettingsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    activeWorkspaceUUID,
    setWorkspaceSettings,
    activeWorkspace,
    isLoadingWorkspaces,
  } = useWorkspaces();
  const { user } = useAuth();
  const { departments, department } = user;
  const userDepartments: Array<UUIDType> = department
    ? [department.uuid]
    : departments.map((department: DepartmentType) => department.uuid);

  const [paginationState, setPaginationState] = useState<PaginationState>(
    DEFAULT_PAGINATION_STATE
  );

  const [calendarParams, setCalendarParams] = useState<CalendarParams>(
    DEFAULT_CALENDAR_PARAMS
  );

  const [ordersCount, setOrdersCount] = useState<number>(0);

  const [visibleDate, setVisibleDate] = useState<Date>(new Date());

  const [orderFilters, setOrderFilters] = useState<OrderFilters>(
    DEFAULT_ORDER_FILTERS
  );

  const [vehicleFilters, setVehicleFilters] = useState<VehicleFilters>(
    DEFAULT_VEHICLE_FILTERS
  );

  const [selectedTour, setSelectedTour] = useState<TourListItemType | null>(
    null
  );

  const [selectedOrder, setSelectedOrder] = useState<OrderType | null>(null);

  const [targetVehicle, setTargetVehicle] = useState<VehicleType | null>(null);

  const [displayedVehicles, setDisplayedVehicles] = useState<
    Array<VehicleType>
  >([]);

  const [displayedOrders, setDisplayedOrders] = useState<Array<OrderType>>([]);

  const [displayedTours, setDisplayedTours] = useState<Array<TourType>>([]);

  const [isFetching, setIsFetching] = useState<boolean>(true);

  const {
    data: ordersData,
    isFetching: isOrdersDataFetching,
    refetch: refetchOrders,
  } = useInfiniteQuery({
    queryKey: [
      QueryKeys.ORDERS,
      activeWorkspaceUUID,
      paginationState,
      orderFilters,
      userDepartments,
    ],
    queryFn: ({ pageParam }) => {
      const results: Promise<PaginatedListType<OrderType>> = getOrders({
        has_vehicle: 'false',
        has_stops: 'true',
        status: ['NEW', 'DRAFT', 'CONFIRMED'],
        ...getParsedOrderFilters(orderFilters),
        ...pageParam,
        owning_department: userDepartments,
        tour_status: ['NEW', 'DRAFT'],
      });

      return results;
    },
    enabled: !isLoadingWorkspaces,
    initialPageParam: getPaginationQueryParams(paginationState),
    getNextPageParam: (
      lastPage: PaginatedListType<OrderType>
    ): PaginationQueryParams => {
      if (!lastPage.next) {
        return undefined;
      }

      const params: URLSearchParams = new URL(lastPage.next).searchParams;

      const parsedParams: PaginationQueryParams = {
        limit: Number(params.get('limit')) || 0,
        offset: Number(params.get('offset')) || 0,
      };

      return parsedParams;
    },
  });

  const {
    data: vehiclesData,
    isFetching: isVehiclesDataFetching,
    fetchNextPage: fetchVehiclesNextPage,
    hasNextPage: isVehiclesNextPagePresent,
  } = useInfiniteQuery({
    queryKey: [QueryKeys.VEHICLES, activeWorkspaceUUID, vehicleFilters],
    queryFn: ({ pageParam }) => {
      const results: Promise<PaginatedListType<VehicleType>> = getVehicles({
        search: vehicleFilters.search,
        department: vehicleFilters?.department?.uuid || '',
        vehicle_type: vehicleFilters.vehicleType,
        contract: 'fix',
        ...pageParam,
      });

      return results;
    },
    enabled: !isLoadingWorkspaces,
    initialPageParam: getPaginationQueryParams(paginationState),
    getNextPageParam: (
      lastPage: PaginatedListType<VehicleType>
    ): PaginationQueryParams => {
      if (!lastPage.next) {
        return undefined;
      }

      const params: URLSearchParams = new URL(lastPage.next).searchParams;

      const parsedParams: PaginationQueryParams = {
        limit: Number(params.get('limit')) || 0,
        offset: Number(params.get('offset')) || 0,
      };

      return parsedParams;
    },
  });

  const { data: departmentsData, isFetching: isDepartmentsDataFetching } =
    useQuery({
      queryKey: [QueryKeys.DEPARTMENTS],
      queryFn: () => getDepartments(),
    });

  const tourQueryParams: ParsedTourQueryParams = getTourQueryParams();
  const {
    data: toursData,
    isFetching: isToursDataFetching,
    refetch: refetchTours,
  } = useQuery({
    queryKey: [QueryKeys.TOURS, tourQueryParams, userDepartments],
    queryFn: () =>
      getTours({
        ...tourQueryParams,
        limit: DEFAULT_TOUR_LIMIT,
        owning_department: userDepartments,
        vehicle: displayedVehicles.map((vehicle: VehicleType) => vehicle.uuid),
      }),
    placeholderData: keepPreviousData,
    enabled:
      !isVehiclesDataFetching &&
      !!displayedVehicles &&
      displayedVehicles.length > 0,
  });

  useEffect(() => {
    if (!isLoadingWorkspaces) {
      const isPaginationStateMissing: boolean = !(
        activeWorkspace?.settings?.schedulerSettings?.paginationState &&
        Object.keys(activeWorkspace.settings.schedulerSettings.paginationState)
          .length > 0
      );
      const isCalendarParamsMissing: boolean = !(
        activeWorkspace?.settings?.schedulerSettings?.calendarParams &&
        Object.keys(activeWorkspace.settings.schedulerSettings.calendarParams)
          .length > 0
      );
      const isOrderFiltersDataMissing: boolean = !(
        activeWorkspace?.settings?.schedulerSettings?.orderFilters &&
        Object.keys(activeWorkspace.settings.schedulerSettings.orderFilters)
          .length > 0
      );
      const isVehicleFiltersDataMissing: boolean = !(
        activeWorkspace?.settings?.schedulerSettings?.vehicleFilters &&
        Object.keys(activeWorkspace.settings.schedulerSettings.vehicleFilters)
          .length > 0
      );

      const settingsUpdateObject: object = {};

      if (isPaginationStateMissing) {
        settingsUpdateObject['paginationState'] = DEFAULT_PAGINATION_STATE;
        setPaginationState(DEFAULT_PAGINATION_STATE);
      } else {
        const receivedPaginationState: PaginationState =
          activeWorkspace.settings.schedulerSettings.paginationState;
        setPaginationState(receivedPaginationState);
      }

      if (isCalendarParamsMissing) {
        settingsUpdateObject['calendarParams'] = DEFAULT_CALENDAR_PARAMS;
        setCalendarParams(DEFAULT_CALENDAR_PARAMS);
      } else {
        const { calendarDateRange, sliderPosition, calendarDateSelector } =
          activeWorkspace.settings.schedulerSettings.calendarParams;
        const receivedCalendarParams: CalendarParams = {
          sliderPosition,
          calendarDateSelector,
          calendarDateRange: parseDateRangeData(calendarDateRange),
        };
        setCalendarParams(receivedCalendarParams);
      }

      if (isOrderFiltersDataMissing) {
        settingsUpdateObject['orderFilters'] = DEFAULT_ORDER_FILTERS;
        setOrderFilters(DEFAULT_ORDER_FILTERS);
      } else {
        const receivedOrderFilters: Partial<OrderFilters> =
          activeWorkspace.settings.schedulerSettings.orderFilters;
        setOrderFilters({
          search: receivedOrderFilters.search || '',
          loadingPoint: receivedOrderFilters.loadingPoint || '',
          unloadingPoint: receivedOrderFilters.unloadingPoint || '',
          loadingDateRange: parseDateRangeData(
            receivedOrderFilters.loadingDateRange
          ),
          unloadingDateRange: parseDateRangeData(
            receivedOrderFilters.unloadingDateRange
          ),
          loadingDateSelector: receivedOrderFilters.loadingDateSelector || null,
          unloadingDateSelector:
            receivedOrderFilters.unloadingDateSelector || null,
          customer: receivedOrderFilters.customer || null,
        });
      }

      if (isVehicleFiltersDataMissing) {
        settingsUpdateObject['vehicleFilters'] = DEFAULT_VEHICLE_FILTERS;
        setVehicleFilters(DEFAULT_VEHICLE_FILTERS);
      } else {
        const receivedVehicleFilters: Partial<VehicleFilters> =
          activeWorkspace.settings.schedulerSettings.vehicleFilters;

        setVehicleFilters({
          search: receivedVehicleFilters.search || '',
          vehicleType: receivedVehicleFilters.vehicleType || '',
          department: receivedVehicleFilters.department || null,
        });
      }

      if (
        isPaginationStateMissing ||
        isCalendarParamsMissing ||
        isOrderFiltersDataMissing ||
        isVehicleFiltersDataMissing
      ) {
        setWorkspaceSettings(activeWorkspaceUUID, 'schedulerSettings', {
          ...settingsUpdateObject,
        });
      }

      refetchOrders();
    }
  }, [activeWorkspaceUUID, isLoadingWorkspaces]);

  useEffect(() => {
    if (
      !isLoadingWorkspaces &&
      activeWorkspace &&
      !isVehiclesDataFetching &&
      vehiclesData &&
      !isDepartmentsDataFetching &&
      departmentsData
    ) {
      setDisplayedVehicles(
        vehiclesData.pages.reduce(
          (acc: Array<VehicleType>, curr: PaginatedListType<VehicleType>) => {
            return curr.results ? acc.concat(curr.results) : acc;
          },
          []
        )
      );
    }
  }, [isVehiclesDataFetching, isLoadingWorkspaces, isDepartmentsDataFetching]);

  useEffect(() => {
    if (
      !isLoadingWorkspaces &&
      activeWorkspace &&
      !isOrdersDataFetching &&
      ordersData &&
      ordersData.pages[0] &&
      !isDepartmentsDataFetching &&
      departmentsData
    ) {
      setDisplayedOrders(
        ordersData.pages[0]?.results ? [...ordersData.pages[0].results] : []
      );
      setOrdersCount(ordersData.pages[0].count);
    }
  }, [isOrdersDataFetching, isLoadingWorkspaces, isDepartmentsDataFetching]);

  useEffect(() => {
    if (!isLoadingWorkspaces && activeWorkspace) {
      refetchTours();
    }
  }, [displayedVehicles]);

  useEffect(() => {
    if (
      !isLoadingWorkspaces &&
      activeWorkspace &&
      !isToursDataFetching &&
      toursData
    ) {
      setDisplayedTours(toursData?.results ? [...toursData.results] : []);
      setIsFetching(false);
    }
  }, [isToursDataFetching]);

  function getPaginationQueryParams(
    newPaginationState: PaginationState
  ): PaginationQueryParams {
    const orderQueryParams: PaginationQueryParams = {
      limit: newPaginationState.pageSize,
      offset: newPaginationState.pageSize * newPaginationState.pageIndex,
    };
    return orderQueryParams;
  }

  function getParsedOrderFilters(filters: OrderFilters): ParsedOrderFilters {
    const parsedOrderFilters: ParsedOrderFilters = {};
    if (filters.search) {
      parsedOrderFilters.search = filters.search;
    }
    if (filters.customer) {
      parsedOrderFilters.loader = filters.customer.uuid;
    }
    if (filters.loadingPoint) {
      parsedOrderFilters.loading_stop = filters.loadingPoint;
    }
    if (filters.unloadingPoint) {
      parsedOrderFilters.unloading_stop = filters.unloadingPoint;
    }
    if (filters.loadingDateRange) {
      if (filters.loadingDateRange[0]) {
        parsedOrderFilters.planned_loading_date_after =
          filters.loadingDateRange[0].format('YYYY-MM-DD') as ISODateType;
      }
      if (filters.loadingDateRange[1]) {
        parsedOrderFilters.planned_loading_date_before =
          filters.loadingDateRange[1].format('YYYY-MM-DD') as ISODateType;
      }
    }
    if (filters.unloadingDateRange) {
      if (filters.unloadingDateRange[0]) {
        parsedOrderFilters.planned_unloading_date_after =
          filters.unloadingDateRange[0].format('YYYY-MM-DD') as ISODateType;
      }
      if (filters.unloadingDateRange[1]) {
        parsedOrderFilters.planned_unloading_date_before =
          filters.unloadingDateRange[1].format('YYYY-MM-DD') as ISODateType;
      }
    }

    return parsedOrderFilters;
  }

  function updateOrdersPagination(newPaginationState: PaginationState): void {
    setPaginationState(newPaginationState);
    setWorkspaceSettings(activeWorkspaceUUID, 'schedulerSettings', {
      paginationState: newPaginationState,
    });
  }

  function updateCalendarParams(newCalendarParams: CalendarParams): void {
    setCalendarParams(newCalendarParams);
    setWorkspaceSettings(activeWorkspaceUUID, 'schedulerSettings', {
      calendarParams: newCalendarParams,
    });
  }

  function updateOrderFilters(updatedFilter: Partial<OrderFilters>): void {
    const updatedOrderFilters = { ...orderFilters, ...updatedFilter };
    setOrderFilters(updatedOrderFilters);
    setWorkspaceSettings(activeWorkspaceUUID, 'schedulerSettings', {
      orderFilters: updatedOrderFilters,
    });
  }

  function clearOrderFilters(): void {
    updateOrderFilters(DEFAULT_ORDER_FILTERS);
  }

  function updateVehicleFilters(updatedFilter: Partial<VehicleFilters>): void {
    const updatedVehicleFilters = { ...vehicleFilters, ...updatedFilter };
    setVehicleFilters(updatedVehicleFilters);
    setWorkspaceSettings(activeWorkspaceUUID, 'schedulerSettings', {
      vehicleFilters: updatedVehicleFilters,
    });
  }

  function clearVehicleFilters(params?: { withSearch?: boolean }): void {
    const { department, vehicleType } = DEFAULT_VEHICLE_FILTERS;
    updateVehicleFilters(
      params?.withSearch
        ? DEFAULT_VEHICLE_FILTERS
        : {
            department,
            vehicleType,
          }
    );
  }

  function getTourQueryParams(): ParsedTourQueryParams {
    const { calendarDateRange } = calendarParams;
    const scheduledAfterDate: Dayjs =
      calendarDateRange[0] && calendarDateRange[1]
        ? calendarDateRange[0]
        : dayjs().subtract(1, 'week');
    const scheduledBeforeDate: Dayjs =
      calendarDateRange[0] && calendarDateRange[1]
        ? calendarDateRange[1]
        : dayjs().add(1, 'week');

    return {
      hasRoute: true,
      hasVehicle: true,
      scheduled_after: scheduledAfterDate.format('YYYY-MM-DD') as ISODateType,
      scheduled_before: scheduledBeforeDate.format('YYYY-MM-DD') as ISODateType,
    };
  }

  function clearSelection(): void {
    setSelectedOrder(null);
    setSelectedTour(null);
    setTargetVehicle(null);
  }

  return (
    <SchedulerSettingsContext.Provider
      value={{
        paginationState,
        calendarParams,
        updateCalendarParams,
        ordersCount,
        visibleDate,
        setVisibleDate,
        selectedTour,
        setSelectedTour,
        selectedOrder,
        setSelectedOrder,
        targetVehicle,
        setTargetVehicle,
        orderFilters,
        updateOrderFilters,
        clearOrderFilters,
        vehicleFilters,
        updateVehicleFilters,
        clearVehicleFilters,
        displayedOrders,
        displayedTours,
        displayedVehicles,
        isFetching,
        isToursDataFetching,
        isVehiclesDataFetching,
        isVehiclesNextPagePresent,
        refetchOrders,
        refetchTours,
        updateOrdersPagination,
        defaultCalendarDateRange: DEFAULT_CALENDAR_PARAMS.calendarDateRange,
        clearSelection,
        departmentsData,
        fetchVehiclesNextPage,
        DISPLAYED_DATE_RANGE_MAX,
      }}
    >
      {children}
    </SchedulerSettingsContext.Provider>
  );
};

export const useSchedulerSettings = () => {
  const context: SchedulerSettingsContextType = useContext(
    SchedulerSettingsContext
  );

  if (!context) {
    throw new Error(
      'useSchedulerSettings hook must be used within a SchedulerSettingsProvider'
    );
  }

  return context;
};
