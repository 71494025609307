import { colors } from '@dizzbo/ui';
import { SxProps } from '@mui/material';
import Popover from '@mui/material/Popover';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { assertNever } from '../../../../apps/tms-client/src/utils/typeUtils';
import { Box } from '../Box';
import { Button } from '../Button';
import { DateRangeCalendar } from '../DateRangeCalendar';
import { Divider } from '../Divider';
import { DateIcon, SwitchIcon } from '../icons';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

export type DateRangeSelectorFilterType =
  | 'today'
  | 'this_week'
  | 'this_month'
  | 'this_year'
  | 'today_plus_minus_2_days'
  | 'today_plus_7_days'
  | 'today_plus_14_days'
  | 'today_plus_21_days'
  | 'today_plus_28_days'
  | 'today_plus_31_days';

type ButtonContentProps = {
  buttonLabel: string;
  filterLabel: string;
  dateRange: DateRange<Dayjs>;
  selectedFilter: DateRangeSelectorFilterType | null;
};

type Props = {
  label: string;
  dateRangeValue: DateRange<Dayjs | null>;
  filterValue: DateRangeSelectorFilterType | null;
  onChange: (
    dateRange: DateRange<Dayjs>,
    dateSelector: DateRangeSelectorFilterType
  ) => void;
  filters: Array<DateRangeSelectorFilterType>;
  buttonStyles?: SxProps;
  isButtonContentDisabled?: boolean;
  isSwitchIconDisabled?: boolean;
  resetValue?: DateRange<Dayjs>;
  maxDateRange?: number;
};

export const DateRangeSelector: FC<Props> = ({
  label,
  dateRangeValue,
  filterValue,
  onChange,
  filters,
  buttonStyles = {
    height: 40,
  },
  isButtonContentDisabled = false,
  isSwitchIconDisabled = false,
  resetValue = [null, null],
  maxDateRange,
}) => {
  const { t } = useTranslation();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `${label}dateRangeSelector`,
  });

  function handleDateRangeChange(value: DateRange<Dayjs | null>): void {
    const newDateRange: DateRange<Dayjs | null> = [
      value[0] || null,
      value[1] || value[0] || null,
    ];

    if (
      maxDateRange &&
      Math.abs(newDateRange[1].diff(newDateRange[0], 'day')) > maxDateRange
    ) {
      newDateRange[0] = newDateRange[1].subtract(maxDateRange, 'days');
      toast.warning(t('calendarDateDifferenceWarning', { maxDateRange }), {
        autoClose: 6000,
      });
    }

    onChange(newDateRange, null);
  }

  function getFilterLabel(filter: DateRangeSelectorFilterType | null): string {
    if (!filter) {
      return '';
    }
    switch (filter) {
      case 'today':
        return t('today');
      case 'this_week':
        return t('thisWeek');
      case 'this_month':
        return t('thisMonth');
      case 'this_year':
        return t('thisYear');
      case 'today_plus_minus_2_days':
        return `${t('today')} +- 2`;
      case 'today_plus_7_days':
        return `+7 ${t('days')}`;
      case 'today_plus_14_days':
        return `+14 ${t('days')}`;
      case 'today_plus_21_days':
        return `+21 ${t('days')}`;
      case 'today_plus_28_days':
        return `+28 ${t('days')}`;
      case 'today_plus_31_days':
        return `+31 ${t('days')}`;

      default:
        assertNever(filter);
        return '';
    }
  }

  function handleFilterButtonOnClick(
    filter: DateRangeSelectorFilterType
  ): void {
    const now: Dayjs = dayjs();
    let newDateRange: DateRange<Dayjs> = [null, null];

    switch (filter) {
      case 'today':
        newDateRange = [now, now];
        break;
      case 'this_week':
        newDateRange = [now.startOf('week'), now.endOf('week')];
        break;
      case 'this_month':
        newDateRange = [now.startOf('month'), now.endOf('month')];
        break;
      case 'this_year':
        newDateRange = [now.startOf('year'), now.endOf('year')];
        break;
      case 'today_plus_minus_2_days':
        newDateRange = [now.subtract(2, 'days'), now.add(2, 'days')];
        break;
      case 'today_plus_7_days':
        newDateRange = [now, now.add(7, 'days')];
        break;
      case 'today_plus_14_days':
        newDateRange = [now, now.add(14, 'days')];
        break;
      case 'today_plus_21_days':
        newDateRange = [now, now.add(21, 'days')];
        break;
      case 'today_plus_28_days':
        newDateRange = [now, now.add(28, 'days')];
        break;
      case 'today_plus_31_days':
        newDateRange = [now, now.add(31, 'days')];
        break;

      default:
        assertNever(filter);
    }

    onChange(newDateRange, filter);
  }

  function handleReset(): void {
    onChange(resetValue, null);
  }

  return (
    <>
      <Button
        variant={'tertiary'}
        size="medium"
        sx={{ ...buttonStyles, gap: '6px' }}
        {...bindTrigger(popupState)}
      >
        <DateIcon sx={{ height: '16px', width: '16px' }} />
        <ButtonContent
          buttonLabel={!isButtonContentDisabled ? label : ''}
          filterLabel={
            !isButtonContentDisabled ? getFilterLabel(filterValue) : ''
          }
          dateRange={dateRangeValue}
          selectedFilter={filterValue}
        />
        {!isSwitchIconDisabled ? (
          <SwitchIcon sx={{ height: '16px', width: '16px' }} />
        ) : null}
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>
          <Box sx={{ padding: '12px 24px' }}>
            <Typography
              variant={'buttonRegularSmall'}
              color={colors.turquoise[150]}
            >
              {label}
            </Typography>
          </Box>
          <Divider variant={'fullWidth'} />
          <Stack
            spacing={0}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Stack spacing={3} alignItems="flex-start" sx={{ px: 6, py: 6 }}>
              <Button
                variant="tertiary"
                size="medium"
                onClick={() => handleReset()}
              >
                {t('reset')}
              </Button>
              {filters.map((filter: DateRangeSelectorFilterType, i) => {
                return (
                  <Button
                    variant="tertiary"
                    size="medium"
                    key={`${filter}_${label}_${i}`}
                    onClick={() => handleFilterButtonOnClick(filter)}
                  >
                    {getFilterLabel(filter)}
                  </Button>
                );
              })}
            </Stack>
            <DateRangeCalendar
              calendars={1}
              onChange={handleDateRangeChange}
              value={dateRangeValue}
            />
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

DateRangeSelector.displayName = 'DateRangeSelector';

const ButtonContent: FC<ButtonContentProps> = ({
  buttonLabel,
  filterLabel,
  dateRange,
  selectedFilter,
}) => {
  if (buttonLabel === '' && filterLabel === '') {
    return null;
  }
  if (selectedFilter) {
    return (
      <Stack>
        <Typography variant={'subtitle2'} textAlign={'start'}>
          {buttonLabel}
        </Typography>
        <Typography variant={'buttonRegularSmall'}>{filterLabel}</Typography>
      </Stack>
    );
  }
  if (
    dateRange[0] !== null &&
    dateRange[0].isValid() &&
    dateRange[1] !== null &&
    dateRange[1].isValid()
  ) {
    return (
      <Stack>
        <Typography variant={'subtitle2'} textAlign={'start'}>
          {buttonLabel}
        </Typography>
        <Typography variant={'buttonRegularSmall'}>
          {dateRange[0].isSame(dateRange[1])
            ? `${dateRange[0].format('MMM DD')}`
            : `${dateRange[0].format('MMM DD')} - ${dateRange[1].format('MMM DD')}`}
        </Typography>
      </Stack>
    );
  }
  return <Stack>{buttonLabel}</Stack>;
};
