import { useWorkspaces } from '@dizzbo/core/hooks';
import React, {
  Context,
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

export type SearchContextType = {
  searchValue: string;
  setSearchValue: (newSearchValue: string) => void;
};

const SearchContext: Context<SearchContextType> = createContext<
  SearchContextType | undefined
>(undefined);

export const SearchValueProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    activeWorkspace,
    activeWorkspaceUUID,
    isLoadingWorkspaces,
    setWorkspaceSettings,
    removeWorkspaceSettings,
  } = useWorkspaces();

  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (!isLoadingWorkspaces) {
      const currentSearchString: string | undefined =
        activeWorkspace?.settings?.filters?.search;

      if (
        typeof currentSearchString === 'string' &&
        currentSearchString.trim().length > 0
      ) {
        setSearchValue(currentSearchString);
      } else {
        setSearchValue('');
      }
    }
  }, [activeWorkspace, isLoadingWorkspaces]);

  useEffect(() => {
    const currentSearchString: string =
      typeof activeWorkspace?.settings?.filters?.search === 'string'
        ? activeWorkspace?.settings?.filters?.search.trim()
        : '';
    const currentFilters: object = activeWorkspace?.settings?.filters || {};

    if (!currentSearchString && !searchValue.trim()) {
      return;
    } else if (!currentSearchString && searchValue.trim()) {
      setWorkspaceSettings(activeWorkspaceUUID, 'filters', {
        ...currentFilters,
        search: searchValue,
      });
    } else if (currentSearchString && !searchValue.trim()) {
      removeWorkspaceSettings(activeWorkspaceUUID, 'filters', 'search');
    } else if (
      currentSearchString &&
      searchValue.trim() &&
      currentSearchString !== searchValue.trim()
    ) {
      setWorkspaceSettings(activeWorkspaceUUID, 'filters', {
        ...currentFilters,
        search: searchValue,
      });
    }
  }, [searchValue]);

  return (
    <SearchContext.Provider value={{ searchValue, setSearchValue }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchValue = () => {
  const context: SearchContextType = useContext(SearchContext);

  if (!context) {
    throw new Error(
      'useSearchValue hook must be used within a SearchValueProvider'
    );
  }

  return context;
};
