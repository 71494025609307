import { styled, SxProps } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { getOrderDeliveryList } from '@core/api';
import { QueryKeys } from '@core/config';
import { Box, Skeleton, Stack, Typography } from '@dizzbo/ui';

import { useTourOrdersData } from '@order-detail/contexts';
import { useTranslation } from 'react-i18next';
import Pattern from '../../../../assets/images/patterns/pattern_turquoise_light_10.png';
import { DeliveryListItem } from './DeliveryListItem';

const ContainerStyles = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  backgroundImage: `url(${Pattern})`,
  backgroundSize: `22px 19px`,
  paddingTop: 12,
  paddingRight: 24,
  paddingBottom: 24,
  paddingLeft: 24,
}));

const UnassignedDeliveriesStyles: SxProps = {
  maxWidth: '95%',
  overflow: 'auto',
  paddingBottom: '8px',
};

type Props = {};

export const UnassignedDeliveryList: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { selectedOrderUUID } = useTourOrdersData();
  const params = { hasBothStops: false };
  const { data, isPending } = useQuery({
    queryKey: [
      QueryKeys.ORDERS,
      selectedOrderUUID,
      QueryKeys.DELIVERIES,
      params,
    ],
    queryFn: () => getOrderDeliveryList(selectedOrderUUID, params),
  });

  if (!data || data.length === 0) return null;

  return (
    <ContainerStyles>
      <Typography variant="h5">{t('unassignedDeliveries')}</Typography>
      <Stack
        spacing={2}
        direction="row"
        mt={4}
        sx={{ ...UnassignedDeliveriesStyles }}
      >
        {!isPending ? (
          data.map(
            (delivery) =>
              !delivery.loadingStop && (
                <DeliveryListItem
                  key={delivery.uuid}
                  index={delivery.index}
                  action="loading"
                />
              )
          )
        ) : (
          <>
            <Skeleton width="100%" height={56} />
          </>
        )}
        {!isPending ? (
          data.map(
            (delivery) =>
              !delivery.unloadingStop && (
                <DeliveryListItem
                  key={delivery.uuid}
                  index={delivery.index}
                  action="unloading"
                />
              )
          )
        ) : (
          <>
            <Skeleton width="100%" height={56} />
          </>
        )}
      </Stack>
    </ContainerStyles>
  );
};
