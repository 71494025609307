import { useDeleteSectionWaypoint } from '@core/components/RoutingMap/hooks';
import { QueryKeys } from '@core/config';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import {
  ArrowDownIcon,
  Box,
  Button,
  Chip,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LocationIcon,
  RemoveIcon,
  TruckIcon,
  Typography,
} from '@dizzbo/ui';
import { DialogContentText, Grid, SxProps } from '@mui/material';
import { useTourOrdersData } from '@order-detail/contexts';
import { useQueryClient } from '@tanstack/react-query';
import { UUIDType, WaypointType } from '@types';
import { AxiosError } from 'axios';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const iconStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
};

const itemStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  padding: '0 24px',
};

const chipStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  minWidth: '80px',
  minHeight: '40px',
  borderRadius: '21px',
  alignItems: 'center',
  justifyContent: 'center',
};

const pathChipStyle: SxProps = {
  display: 'flex',
  gap: '8px',
  minWidth: '80px',
  minHeight: '40px',
  borderRadius: '21px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: '50px',
  color: colors.oxford['15'],
};

type TourWayPointItemProps = {
  waypoint: WaypointType;
  sectionUUID: UUIDType;
};
export const TourWaypointItem: FC<TourWayPointItemProps> = ({
  waypoint,
  sectionUUID,
}) => {
  const { tourData, refetchOrderAndTourTodos, refetchTourDataAndOrders } =
    useTourOrdersData();
  const { route: routeUUID, uuid: tourUUID } = tourData;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const { mutateAsync: mutateDeleteAsync } =
    useDeleteSectionWaypoint(routeUUID);

  const handleWaypointDelete = () => {
    const getToastId = addSelfClearingLoadingToast(
      t('waypointDeletingToast'),
      6000
    );

    mutateDeleteAsync(
      {
        uuid: waypoint.uuid,
        sectionUUID: sectionUUID,
      },
      {
        onSuccess: () => {
          toast.update(getToastId(), {
            autoClose: 2000,
            closeButton: true,
            render: t('waypointDeleted'),
            type: 'success',
            isLoading: false,
          });
        },
        onError: (error: AxiosError) => {
          toast.update(getToastId(), {
            autoClose: 6000,
            closeButton: true,
            render: generateAxiosErrorMessage(error),
            type: 'error',
            isLoading: false,
          });
        },
        onSettled: () => {
          refetchOrderAndTourTodos();
          refetchTourDataAndOrders();
          queryClient.invalidateQueries({
            queryKey: [tourUUID, QueryKeys.ROUTES, routeUUID],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ROUTES, routeUUID],
          });
        },
      }
    );
  };
  return (
    <>
      <Box sx={{ ...itemStyles }}>
        <Box>
          <Chip
            sx={{ ...chipStyles }}
            size="large"
            variant="turquoise-60"
            icon={<LocationIcon sx={{ ...iconStyles }} />}
            label={<TruckIcon sx={{ ...iconStyles }} />}
          />
        </Box>
        <Grid sx={{ width: '100%' }}>
          <Box
            sx={{
              maxWidth: '320px',
            }}
          >
            <Grid container>
              <Grid item xs={10}>
                <Box>
                  <Typography variant="labelSmall" color={colors.oxford[25]}>
                    {t('waypoint')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingRight: '10px',
                  }}
                >
                  <Typography variant="bodyRegular" color={colors.oxford[100]}>
                    {waypoint.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ display: 'flex', justifyItems: 'flex-end' }}
              >
                <IconButton
                  size="large"
                  variant="tertiary"
                  color="warning"
                  onClick={handleOpen}
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Box sx={{ ...pathChipStyle }}>
        <ArrowDownIcon />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('waypointDeleteTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>{t('waypointDeleteExplainer')}</Typography>
            <Typography variant="h6">{waypoint.name}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="button" variant="tertiary" onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button onClick={handleWaypointDelete} autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
