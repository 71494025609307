import Popper from '@mui/material/Popper';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  ListItem,
  ListItemIcon,
  ListItemText,
  SearchIcon,
  TextField,
  Typography,
} from '@dizzbo/ui';
import { SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatAddressString } from '@dizzbo/core/utils/parsingUtils';
import { GoogleAutocompleteAddress } from '@dizzbo/core/types';

type Props = {
  label: string;
  setFieldValue?: (name, value, option) => void;
  resetForm?: () => void;
  setLocation?: (latLng) => void;
  setAddressDescription?: (address: string) => void;
  keepValue?: boolean;
  sx?: SxProps;
};

type MainTextMatchedSubstrings = {
  offset: number;
  length: number;
};

type StructuredFormatting = {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
};

type PlaceType = {
  description: string;
  structured_formatting: StructuredFormatting;
};

const styles = {
  popper: {
    width: 'fit-content',
  },
};

const PopperMy = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};
const autocompleteService = { current: null };

const setFormFields = (
  setFieldValue: (name, value, option) => void,
  addressComponents
): GoogleAutocompleteAddress => {
  // https://developers.google.com/maps/documentation/geocoding/requests-geocoding?hl=de#Types
  const address: GoogleAutocompleteAddress = {
    street: '',
    streetNumber: '',
    city: '',
    postalCode: '',
    country: '',
    countryName: '',
  };

  for (let i = 0; i < addressComponents.length; i++) {
    for (let t = 0; t < addressComponents[i].types.length; t++) {
      if (addressComponents[i].types[t] == 'street_number') {
        address['streetNumber'] = addressComponents[i].long_name;
      }
      if (addressComponents[i].types[t] == 'route') {
        address['street'] = addressComponents[i].long_name;
        setFieldValue(
          'streetAddress',
          `${addressComponents[i].long_name} ${address.streetNumber}`,
          { shouldValidate: true }
        );
      }
      if (addressComponents[i].types[t] == 'locality') {
        address['city'] = addressComponents[i].long_name;
        setFieldValue('city', addressComponents[i].long_name, {
          shouldValidate: true,
        });
      }
      if (addressComponents[i].types[t] == 'postal_town') {
        address['city'] = addressComponents[i].long_name;
        setFieldValue('city', addressComponents[i].long_name, {
          shouldValidate: true,
        });
      }
      if (addressComponents[i].types[t] == 'postal_code') {
        address['postalCode'] = addressComponents[i].long_name;
        setFieldValue('postalCode', addressComponents[i].long_name, {
          shouldValidate: true,
        });
      }
      if (addressComponents[i].types[t] == 'country') {
        address['country'] = addressComponents[i].short_name;
        address['countryName'] = addressComponents[i].long_name;
        setFieldValue(
          'country',
          {
            code: addressComponents[i].short_name,
            name: addressComponents[i].long_name,
          },
          { shouldValidate: true }
        );
      }
    }
  }
  return address;
};

export const GooglePlacesAutocomplete: React.FC<Props> = ({
  label,
  setFieldValue,
  resetForm,
  setLocation,
  keepValue,
  setAddressDescription,
  sx,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const { t } = useTranslation();

  const placesOptions = {
    radius: 2280041,
    location: new (window as any).google.maps.LatLng(47.843955, 7.861526),
    // types: ['geocode'],
    // types: ['(cities)'],
    // componentRestrictions: { country: 'us' },
  };

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        (autocompleteService.current as any).getPlacePredictions(
          request,
          callback
        );
      }, 400),
    []
  );

  // TODO: use useQuery here
  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    setIsLoading(true);
    fetch(
      { input: inputValue, ...placesOptions },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setIsLoading(false);
          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  return (
    <>
      <Autocomplete
        // disableClearable={disableClearable}
        // disableCloseOnSelect
        loading={isLoading}
        autoComplete
        // autoHighlight
        includeInputInList
        filterSelectedOptions
        blurOnSelect // input should be blurred when an option is selected
        // clearOnEscape // clear input via pressing Esc
        popupIcon={<SearchIcon />}
        PopperComponent={PopperMy}
        componentsProps={{
          popper: {
            sx: {
              width: 'auto',
            },
          },
        }}
        noOptionsText={t('noLocationFound')}
        options={options}
        value={value}
        filterOptions={(x) => x}
        renderInput={(params) => <TextField {...params} label={label} />}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        onChange={(event: any, newValue: PlaceType | null) => {
          if (!newValue) {
            return;
          }
          // reset our form to remove legacy input data on those fields
          resetForm();
          setOptions(newValue ? [newValue, ...options] : options);
          // set the curren selected Place
          setValue(newValue);
          // set the Loading Point Name in the form
          setFieldValue('name', newValue?.structured_formatting?.main_text, {
            shouldValidate: true,
          });
          // prepare the Address Object
          const addressObj = {
            placeId: newValue.place_id,
          };
          // create a dummy google map. A map instance is mandatory for using
          // Google's PlacesService
          const map = new (window as any).google.maps.Map(
            document.createElement('div')
          );
          // init Google's PlacesService
          const service = new (window as any).google.maps.places.PlacesService(
            map
          );
          // get the Place Detail for the place id
          service.getDetails(addressObj, (place, status) => {
            setLocation({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });
            setFieldValue(
              'openingHours',
              // create a formatred string with line breaks from the weekday_text array
              place?.opening_hours?.weekday_text.join('\r\n'),
              {
                shouldValidate: true,
              }
            );
            const address = setFormFields(
              setFieldValue,
              place.address_components
            );
            const formatedAddress = formatAddressString(address);
            newValue.description = formatedAddress;
            if (!keepValue) {
              setValue(null);
            } else {
              setValue(newValue);
              setAddressDescription(formatedAddress);
            }
            setOptions([]);
          });

          // beyond is the old approach to get the address components of the place
          // the diry approach above for getting the Place Detail (which has the opening hours)
          // also provides us the address_components

          // const geocoder = new (window as any).google.maps.Geocoder();
          // geocoder.geocode(addressObj, (results) => {
          //   if (
          //     typeof results !== 'undefined' &&
          //     results !== null &&
          //     results.length > 0
          //   ) {
          //     setFormFields(setFieldValue, results[0].address_components);
          //   }
          // });
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          );

          const text = parts.map((part, index) => (
            <Typography
              key={index}
              variant={part.highlight ? 'bodyBold' : 'bodyRegular'}
            >
              {part.text}
            </Typography>
          ));

          return (
            <ListItem {...props} sx={{ height: 52 }}>
              <ListItemIcon>
                <SearchIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
              <ListItemText>
                {text}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </ListItemText>
            </ListItem>
          );
        }}
      />
    </>
  );
};
