import { moveOrderToAnotherTour } from '@core/api';
import { generateAxiosErrorMessage } from '@dizzbo/core';
import {
  Box,
  Button,
  colors,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@dizzbo/ui';
import { SxProps } from '@mui/material';
import { useGetOrders, useSearchForOrder } from '@orders/queries';
import { useMutation } from '@tanstack/react-query';
import { OrderType, UUIDType } from '@types';
import { assertNever, hexToRgba, unwrap } from '@utils';
import { AxiosError } from 'axios';
import { debounce, DebouncedFunc } from 'lodash';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type DialogConfig = {
  title: string;
  headline: string;
  description: string;
  label: string;
  placeholder: string;
  validationRegex: RegExp;
};

type Props = {
  popupState: PopupState;
  orderReference: string;
  tourReference: string;
  targetOrderUUID?: string;
  targetTourUUID?: string;
  type: 'moveThisOrder' | 'addAnotherOrder';
};

export const MoveOrderBetweenToursDialog: FC<Props> = ({
  popupState,
  orderReference,
  tourReference,
  targetOrderUUID,
  targetTourUUID,
  type,
}) => {
  const { t } = useTranslation();
  const { refetch: refreshOrderListData } = useGetOrders();

  const config: DialogConfig =
    type === 'moveThisOrder'
      ? {
          title: t('moveThisOrderToAnotherTour'),
          headline: t('inputTourId'),
          description: t('movingOrderToATour', { orderReference }),
          label: t('tourId'),
          placeholder: 'TRXXXXXXXX',
          validationRegex: /^TR\d{8}$/,
        }
      : {
          title: t('moveAnotherOrderToThisTour'),
          headline: t('inputOrderId'),
          description: t('movingAnOrderToTour', { tourReference }),
          label: t('orderId'),
          placeholder: 'ORXXXXXXXX',
          validationRegex: /^OR\d{8}$/,
        };

  const { title, headline, description, label, placeholder, validationRegex } =
    config;

  const { isOpen } = popupState;

  const [targetUUID, setTargetUUID] = useState<string>('');
  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);

  useEffect(() => {
    reset();
  }, [isOpen]);

  const { refetch } = useSearchForOrder(
    isInputValid
      ? {
          search: input,
        }
      : undefined
  );

  const { mutateAsync: mutateMoveOrder, isPending: isMutating } = useMutation({
    mutationFn: (config: { orderUUID: UUIDType; targetTourUUID: UUIDType }) => {
      const { orderUUID, targetTourUUID } = config;
      return moveOrderToAnotherTour(orderUUID, targetTourUUID);
    },
    onSuccess: async () => {
      await refreshOrderListData();
      switch (type) {
        case 'moveThisOrder': {
          toast.success(
            t('orderWasMovedToTour', {
              orderReference,
              targetTourReference: input,
            })
          );
          break;
        }
        case 'addAnotherOrder': {
          toast.success(
            t('orderWasMovedToTour', {
              orderReference: input,
              targetTourReference: tourReference,
            })
          );
          break;
        }
        default:
          assertNever(type);
      }
      popupState.close();
      reset();
    },
    onError: (error: AxiosError) => {
      toast.error(generateAxiosErrorMessage(error));
    },
  });

  async function searchForUUID(reference: string): Promise<void> {
    try {
      if (reference === orderReference || reference === tourReference) {
        toast.warning(
          `Order ${reference} is already in the tour ${tourReference}! 🤯`
        );
        setTargetUUID('');
        return;
      }

      setIsSearching(true);
      const { data: refetchedOrderdata } = await refetch();
      setIsSearching(false);
      const { count, results } = refetchedOrderdata;

      switch (type) {
        case 'moveThisOrder': {
          if (count === 0) {
            toast.warning(`Tour with reference ${reference} was not found! 🤯`);
            setTargetUUID('');
            break;
          }
          const order: OrderType = results[0];
          const { tour } = order;
          const { uuid } = tour;
          toast.success(`Tour with reference ${reference} was found! 👌`);
          setTargetUUID(uuid);
          break;
        }
        case 'addAnotherOrder': {
          if (count === 0) {
            toast.warning(`Order with reference ${reference} was not found!`);
            setTargetUUID('');
            break;
          }
          const order: OrderType = results[0];
          const { uuid: orderUUID, tour } = order;
          const { uuid: tourUUID } = tour;
          if (tourUUID === targetTourUUID) {
            toast.warning(
              `Order ${reference} is already in the tour ${tourReference}! 🤯`
            );
            setTargetUUID('');
          } else {
            toast.success(`Order with reference ${reference} was found! 👌`);
            setTargetUUID(orderUUID);
          }
          break;
        }
        default:
          assertNever(type);
      }
    } catch (error) {
      toast.error(
        `Something went wrong when searching for order! Error : ${error?.response || error.message}`
      );
    }
  }

  const debouncedSearch: DebouncedFunc<(search: string) => void> = useCallback(
    debounce((search: string) => {
      searchForUUID(search);
    }, 1000),
    [searchForUUID]
  );

  function reset(): void {
    setTargetUUID('');
    setInput('');
    setIsInputValid(false);
    setIsSearching(false);
  }

  function handleInput(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const { currentTarget } = event;
    const { value } = currentTarget;
    setTargetUUID('');
    const isValid: boolean = validationRegex.test(value);
    setIsInputValid(isValid);
    setInput(value);
    if (isValid) {
      debouncedSearch(value);
    }
  }

  function handleDialogCancel(): void {
    popupState.close();
    reset();
  }

  async function handleDialogConfirm(): Promise<void> {
    switch (type) {
      case 'moveThisOrder': {
        const orderUUID: UUIDType = unwrap(
          targetOrderUUID,
          'targetOrderUUID',
          'MoveOrderBetweenToursDialog'
        );
        mutateMoveOrder({ orderUUID, targetTourUUID: targetUUID });
        break;
      }
      case 'addAnotherOrder': {
        const tourUUID: UUIDType = unwrap(
          targetTourUUID,
          'targetTourUUID',
          'MoveOrderBetweenToursDialog'
        );
        mutateMoveOrder({ orderUUID: targetUUID, targetTourUUID: tourUUID });
        break;
      }
      default:
        assertNever(type);
    }
  }

  function getCurrentButtonLabel(): string {
    if (isSearching) {
      return t('searching');
    }
    if (isMutating) {
      return t('loading');
    }
    return t('confirm');
  }

  const dialogContentStyles: SxProps = {
    background: hexToRgba(colors.turquoise[80], 0.2),
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    gap: '4px',
  };

  const inputCellBoxStyles: SxProps = {
    display: 'flex',
    padding: '16px 24px',
    flexDirection: 'column',
    gap: '16px',
    textAlign: 'left',
  };

  return (
    <Dialog
      aria-labelledby="draggable-dialog-title"
      scroll="paper"
      fullWidth
      maxWidth="sm"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close} id="draggable-dialog-title">
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers={true}
        sx={{ textAlign: 'center', color: '#000' }}
      >
        {
          <Box sx={{ ...dialogContentStyles }}>
            <Box sx={{ ...inputCellBoxStyles }}>
              <Typography variant="h5" color="primary">
                {headline}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ ...inputCellBoxStyles }}>
              <Typography variant={'bodyRegular'}>{description}</Typography>
              <TextField
                label={label}
                placeholder={placeholder}
                maxRows={1}
                fullWidth
                onChange={(event) => handleInput(event)}
                value={input}
                error={!isInputValid && input.length > 0}
              />
            </Box>
          </Box>
        }
      </DialogContent>
      <DialogContent dividers={false}>
        {
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '6px',
            }}
          >
            <Button
              variant="tertiary"
              size="medium"
              onClick={() => handleDialogCancel()}
            >
              <Typography variant="buttonRegular">{t('cancel')}</Typography>
            </Button>
            <Button
              variant="primary"
              size="medium"
              onClick={() => handleDialogConfirm()}
              disabled={
                !isInputValid || targetUUID === '' || !targetUUID || isMutating
              }
            >
              <Typography variant="buttonRegular">
                {getCurrentButtonLabel()}
              </Typography>
            </Button>
          </Box>
        }
      </DialogContent>
    </Dialog>
  );
};
