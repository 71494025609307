import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';

import { createTourComment, getTourComments } from '@core/api';
import { QueryKeys } from '@core/config';
import { ConversationDialog, getStatusColor } from '@dizzbo/core';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CommunicationIcon,
  TruckFTLIcon,
  TruckLTLIcon,
  Typography,
} from '@dizzbo/ui';
import { useTourOrdersData } from '@order-detail/contexts';
import { useTranslation } from 'react-i18next';

type Props = {};

export const TourSummary: FC<Props> = () => {
  const { selectedOrderData, tourData } = useTourOrdersData();
  const { uuid: tourUUID, reference, status } = tourData;
  const { loadType } = selectedOrderData;

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'tourConversationDialog',
  });

  const fetchComments = (params) => {
    return getTourComments(tourUUID, params);
  };

  const createComment = (data) => {
    return createTourComment(tourUUID, data);
  };

  const { t } = useTranslation();

  return (
    <>
      <Card
        variant="filled-secondary"
        elevation={1}
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <CardHeader
          avatar={
            loadType === 'LTL' ? (
              <Chip
                label="LTL"
                size="medium"
                variant="green-100"
                icon={<TruckLTLIcon />}
              />
            ) : (
              <Chip
                label="FTL"
                size="medium"
                variant="oxford-40"
                icon={<TruckFTLIcon />}
              />
            )
          }
          title={
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h4" color="primary">
                {t('dizzboTourWithReference', { reference })}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: getStatusColor(status),
                  textTransform: 'capitalize',
                }}
              >
                &nbsp;-&nbsp; {status}
              </Typography>
            </Box>
          }
          action={
            <Button
              aria-label="Communication"
              size="medium"
              variant="primary"
              startIcon={<CommunicationIcon />}
              onClick={() => popupState.open()}
            >
              {t('comments')}
            </Button>
          }
          variant="small"
          sx={{ borderBottom: 0 }}
        />
      </Card>
      <ConversationDialog
        popupState={popupState}
        query={{
          queryFn: fetchComments,
          queryKey: [QueryKeys.TOURS, tourUUID, 'comments'],
        }}
        mutation={{
          mutationFn: createComment,
          mutationKey: [QueryKeys.TOURS, tourUUID, 'comments'],
        }}
      />
    </>
  );
};
