import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteOrderStop } from '@core/api';
import { QueryKeys } from '@core/config';
import { UUIDType } from '@types';

export const useDeleteOrderStop = (orderUUID: UUIDType, stopUUID: UUIDType) => {
  const queryClient = useQueryClient();

  return useMutation({
    // mutationKey: [QueryKeys.ANONYMOUS_POD_UPLOAD, token],
    mutationFn: () => deleteOrderStop(orderUUID, stopUUID),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.STOPS],
      });
    },
  });
};
