import { useMutation, useQueryClient } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';

import { createWaypoint } from '@core/api';
import { QueryKeys } from '@core/config';
import { RouteSectionType, UUIDType } from '@types';

type Payload = {
  lat: number;
  lng: number;
  uuid?: UUIDType;
  sectionUUID: UUIDType;
};

export const useCreateSectionWaypoint = (routeUUID: UUIDType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QueryKeys.ROUTES, routeUUID],
    mutationFn: (values: Payload) => createWaypoint(values),
    onMutate: async (newWaypoint) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [
          QueryKeys.ROUTES,
          routeUUID,
          QueryKeys.SECTIONS,
          newWaypoint.sectionUUID,
        ],
      });

      // Snapshot the previous value
      const previousSection: RouteSectionType = queryClient.getQueryData([
        QueryKeys.ROUTES,
        routeUUID,
        QueryKeys.SECTIONS,
        newWaypoint.sectionUUID,
      ]);

      const waypointCopy = { ...newWaypoint };
      delete waypointCopy.sectionUUID;

      const newSection = cloneDeep(previousSection);

      newSection.waypoints = [...newSection.waypoints, waypointCopy];
      // Optimistically update to the new value
      queryClient.setQueryData(
        [
          QueryKeys.ROUTES,
          routeUUID,
          QueryKeys.SECTIONS,
          newWaypoint.sectionUUID,
        ],
        newSection
      );

      // Return a context object with the snapshotted value
      return { previousSection, newSection };
    },
    onError: (err, newWaypoint, context) => {
      queryClient.setQueryData(
        [
          QueryKeys.ROUTES,
          routeUUID,
          QueryKeys.SECTIONS,
          newWaypoint.sectionUUID,
        ],
        context.previousSection
      );
    },
    onSettled: (newWaypoint) => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeys.ROUTES,
          routeUUID,
          QueryKeys.SECTIONS,
          newWaypoint.sectionUUID,
        ],
      });
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeys.ROUTES,
          routeUUID,
          QueryKeys.SECTIONS,
          variables.sectionUUID,
        ],
      });
    },
  });
};
