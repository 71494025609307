import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, PlusIcon } from '@dizzbo/ui';
import { CreateOrderDialog } from '@orders/components/CreateOrderDialog';

import { TourOrdersDataProvider } from '@order-detail/contexts';

import { useOrderDetail } from '../hooks';

type Props = {
  totalPrice: string;
  isOrderCreationPending: boolean;
};

export const CreateOrderButton: React.FC<Props> = ({
  totalPrice,
  isOrderCreationPending,
}) => {
  const { t } = useTranslation();
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderCreateDialog',
  });
  const { orderData } = useOrderDetail();
  const newOrderData = {
    ...orderData,
    price: parseFloat(totalPrice),
  };

  const handleOpenDialog = () => {
    popupState.open();
  };

  if (!orderData) return null;

  return (
    <>
      <Button
        type="submit"
        size="large"
        startIcon={<PlusIcon />}
        onClick={handleOpenDialog}
        disabled={isOrderCreationPending}
      >
        {t('createNewOrder')}
      </Button>
      {!isOrderCreationPending && newOrderData ? (
        <TourOrdersDataProvider
          contextType={'newOrder'}
          initialDisplayedOrderUUID={orderData.uuid}
        >
          <CreateOrderDialog orderData={newOrderData} popupState={popupState} />
        </TourOrdersDataProvider>
      ) : null}
    </>
  );
};
