import { QueryKeys } from '@core/config';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { updateOrderStop } from '@core/api';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Alert,
  Box,
  Button,
  DateTimePicker,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconBullet,
  LoadingButton,
  Stack,
  Typography,
  UnloadingIcon,
  WarningIcon,
} from '@dizzbo/ui';
import { useTourOrdersData } from '@order-detail/contexts';
import { OrderType, StopType } from '@types';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

const SubtitleStyles = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

type Props = {
  orderData: OrderType;
  popupState: PopupState;
};

export const UnloadingTimeDialog: React.FC<Props> = ({
  orderData,
  popupState,
}: Props) => {
  const { refetchTourDataAndOrders, refetchOrderAndTourTodos } =
    useTourOrdersData();
  const { uuid: orderUUID, reference, status, stops } = orderData;

  const lastStop = stops[stops.length - 1];

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      arrivedAt: lastStop?.arrivedAt ? dayjs(lastStop?.arrivedAt) : null,
    },
  });

  const { t } = useTranslation();

  const {
    mutate: mutateStop,
    isError,
    error,
    isPending,
  } = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.STOPS, lastStop?.uuid],
    mutationFn: (values: Partial<StopType>) =>
      updateOrderStop(orderUUID, lastStop?.uuid, values),
  });

  useEffect(() => {
    if (popupState.isOpen) {
      reset();
    }
  }, [popupState]);

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData) => {
    const getToastId = addSelfClearingLoadingToast(
      t('updatingUnloadingTime'),
      6000
    );

    const data = {
      arrivedAt: formData.arrivedAt?.toISOString(),
    };
    mutateStop(data, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('unloadingTimeUpdated'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.update(getToastId(), {
          autoClose: 6000,
          closeButton: true,
          render: generateAxiosErrorMessage(error),
          type: 'error',
          isLoading: false,
        });
      },
      onSettled: async (): Promise<void> => {
        await Promise.all([
          refetchOrderAndTourTodos(),
          refetchTourDataAndOrders(),
        ]);
        popupState.close();
      },
    });
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={popupState.close}>
          <Typography variant="h3" color="primary">
            {reference}
          </Typography>
          <Typography
            variant="h3"
            color="warning.main"
            sx={{ textTransform: 'capitalize', marginLeft: 1 }}
          >
            – {status}
          </Typography>
        </DialogTitle>

        <DialogContent dividers sx={{ padding: 0 }}>
          <SubtitleStyles>
            <Typography variant="h5">
              {t('submitActualUnloadingTime')}
            </Typography>
          </SubtitleStyles>
          {(!orderData.stops || orderData.stops.length <= 1) && (
            <Box p={6}>
              <Alert
                title="Please assign another stop to this order."
                variant="standard"
                severity="warning"
                icon={<WarningIcon />}
              />
            </Box>
          )}
          <Box px={6} pt={6}>
            <Stack direction="row" spacing={6} alignItems="center">
              <IconBullet size="large" variant="bright">
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {stops.length}
                </Box>
                <UnloadingIcon />
              </IconBullet>
              <Stack>
                <Typography variant="labelSmall">
                  {t('loadingPoint')}
                </Typography>
                <Typography variant="bodyRegular">
                  {lastStop?.loadingPoint.name}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Box p={6}>
            <Controller
              name="arrivedAt"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  sx={{ width: '100%' }}
                  label="Actual Unloading Time"
                  disableFuture
                  slotProps={{
                    textField: {
                      error: hasFieldError(field),
                      helperText: fieldError(field),
                    },
                  }}
                  {...field}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack spacing={3} direction="row">
            <Button
              variant="tertiary"
              onClick={() => popupState.close()}
              disabled={isPending}
            >
              {t('cancel')}
            </Button>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="primary"
              loading={isPending}
              disabled={!isDirty || !isValid || isPending}
            >
              {t('submit')}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};
