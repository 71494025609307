import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

import { Box } from '@dizzbo/ui';

import { OrderType } from '@types';
import { useSchedulerSettings } from '../../context';
import { OrderListItem } from '../OrderListItem';

const BoxOrderListContainer = styled(Box)(() => ({
  height: '120px',
  width: '100%',
  overflowY: 'hidden',
  overflowX: 'auto',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  padding: 24,
  gap: '24px',
}));

type Props = {};

export const OrderList: FC<Props> = () => {
  const { displayedOrders } = useSchedulerSettings();

  return (
    <BoxOrderListContainer>
      {displayedOrders.map((orderData: OrderType) => {
        return <OrderListItem orderData={orderData} key={orderData.uuid} />;
      })}
    </BoxOrderListContainer>
  );
};
